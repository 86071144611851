import React from "react";
import { Box, Text, Button, useColorModeValue } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

interface NoResultsMessageProps {
  value?: string;
  activeFilter: string | null;
  onClickFeedback: () => void;
}

export const NoResultsMessage: React.FC<NoResultsMessageProps> = ({ value, activeFilter, onClickFeedback }) => {
  const inputBgColor = useColorModeValue("white", "gray.700");

  return (
    <Box className="ch-autocomplete-error" borderRadius="md" bgColor={inputBgColor} border="none" p="1rem">
      <Text fontSize="sm" lineHeight="1.4rem">
        There are no matching results
        {value && value.length > 0 && (
          <>
            {" for "}
            <Text as="span" fontWeight="bold">
              {value.toUpperCase()}
            </Text>
          </>
        )}
        {activeFilter !== null && (
          <>
            {" on "}
            <Text as="span" fontWeight="bold">
              {activeFilter.toUpperCase().replace(/_/g, " ")}
            </Text>
          </>
        )}
        . Additional exchanges, indices and equities are being added rapidly and our team can prioritize based on your feedback.
      </Text>
      {value && value.length > 0 && (
        <Button
          leftIcon={<InfoIcon color="gray.400" />}
          fontWeight="normal"
          color="gray.600"
          pt="1rem"
          variant="link"
          fontSize="sm"
          onClick={onClickFeedback}
          width="100%"
          justifyContent="end">
          Information on prioritizing
        </Button>
      )}
    </Box>
  );
};
