import { Stack, useColorMode, IconButton, useColorModeValue, useBreakpointValue } from "@chakra-ui/react";
import { useCustomTheme, useEntitlementKey, useUserPreference, useUserSubscriptionDetails } from "hooks";
import type { FunctionComponent } from "react";
import React, { memo, useCallback } from "react";
import { UpgradePlanButton } from "screens/panels/settings/tabs/subscription/SubscriptionUpgrade";
import { InteractionBarFindInput } from "./InteractionBarFindInput";
import { NotificationsMenu } from "./NotificationsMenu";
import { ProfileSettingsMenu } from "./ProfileSettingsMenu";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";

interface Props {
  initialText?: string;
}

const InteractionBarComponent: FunctionComponent<Props> = ({ initialText = "" }) => {
  const { isEmbeddedApp } = useCustomTheme();
  const hasSearch = useEntitlementKey("ui_enable_search");
  const subscriptionInfo = useUserSubscriptionDetails();
  const uiColorMode = useUserPreference("ui_color_mode") as string;
  const { colorMode, setColorMode } = useColorMode();
  const hasThemes = useEntitlementKey("ui_themes");
  const iconColor = useColorModeValue("gray.400", "gray.500");
  const dispatch = useDispatch();
  const hasCompletedOnboarding = useUserPreference("ui_onboarding_completed") as boolean;
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false }, { fallback: "md", ssr: false });

  const toggleColorMode = useCallback(() => {
    const newColorMode = uiColorMode === "light" ? "dark" : "light";
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_color_mode", value: newColorMode }));
    setColorMode(newColorMode);
  }, [uiColorMode, dispatch, setColorMode]);

  return (
    <Stack
      pointerEvents={!isMobile && !hasCompletedOnboarding ? "none" : "initial"}
      direction="row"
      width="100%"
      justifyContent="flex-end"
      p="0.5rem"
      align="center"
      spacing="1em">
      {hasSearch && <InteractionBarFindInput initialText={initialText} />}
      <NotificationsMenu />
      {subscriptionInfo.internalPlanName === "free_trial" && (
        <UpgradePlanButton
          tooltip="You can analyze up to 3 projects on your current plan. Upgrade to continue receiving the benefits of the Automated Analyst"
          buttonSize="xs"
        />
      )}
      {hasThemes && (
        <IconButton
          aria-label="Toggle color mode"
          minWidth={0}
          paddingInline={0}
          color={iconColor}
          icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
          onClick={toggleColorMode}
          size="sm"
          variant="ghost"
          backgroundColor="transparent"
          fontSize={"1.3rem"}
          _hover={{ color: "primary.default", bgColor: "transparent" }}
        />
      )}
      {!isEmbeddedApp && <ProfileSettingsMenu />}
    </Stack>
  );
};

export const InteractionBar = memo(InteractionBarComponent);
