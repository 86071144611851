import { Box, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, useBreakpointValue, Text, Tooltip } from "@chakra-ui/react";
import { useContents, useIsMainAppLoading, useTabProps, useCollectionKey, useInitialSyncCompleted, useEntitlementKey } from "hooks";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import React, { useContext, useState } from "react";
import { ContentFilterContext, ContentRouter } from "screens/content";
import { ProjectDetailDefaultAIResults } from "./ProjectDetailDefaultAIResults";
import { ProjectDetailDefaultActionMenu } from "./ProjectDetailDefaultViewActionMenu";
import { ContentCanvasFilterInput } from "screens/content/contentCanvas/header/ContentCanvasFilterInput";
import { UpgradePlanButton } from "screens/panels/settings/tabs/subscription/SubscriptionUpgrade";

export const PROJECT_DETAIL_SECTION_SELECTOR = "project-detail-section";

interface Props {
  collectionId: string;
}

export const ProjectDetailViewContent: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ collectionId }) => {
  const { resetFilter } = useContext(ContentFilterContext);
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const contentData = useContents(collectionMetadataIds || []);
  const [tabIndex, setTabIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const { defaultTabProps } = useTabProps();
  const isMainAppLoading = useIsMainAppLoading();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const hasInitialSyncCompleted = useInitialSyncCompleted();
  const hasProfessionalFeatures = useEntitlementKey("ui_enable_shared_features_professional");
  const shareDetails = useCollectionKey(collectionId, "shareDetails");
  const isSharedRead = shareDetails && shareDetails.accessMode === "read";

  const handleTabsChange = (index: number) => {
    if (isSharedRead && !hasProfessionalFeatures && index === 1) return;
    setTabIndex(index);
    resetFilter();
  };

  const isLoadingContents = useMemo(() => {
    if (!collectionMetadataIds?.length) {
      return false;
    }

    return (contentData?.length ?? 0) < collectionMetadataIds?.length;
  }, [collectionMetadataIds?.length, contentData?.length]);

  return (
    <>
      {!isMainAppLoading && (
        <Tabs isFitted={isMobile} index={tabIndex} onChange={handleTabsChange} mt={isMobile ? "0" : "2.9rem!important"}>
          <Stack
            direction={isMobile ? "column" : "row"}
            justifyContent={"space-between"}
            width="100%"
            spacing={isMobile ? "1rem" : ".5rem"}
            alignItems="center"
            pb=".5rem">
            <TabList borderBottom={"none"} width="100%">
              <Tab {...defaultTabProps} className="ch-project-results">
                AI Results
              </Tab>
              <Tab {...defaultTabProps} width="14rem" className="ch-project-resources">
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  width="100%"
                  justifyContent={"space-between"}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}>
                  <Tooltip
                    label={isSharedRead && !hasProfessionalFeatures ? "Upgrade to Professional to access the Source Content" : ""}
                    width="12rem"
                    aria-label="Source Content"
                    placement="top">
                    <Text color={isSharedRead && !hasProfessionalFeatures ? "gray.400" : "inherit"}>Source Content</Text>
                  </Tooltip>
                  {isSharedRead && !hasProfessionalFeatures && (
                    <Box opacity={isHovered ? 1 : 0} transition="opacity 0.2s">
                      <UpgradePlanButton buttonSize="xs" style={{ width: "4rem" }} />
                    </Box>
                  )}
                </Stack>
              </Tab>
            </TabList>
            <ProjectDetailDefaultActionMenu collectionId={collectionId} />
          </Stack>
          <Box width="100%" maxWidth="30rem" ml="auto">
            <ContentCanvasFilterInput placeholderText="Filter AI Results" />
          </Box>
          <TabPanels pb="4rem">
            <TabPanel pt=".65rem" px="0!important" className="ch-project-results-panel">
              <ProjectDetailDefaultAIResults isLoading={!hasInitialSyncCompleted || isLoadingContents} collectionId={collectionId} />
            </TabPanel>
            <TabPanel pt=".65rem" px="0!important" className="ch-project-resources-panel">
              <Stack spacing="1rem">
                <ContentRouter filterOutProjectOutput />
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </>
  );
};
