import React from "react";
import { Box, Text, Link, Stack, useBreakpointValue } from "@chakra-ui/react";

interface RegistrationBannerProps {
  isLogin?: boolean;
}

export const RegistrationBanner: React.FC<RegistrationBannerProps> = ({ isLogin = false }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  return (
    <Box
      width={isMobile ? "calc(100% - 2rem)" : "1080px"}
      height="43px"
      background="white"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      paddingRight="0"
      color="white"
      fontSize="sm"
      fontWeight="medium">
      <Stack
        fontSize="0.8rem"
        fontWeight="500"
        color="#3d3d3d"
        direction="row"
        spacing={"2rem"}
        alignItems="flex-end"
        fontFamily="Montserrat">
        <Text>
          <Link href="https://support.charli.ai/">Support</Link>
        </Text>
        <Text>
          <Link href={isLogin ? "/register" : "/login"}>{isLogin ? "Register" : "Log In"}</Link>
        </Text>
      </Stack>
    </Box>
  );
};
