import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorModeValue,
  Center,
  Link,
  Checkbox,
  Text,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  useButtonProps,
  useCreditCardSecurityToken,
  useFeatureFlags,
  useUserProfile,
  useUserSubscriptionDetails,
  useUserSubscriptionDetailsDaysTillNextBilling,
} from "hooks";
import { track } from "api/analytics";
import { USER_UPGRADE_PLAN_CONFIRM } from "api/analytics/events";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import { Intent } from "types/intent";
import { useLocation } from "react-router-dom";
import { COUNTRY_CODES } from "api/subscription/data/countryCodes";
import { COUNTRY_SUB_DIVISIONS } from "api/subscription/data/countrySubdivisions";

export const FormErrorLabel = ({ error, textAlign = "end" }: { error: string; textAlign?: "end" | "start" }) => {
  return (
    <Text width="100%" position={"absolute"} textAlign={textAlign} pt="2px" color="red.500" fontSize={"10px"}>
      {error}
    </Text>
  );
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (fields: any) => void;
}

export const SubscribeForm = (props: Props) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    firstName: string;
    lastName: string;
    organization: string;
    address2: string;
    address: string;
    city: string;
    country: string;
    state: string;
    postcode: string;
    acceptTerms: boolean;
    promotionCode?: string;
  }>();
  const primaryTextColor = useColorModeValue("gray.300", "gray.400");
  const buttonStyle = useButtonProps("lg", "subscribe");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { firstName, lastName, fullName, email } = useUserProfile();
  const subscriptionInfo = useUserSubscriptionDetails();
  const daysTillPlanExpiry = useUserSubscriptionDetailsDaysTillNextBilling();
  const answerUsage = useFeatureUsage(Intent.generateAnswer);
  const projectUsage = useFeatureUsage(Intent.createDueDiligenceProject);
  const { pathname } = useLocation();
  const countryWatch = watch("country", undefined);
  const firstNameInputRef = useRef<HTMLInputElement | null>();
  const { ref: registerFirstNameRef, ...firstNameRegisterProps } = register("firstName", { required: true });
  const chargify = useRef(new window.Chargify());
  const chargifyForm = useRef<HTMLFormElement>(null);
  const toast = useToast();
  const securityToken = useCreditCardSecurityToken();
  const { enable_credit_card_processing: isCreditCardProcessingEnabled } = useFeatureFlags();

  useEffect(() => {
    if (firstNameInputRef.current) {
      firstNameInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!isCreditCardProcessingEnabled) {
      return;
    }

    chargify.current.load({
      publicKey: window.env.chargifyJsPublicToken,
      type: "card",
      gatewayHandle: window.env.chargifyGatewayHandle,
      serverHost: window.env.maxioSiteHost,
      securityToken: securityToken,
      fields: {
        number: {
          required: true,
          selector: "#chargify_form",
        },
        month: {
          required: true,
          selector: "#chargify_form",
        },
        year: {
          required: true,
          selector: "#chargify_form",
        },
        cvv: {
          required: true,
          selector: "#chargify_form",
        },
      },
    });

    return undefined;
  }, [securityToken, isCreditCardProcessingEnabled]);

  const onConfirm = useCallback(
    async (fields: any) => {
      track(USER_UPGRADE_PLAN_CONFIRM, {
        pageViewed: pathname,
        userName: fullName,
        userEmail: email,
        answersUsed: answerUsage?.used,
        dueDiligenceUsed: projectUsage?.used,
        currentPlan: subscriptionInfo.plan,
        planStatus: subscriptionInfo.status,
        nextBillingDate: subscriptionInfo.nextBillingDate,
        subscriptionId: subscriptionInfo.subscriptionId,
        daysTillPlanExpiry: daysTillPlanExpiry.daysTillNextBilling,
        promotionCode: fields.promotionCode,
      });

      setIsSubmitting(true);
      if (!isCreditCardProcessingEnabled) {
        props.onSubmit(fields);
        return;
      }

      try {
        const token = await new Promise((resolve, reject) => {
          chargify.current.token(
            chargifyForm.current,

            (token) => {
              resolve(token);
            },

            (error) => {
              console.error("{host} token ERROR - err: ", error);
              reject("Error fetching token: " + String(error));
            }
          );
        });

        props.onSubmit({
          ...fields,
          creditCardToken: token,
          chargifyGatewayHandle: window.env.chargifyGatewayHandle,
        });
      } catch (error) {
        console.error(error);
        toast({
          title: "An error occurred.",
          description: "Unable to process credit card. Please contact support or try again later",
          status: "error",
          duration: 8000,
          isClosable: true,
        });
        setIsSubmitting(false);
      }
    },
    [
      answerUsage?.used,
      daysTillPlanExpiry.daysTillNextBilling,
      email,
      fullName,
      pathname,
      projectUsage?.used,
      props,
      subscriptionInfo.nextBillingDate,
      subscriptionInfo.plan,
      subscriptionInfo.status,
      subscriptionInfo.subscriptionId,
      toast,
      isCreditCardProcessingEnabled,
    ]
  );

  const countryCodeOptions = useMemo(
    () =>
      COUNTRY_CODES.map((country) =>
        Array.isArray(country) ? { label: country[0], value: country[1] } : { label: country, value: country }
      ),
    []
  );

  const countrySubdivisionOptions = useMemo(() => {
    const subdivisions = COUNTRY_SUB_DIVISIONS[countryWatch];
    if (subdivisions) {
      return subdivisions.map(([label, value]) => ({ label, value }));
    }

    return undefined;
  }, [countryWatch]);

  useEffect(() => {
    setValue("state", "");
  }, [countrySubdivisionOptions, setValue]);

  useEffect(() => {
    if (!COUNTRY_SUB_DIVISIONS[countryWatch]) {
      setValue("state", "");
    }
  }, [countryWatch, setValue]);

  return (
    <Stack justifyContent={"space-between"} spacing="2rem" height="100%" width="100%">
      <form onSubmit={handleSubmit(onConfirm)} ref={chargifyForm} noValidate>
        <Stack direction="column" spacing="2rem">
          <Stack spacing="1.5rem" width="100%">
            <Stack direction="row" spacing="1rem">
              <FormControl isInvalid={!!errors.firstName}>
                <Input
                  defaultValue={firstName}
                  size="sm"
                  {...firstNameRegisterProps}
                  id="register-firstName"
                  type="text"
                  placeholder="First Name"
                  borderColor={errors.firstName ? "red.500" : "gray.300"}
                  fontSize="sm"
                  boxShadow="none"
                  ref={(e) => {
                    registerFirstNameRef(e);
                    firstNameInputRef.current = e;
                  }}
                />
                {errors.firstName && <FormErrorLabel error="required" />}
              </FormControl>
              <FormControl isInvalid={!!errors.lastName}>
                <Input
                  defaultValue={lastName}
                  size="sm"
                  {...register("lastName", { required: true })}
                  id="register-lastName"
                  type="text"
                  placeholder="Last Name"
                  borderColor={errors.lastName ? "red.500" : "gray.300"}
                  fontSize="sm"
                  boxShadow="none"
                />
                {errors.lastName && <FormErrorLabel error="required" />}
              </FormControl>
            </Stack>
            <FormControl isInvalid={!!errors.organization}>
              <Input
                size="sm"
                id="register-organization"
                type="string"
                placeholder="Company Name"
                {...register("organization", { required: false })}
                borderColor={errors.organization ? "red.500" : "gray.300"}
                fontSize="sm"
                boxShadow="none"
              />
              {errors.organization && <FormErrorLabel error="required" />}
            </FormControl>
            <Stack direction="row" spacing="1rem">
              <FormControl isInvalid={!!errors.organization}>
                <Input
                  size="sm"
                  id="register-address"
                  type="string"
                  placeholder="Address"
                  {...register("address", { required: true })}
                  borderColor={errors.address ? "red.500" : "gray.300"}
                  fontSize="sm"
                  boxShadow="none"
                />
                {errors.address && <FormErrorLabel error="required" />}
              </FormControl>
              <FormControl width="10rem">
                <Input
                  size="sm"
                  {...register("address2", {
                    required: false,
                  })}
                  id="register-address2"
                  type="address2"
                  placeholder="Unit (Optional)"
                  borderColor={errors.address2 ? "red.500" : "gray.300"}
                  fontSize="sm"
                  boxShadow="none"
                />
              </FormControl>
            </Stack>
            <Stack direction="row" spacing="1rem">
              <FormControl isInvalid={!!errors.city}>
                <Input
                  size="sm"
                  {...register("city", {
                    required: true,
                  })}
                  id="register-city"
                  type="city"
                  placeholder="City"
                  borderColor={errors.city ? "red.500" : "gray.300"}
                  fontSize="sm"
                  boxShadow="none"
                />
                {errors.city && <FormErrorLabel error="required" />}
              </FormControl>
              <FormControl isInvalid={!!errors.state}>
                {countrySubdivisionOptions ? (
                  <Select
                    placeholder="State/Province"
                    defaultValue={undefined}
                    size="sm"
                    width={"100%"}
                    value={undefined}
                    {...register("state", { required: true })}>
                    {countrySubdivisionOptions.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    size="sm"
                    id="register-state"
                    type="string"
                    placeholder="State"
                    {...register("state", { required: true })}
                    borderColor={errors.state ? "red.500" : "gray.300"}
                    fontSize="sm"
                    boxShadow="none"
                  />
                )}
                {errors.state && <FormErrorLabel error="required" />}
              </FormControl>
            </Stack>
            <Stack direction="row" spacing="1rem">
              <FormControl isInvalid={!!errors.postcode}>
                <Input
                  size="sm"
                  id="register-postcode"
                  type="string"
                  placeholder="Zip/Postal Code"
                  {...register("postcode", { required: true })}
                  borderColor={errors.postcode ? "red.500" : "gray.300"}
                  fontSize="sm"
                  boxShadow="none"
                />
                {errors.postcode && <FormErrorLabel error="required" />}
              </FormControl>
              <FormControl isInvalid={!!errors.country}>
                <Select placeholder="Country" size="sm" width={"100%"} value={undefined} {...register("country", { required: true })}>
                  {countryCodeOptions.map(({ value, label }, idx) => (
                    <option key={idx} disabled={value === label} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
                {errors.country && <FormErrorLabel error="required" />}
              </FormControl>
            </Stack>
          </Stack>
          <Stack spacing="1rem" width="100%">
            <FormControl overflow="auto">
              <div id="chargify_form" />
            </FormControl>
            <FormControl>
              <Input
                size="sm"
                id="register-promotionCode"
                type="string"
                placeholder="Promotion Code"
                {...register("promotionCode", { required: false })}
                borderColor={errors.promotionCode ? "red.500" : "gray.300"}
                fontSize="sm"
                boxShadow="none"
              />
            </FormControl>
            <FormControl isInvalid={!!errors.acceptTerms}>
              <Stack direction={"row"} spacing="1rem">
                <Checkbox
                  type="checkbox"
                  {...register("acceptTerms", { required: true })}
                  id="register-acceptTerms"
                  borderColor={errors.acceptTerms ? "red.500" : "gray.4700"}
                  fontSize="sm"
                  boxShadow="none"
                />
                <FormLabel mb="0" color={primaryTextColor} lineHeight="2rem" fontSize="sm" htmlFor="register-acceptTerms">
                  Accept{" "}
                  <Link href="https://charliai.com/terms-and-services/" isExternal textDecoration={"underline"}>
                    Terms of Service
                  </Link>
                </FormLabel>
              </Stack>
              {errors.acceptTerms && <FormErrorLabel error="required" textAlign="start" />}
            </FormControl>
          </Stack>
        </Stack>
        <Center pt="2rem" pb="1rem">
          <Button
            {...buttonStyle}
            color="white"
            fontWeight={"normal"}
            bgColor={"#81c34b"}
            borderColor={"#81c34b"}
            width="16rem"
            borderRadius="full"
            id="subscribe-submit"
            type="submit"
            isLoading={isSubmitting}>
            Confirm Upgrade
          </Button>
        </Center>
      </form>
    </Stack>
  );
};
