import type { FunctionComponent } from "react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions as sessionActions } from "state/session/reducer";
import { useBreakpointValue, useToast } from "@chakra-ui/react";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useUserPreference } from "hooks";

export const Logout: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const hasCompletedOnboarding = useUserPreference("ui_onboarding_completed") as boolean;
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  useEffect(() => {
    toast.closeAll();
    isMobile && !hasCompletedOnboarding && dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_completed", value: true }));
    dispatch(sessionActions.logout());
  }, [dispatch, hasCompletedOnboarding, isMobile, toast]);

  return <React.Fragment />;
};
