import { useToast } from "@chakra-ui/react";
import { captureException } from "@sentry/react";
import { getCoordinatesByAnswerAndContent } from "api/contextCoordinates";
import sortBy from "lodash/sortBy";
import { useEffect, useState } from "react";
import type { ContextCoordinates } from "types/contextCoordinates";

export const useContextCoordinates = (answerId: string | undefined, metadataId: string | undefined): ContextCoordinates[] | undefined => {
  const [coordinates, setCoordinates] = useState<ContextCoordinates[] | undefined>(undefined);
  const toast = useToast();

  useEffect(() => {
    if (!answerId || !metadataId) {
      return;
    }

    getCoordinatesByAnswerAndContent(answerId, metadataId)
      .then((contextCoordinates) => setCoordinates(sortBy(contextCoordinates, "pageIndex")))
      .catch((error) => {
        captureException(error, {
          extra: {
            answerId,
            metadataId,
          },
        });

        toast({
          title: "Failed to fetch context coordinates",
          status: "error",
        });
      });
  }, [answerId, metadataId, toast]);

  return coordinates;
};
