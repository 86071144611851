import { useCopyValue } from "hooks/useCopies";
import capitalize from "lodash/capitalize";

export const useTickerTypeOptions = () => {
  const types = useCopyValue("copy_ticker_type_options");
  return types.map((type) => ({
    label: capitalize(type.replace(/_/g, " ")),
    value: type,
  }));
};
