import { Record, String, Number, Array } from "runtypes";
import type { Static } from "runtypes";
import { QuestionAnswerStatus } from "./QuestionAnswerStatus";

export const Answer = Record({
  id: String,
  answer: String,
  nodeId: String,
  chunkId: String,
  confidence: Number,
  answerStatus: QuestionAnswerStatus,
  metadataIds: Array(String),
  creationDate: String,
});

export type Answer = Static<typeof Answer>;
