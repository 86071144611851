import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Wizard } from "react-use-wizard";
import { Box, Button, Center, Icon, Select, Stack, Switch, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { VscSettings } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useButtonProps, useCollection, useEntitlementKey, useTileProps } from "hooks";
import { LandingTitle } from "screens/landing/components";
import { EditableInput } from "screens/content/common/TwoColumnTable/EditableInput";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { portfolioSelectors } from "screens/landing/components/popoverComponent/NewPortfolioModal";
import EditableQuestionList from "screens/common/EditableQuestionList";
import capitalize from "lodash/capitalize";
import type { RequestEntities } from "types/charliui";
import { v4 as uuid } from "uuid";
import { ToastMessageContent } from "screens/common/components";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useWorkflowSchedulesByPortfolio } from "hooks/useWorkflowSchedules";
import orderBy from "lodash/orderBy";
import { ProjectDeleteButton } from "screens/collection/views/ProjectActions/ProjectDeleteButton";

const getPortfolioThemeName = (portfolioTheme: string | undefined) => {
  const selectedPortfolio = portfolioSelectors.find((selector) => selector.value === portfolioTheme);
  return selectedPortfolio ? selectedPortfolio.label : "Coming Soon";
};

const Frequency = ["daily", "weekly", "monthly"] as const;
type Frequency = typeof Frequency[number];

export type TimeOfDay =
  | "night"
  | "early-morning"
  | "morning"
  | "late-morning"
  | "afternoon"
  | "late-afternoon"
  | "evening"
  | "late-evening";

export const timeRangeByTimeOfDay: Record<TimeOfDay, string> = {
  night: "12am - 3am",
  "early-morning": "3am - 6am",
  morning: "6am - 9am",
  "late-morning": "9am - 12pm",
  afternoon: "12pm - 3pm",
  "late-afternoon": "3pm - 6pm",
  evening: "6pm - 9pm",
  "late-evening": "9pm - 12am",
};

const daysByfrequency: Record<Frequency, string[]> = {
  daily: [],
  weekly: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  monthly: Array.from({ length: 28 }, (_, i) => String(i + 1)),
};

interface Recurring {
  isRecurring?: boolean;
  frequency: Frequency;
  timeOfDay: TimeOfDay;
  day?: string;
}

const defaultRecurring: Recurring = {
  isRecurring: undefined,
  frequency: "daily",
  timeOfDay: "night",
  day: undefined,
};

export const PortfolioSettingsPanelInternal = () => {
  const { projectFilter } = useParams();
  const portfolioProject = useCollection(projectFilter);
  const { onPortfolioSettingsClose } = useAddToCharliContext();
  const commonTileProps = useTileProps();
  const commonButtonProps = useButtonProps("sm", "primary");
  const dispatch = useDispatch();
  const { setHeaderText } = useContext(SettingsProviderContext);
  const showPortfolioQuestions = useEntitlementKey("ui_enable_portfolio_questions");
  const [recurring, setRecurring] = useState<Recurring>({ ...defaultRecurring });
  const toast = useToast();
  const workflowSchedules = useWorkflowSchedulesByPortfolio(portfolioProject?.id || "");
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  const hasRerunProjects = useEntitlementKey("enable_rerun_projects");

  const recurringSchedule = useMemo(
    () => orderBy(workflowSchedules, "creationDate", "desc").find(({ frequency }) => frequency !== "one-time"),
    [workflowSchedules]
  );

  const { onConversationOpen, onDeleteProjectModalOpen } = useConversationContext();

  const onHandleEdit = useCallback(
    (entity: string, value: string) => {
      if (!portfolioProject) return;
      const entities = [
        { entity: "collection_id", value: portfolioProject.id },
        { entity, value },
      ];
      setHeaderText(value);
      dispatch(
        sendMessage({
          conversationId: portfolioProject.conversationId || "",
          intent: "/edit_collection",
          entities,
        })
      );
    },
    [dispatch, portfolioProject, setHeaderText]
  );

  const handlePortfolioScheduling = () => {
    if (!portfolioProject) return;

    const entities: RequestEntities = [];

    entities.push({ entity: "frequency", value: recurring.frequency });

    if (recurring.timeOfDay) {
      entities.push({ entity: "time_of_day", value: recurring.timeOfDay });
    }

    if ((recurring.frequency === "weekly" || recurring.frequency === "monthly") && recurring.day) {
      entities.push({ entity: "day", value: recurring.day });
    }

    entities.push({ entity: "intent", value: "rerun_portfolio" });
    entities.push({ entity: "entity_name", value: "portfolio_id" });
    entities.push({ entity: "entity_value", value: portfolioProject?.id });

    if (recurringSchedule?.id) {
      entities.push({ entity: "replaced_workflow_schedule_id", value: recurringSchedule.id });
    }

    const newConversationId = uuid();

    dispatch(
      sendMessage({
        conversationId: newConversationId,
        intent: "/schedule_workflow",
        entities: entities,
      })
    );

    onPortfolioSettingsClose();

    toast({
      render: ({ onClose: onCloseToast }) => (
        <ToastMessageContent
          message={`Workflows have been scheduled to run ${recurring.frequency} ${
            recurring.timeOfDay ? ` between ${timeRangeByTimeOfDay[recurring.timeOfDay]}.` : " at midnight."
          } `}
          onClick={() => {
            onCloseToast();
            onConversationOpen(newConversationId);
          }}
          onClose={onCloseToast}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  useEffect(() => {
    if (recurringSchedule) {
      setRecurring({
        isRecurring: true,
        frequency: recurringSchedule.frequency as Frequency,
        timeOfDay: recurringSchedule.desiredTime ? recurringSchedule.desiredTime : "night",
        day: recurringSchedule.day ? String(recurringSchedule.day) : undefined,
      });
    } else {
      setRecurring((prev) => ({ ...prev, isRecurring: false }));
    }
  }, [recurringSchedule, hasRerunProjects]);

  useEffect(() => {
    if (recurring.isRecurring === false && recurringSchedule && portfolioProject?.id) {
      const entities: RequestEntities = [];

      entities.push({ entity: "workflow_schedule_id", value: recurringSchedule.id });
      entities.push({ entity: "portfolio_id", value: portfolioProject.id });

      const newConversationId = uuid();

      dispatch(
        sendMessage({
          conversationId: newConversationId,
          intent: "/delete_workflow_schedule",
          entities: entities,
        })
      );
    }
  }, [dispatch, recurring.isRecurring, recurringSchedule, portfolioProject?.id]);

  const settings = useMemo(
    () => [
      {
        label: "Name",
        content: (
          <EditableInput
            id={portfolioProject?.id || undefined}
            value={portfolioProject?.name}
            entity="name"
            onEdit={onHandleEdit}
            isEditEnabled
            size="sm"
            maxHeight="1.4rem"
          />
        ),
      },
      {
        label: "Theme",
        content: (
          <Text fontSize="sm" width="100%">
            {getPortfolioThemeName(portfolioProject?.portfolioSettings?.primaryTheme)}
          </Text>
        ),
      },
      {
        label: "Recurring",
        content: (
          <Switch
            className="ch-recurring-toggle"
            isDisabled={!hasRerunProjects}
            width="100%"
            alignSelf="left"
            size="sm"
            colorScheme="primary"
            isChecked={recurring.isRecurring}
            onChange={(evt) => {
              setRecurring((prev) => ({ ...prev, isRecurring: evt.target.checked }));
            }}
          />
        ),
      },
      {
        label: "Frequency",
        content: (
          <Select
            className="ch-frequency-select"
            value={recurring.frequency}
            onChange={(evt) => {
              const newFrequency = evt.target.value as Frequency;

              setRecurring((prev) => ({
                ...prev,
                frequency: newFrequency,
                timeOfDay: "night",
                day: "1",
              }));
            }}
            isDisabled={!recurring.isRecurring || !hasRerunProjects}
            size="sm"
            width="10rem">
            {Frequency.map((option) => (
              <option key={option} value={option} className={`ch-frequency-${option}-option`}>
                {capitalize(option)}
              </option>
            ))}
          </Select>
        ),
      },
      {
        label: "Time of the day",
        content: (
          <Select
            className="ch-time-window-select"
            value={recurring.timeOfDay}
            onChange={(evt) =>
              setRecurring((prev) => ({
                ...prev,
                timeOfDay: evt.target.value as TimeOfDay,
              }))
            }
            isDisabled={!recurring.isRecurring || !hasRerunProjects}
            size="sm"
            width="10rem">
            {Object.entries(timeRangeByTimeOfDay).map(([key, value]) => (
              <option key={key} value={key} className={`ch-time-${key}-option`}>
                {value}
              </option>
            ))}
          </Select>
        ),
      },
      {
        label: "Day",
        content: (
          <Select
            className="ch-day-select"
            value={recurring.day}
            onChange={({ target: { value } }) => setRecurring((prev) => ({ ...prev, day: value }))}
            isDisabled={!hasRerunProjects || !recurring.isRecurring || recurring.frequency === "daily"}
            size="sm"
            width="10rem">
            {(daysByfrequency[recurring.frequency] || []).map((value, index) => (
              <option
                key={value}
                value={recurring.frequency === "weekly" ? index : value}
                className={`ch-day-${value.toLowerCase()}-option`}>
                {value}
              </option>
            ))}
          </Select>
        ),
      },
    ],
    [
      portfolioProject?.id,
      portfolioProject?.name,
      portfolioProject?.portfolioSettings?.primaryTheme,
      onHandleEdit,
      recurring.isRecurring,
      recurring.frequency,
      recurring.timeOfDay,
      recurring.day,
      hasRerunProjects,
    ]
  );

  return (
    <Stack spacing="1rem">
      <Stack {...commonTileProps} backgroundColor={bgColor} pb="1rem" spacing="1rem" cursor="default">
        <LandingTitle titleMaxWidth="10rem" underline text="Portfolio Settings" color="primary.default">
          {portfolioProject?.id && (
            <Stack justifyContent="flex-end" direction="row" spacing="0.5rem" width="100%">
              <ProjectDeleteButton
                projectType="portfolio"
                isIcon={false}
                className="ch-delete-portfolio-landing"
                onOpenConfirmation={onDeleteProjectModalOpen}
                onCloseFunction={onPortfolioSettingsClose}
                projectId={portfolioProject.id}
                navigatePath="/home"
              />
            </Stack>
          )}
        </LandingTitle>
        <Stack spacing="1rem" color={textColor}>
          {settings.map(({ label, content }, index) => (
            <Stack key={index} direction="row" justifyContent="space-between" width="100%" spacing="1rem">
              <Text fontSize="sm" width="14rem">
                {label}
              </Text>
              <Box alignContent={"flex-start"} width="100%">
                {content}
              </Box>
            </Stack>
          ))}
        </Stack>
        {hasRerunProjects && (
          <Stack direction="row" justifyContent="space-between">
            <Button
              className="ch-save-settings-button"
              isDisabled={!recurring.isRecurring}
              {...commonButtonProps}
              mt={4}
              onClick={() => handlePortfolioScheduling()}>
              Save Settings
            </Button>
          </Stack>
        )}
      </Stack>
      <Stack {...commonTileProps} pb="1rem" spacing="1rem" cursor="default" mb="1rem">
        <LandingTitle style={{ width: "22rem" }} underline text="Your Questions for this Portfolio" color="primary.default" />
        {showPortfolioQuestions && !!portfolioProject?.id ? (
          <EditableQuestionList
            className="ch-questions-tab"
            portfolioId={portfolioProject.id}
            portfolioSettings={portfolioProject.portfolioSettings}
          />
        ) : (
          <Text fontSize="sm" color="primary.darkGray">
            Advanced Configurations Available in the Business and Premium Packages
          </Text>
        )}
      </Stack>
    </Stack>
  );
};

export const PortfolioSettingsPanel = () => {
  const { isPortfolioSettingsOpen, onPortfolioSettingsClose } = useAddToCharliContext();
  const headerBgColor = useColorModeValue("gray.100", "gray.700");
  const headerBarColor = useColorModeValue("#81c34c", "teal.800");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  return (
    <PanelView
      maxWidth={["100vw", "80vw", "80vw", "50vw", "50vw"]}
      isOpen={isPortfolioSettingsOpen}
      onClose={onPortfolioSettingsClose}
      panelTitle="Portfolio Settings"
      className="ch-portfolio-settings-button"
      panelHeader={
        <Stack spacing="0" className="ch-verified-panel">
          <Box bgColor={headerBarColor} height="1rem" width="100%" />
          <Stack direction="row" justifyContent="space-between" backgroundColor={headerBgColor} px="1.5rem" py="1rem">
            <Stack color={textColor} width="100%" justifyContent="space-between">
              <Text fontSize="md" fontWeight="semibold">
                Portfolio Settings
              </Text>
              <Text fontSize="sm" fontWeight="normal">
                This page provides configuration and setting information over all the projects in a portfolio and allows you to determine
                the primary theme as well as add default questions for the AI.
              </Text>
            </Stack>
            <Center>
              <Icon as={VscSettings} boxSize={["2rem", "2.5rem", "3rem"]} color="#81c34c" ml="1.5rem" />
            </Center>
          </Stack>
        </Stack>
      }>
      <Wizard>
        <PanelStep>{isPortfolioSettingsOpen && <PortfolioSettingsPanelInternal />}</PanelStep>
      </Wizard>
    </PanelView>
  );
};
