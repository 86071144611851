import React, { lazy, Suspense } from "react";
import type { CopyPreviewProps } from "types/copy";
import { Box, Spinner } from "@chakra-ui/react";

const PreviewBox: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Box
    border="1px solid"
    borderColor="gray.200"
    borderRadius="md"
    boxShadow={["none", "md"]}
    p={0}
    mb={4}
    maxH="400px"
    overflowY="auto"
    overflowX={["auto", "hidden"]}
    position="relative"
    sx={{
      "& > *": {
        transform: "scale(0.75)",
        transformOrigin: "top left",
        width: "133.33%",
        height: "100%",
      },
    }}>
    <Suspense
      fallback={
        <Box p={4}>
          <Spinner />
        </Box>
      }>
      {children}
    </Suspense>
  </Box>
);

// Lazy load the components to avoid circular dependencies
const LazyLoginForm = lazy(() =>
  import("screens/login/components/LoginForm").then((module) => ({
    default: ({ onSubmit }: any) => <module.LoginForm onSubmit={onSubmit} showEmailForm />,
  }))
);

const LazyRegistration = lazy(() =>
  import("screens/login/Registration").then((module) => ({
    default: ({ onSubmit }: any) => <module.Registration onSubmit={onSubmit} isPreview />,
  }))
);

export const LoginPreview: React.FC<CopyPreviewProps> = () => (
  <PreviewBox>
    <LazyLoginForm onSubmit={() => void 0} />
  </PreviewBox>
);

export const RegistrationPreview: React.FC<CopyPreviewProps> = () => (
  <PreviewBox>
    <LazyRegistration onSubmit={() => void 0} />
  </PreviewBox>
);
