import React, { useState, useCallback, useMemo, useRef, useEffect } from "react";
import type { BoxProps } from "@chakra-ui/react";
import {
  Box,
  Stack,
  Text,
  VStack,
  List,
  ListItem,
  Button,
  useColorModeValue,
  HStack,
  Badge,
  Flex,
  Center,
  useToast,
} from "@chakra-ui/react";
import { CheckIcon, MinusIcon } from "@chakra-ui/icons";
import { useSwipeable } from "react-swipeable";
import { useBreakpointValue } from "@chakra-ui/react";
import { useUserProfile, useUserSubscriptionDetails, useUserSubscriptionDetailsDaysTillNextBilling } from "hooks";
import { track } from "api/analytics";
import { USER_UPGRADE_PLAN_CONTACT_US, USER_UPGRADE_PLAN_SELF_SERVE } from "api/analytics/events";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import { useLocation } from "react-router-dom";
import { Intent } from "types/intent";
import { ToastMessageContent } from "screens/common/components";
import type { PricingTier, Feature } from "./PricingTiers";
import { tiers } from "./PricingTiers";
import debounce from "lodash/debounce";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";

const arrowStyles: BoxProps = {
  cursor: "pointer",
  pos: "absolute",
  top: "50%",
  w: "auto",
  mt: "-22px",
  mx: "-12px",
  p: "1px 12px",
  bg: "white",
  color: "gray.700",
  fontWeight: "bold",
  fontSize: "22px",
  transition: "0.6s ease",
  borderRadius: "5px",
  userSelect: "none",
  _hover: {
    bg: "gray.100",
  },
} as const;

const PricingTierFeature: React.FC<{ feature: Feature }> = React.memo(({ feature }) => {
  const borderColor = useColorModeValue("gray.100", "gray.600");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const descriptionColor = useColorModeValue("gray.600", "gray.400");

  return (
    <ListItem borderBottom="1px" borderColor={borderColor} py={2}>
      <Flex justifyContent="space-between" alignItems="center" gap={4}>
        <Text fontSize="sm" flex="1" color={textColor} maxWidth={["100%", "100%", "16rem", "16rem", "16rem"]}>
          {feature.text}
        </Text>
        {feature.available !== undefined ? (
          <Box>
            {feature.available === true ? <CheckIcon color="green.500" boxSize={4} /> : <MinusIcon color={descriptionColor} boxSize={4} />}
          </Box>
        ) : (
          <Text fontSize="sm" textAlign="right" fontWeight="semibold" color={descriptionColor} minWidth="5rem">
            {feature.description}
          </Text>
        )}
      </Flex>
    </ListItem>
  );
});

interface PricingTierProps {
  tier: PricingTier;
  isCurrentPlan: boolean;
  currentPlanName: string;
  onPlanClick: (plan: string) => void;
  bgColor: string;
  popularBgColor: string;
  textColor: string;
  tilesPerView: number;
}

const PricingTierTile: React.FC<PricingTierProps> = React.memo(
  ({ tier, isCurrentPlan, currentPlanName, onPlanClick, bgColor, popularBgColor, textColor, tilesPerView }) => {
    const isPopularNotCurrent =
      tier.isPopular &&
      !tier.canUpgradeTo?.includes(currentPlanName) &&
      tier.internalPlanName !== (isCurrentPlan ? tier.internalPlanName : "");
    const isMobile = useBreakpointValue({ base: true, md: false }) || false;
    const containerRef = useRef<HTMLDivElement>(null);
    const { scrollbarStyle } = useCustomScrollbar(containerRef, { width: "2px" });

    return (
      <Box
        minWidth={isMobile ? "83vw" : "30rem"}
        maxW={isMobile ? "83vw" : "30rem"}
        bg={isPopularNotCurrent ? popularBgColor : bgColor}
        rounded="xl"
        shadow="lg"
        p={8}
        flex={`0 0 ${100 / tilesPerView}%`}
        position="relative"
        transform={isPopularNotCurrent ? { base: "none", lg: "scale(1.05)" } : undefined}
        transformOrigin="center"
        zIndex={tier.isPopular ? 1 : 0}
        style={{ scrollSnapAlign: "start" }}>
        {isPopularNotCurrent && (
          <Badge
            textAlign="center"
            colorScheme="blue"
            position="absolute"
            top="-4"
            right=".5rem"
            rounded="full"
            width="8rem"
            px={3}
            py={1}
            fontSize="0.9em"
            textTransform="none">
            Most Popular
          </Badge>
        )}
        {isCurrentPlan && (
          <Badge
            textAlign="center"
            colorScheme="green"
            position="absolute"
            top="-4"
            right=".5rem"
            rounded="full"
            width="8rem"
            px={3}
            py={1}
            fontSize="0.9em"
            textTransform="none">
            Current Plan
          </Badge>
        )}
        <VStack ref={containerRef} spacing={6} justifyContent={"space-between"} height="full">
          <Stack spacing={"1rem"} minHeight={"12rem"}>
            <Box>
              <Center>
                <Text fontSize="2xl" fontWeight="semibold" mb={2}>
                  {tier.name}
                </Text>
              </Center>
              <Center>
                <HStack spacing={2} align="baseline">
                  <Text fontSize="2xl" fontWeight="bold" lineHeight="1">
                    {tier.price}
                  </Text>
                  {tier.price !== "Free" && tier.price !== "Contact For Pricing" && (
                    <Text fontSize="md" color={textColor}>
                      USD/mo
                    </Text>
                  )}
                </HStack>
              </Center>
              {tier.originalPrice && (
                <Center>
                  <Text fontSize="sm" color={textColor} mt={1}>
                    {tier.originalPrice}
                  </Text>
                </Center>
              )}
            </Box>

            <Text fontSize="md" color={textColor}>
              {tier.description}
            </Text>
          </Stack>

          <Stack width="100%" maxHeight={isMobile ? "20rem" : "auto"} overflowY={isMobile ? "scroll" : "unset"} css={scrollbarStyle}>
            {tier.dataSources && (
              <>
                <Text fontSize="md" fontWeight="semibold">
                  Data Sources
                </Text>
                <List spacing={3} flex="1">
                  {tier.dataSources.map((feature, index) => (
                    <PricingTierFeature key={index} feature={feature} />
                  ))}
                </List>
              </>
            )}

            {tier.features && (
              <>
                <Text fontSize="md" fontWeight="semibold" mt={"2rem"}>
                  Features
                </Text>
                <List spacing={3} flex="1">
                  {tier.features.map((feature, index) => (
                    <PricingTierFeature key={index} feature={feature} />
                  ))}
                </List>
              </>
            )}
          </Stack>

          <Button
            onClick={() => onPlanClick(tier.name)}
            size="lg"
            w="full"
            isDisabled={isCurrentPlan}
            colorScheme={isPopularNotCurrent ? "blue" : "gray"}
            variant={isPopularNotCurrent ? "solid" : "outline"}>
            {tier.canUpgradeTo?.includes(currentPlanName) ? "Change Plan" : isCurrentPlan ? "Subscribed" : tier.buttonText}
          </Button>
        </VStack>
      </Box>
    );
  }
);

const PricingTable: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }) || false;
  const [currentTileIndex, setCurrentTileIndex] = useState(0);
  const tilesPerView = useBreakpointValue({ base: 1, md: 2, lg: 2, xl: 5 }) || 1;
  const subscriptionInfo = useUserSubscriptionDetails();
  const { isUpgrading, onSubscriptionModalOpen } = useConversationContext();
  const { email, fullName } = useUserProfile();
  const daysTillPlanExpiry = useUserSubscriptionDetailsDaysTillNextBilling();
  const answerUsage = useFeatureUsage(Intent.generateAnswer);
  const projectUsage = useFeatureUsage(Intent.createDueDiligenceProject);
  const { pathname } = useLocation();
  const toast = useToast();
  const bgColor = useColorModeValue("white", "gray.700");
  const popularBgColor = useColorModeValue("blue.50", "blue.900");
  const textColor = useColorModeValue("gray.600", "gray.300");

  useEffect(() => {
    setCurrentTileIndex(isMobile ? 1 : 0);
  }, [isMobile]);

  const handleOnClick = useCallback(
    (newPlan?: string) => {
      if (isUpgrading || !newPlan) return;

      const commonTrackingData = {
        pageViewed: pathname,
        userName: fullName,
        userEmail: email,
        answersUsed: answerUsage?.used,
        dueDiligenceUsed: projectUsage?.used,
        currentPlan: subscriptionInfo.plan,
        newPlan: newPlan.toUpperCase(),
        planStatus: subscriptionInfo.status,
        nextBillingDate: subscriptionInfo.nextBillingDate,
        subscriptionId: subscriptionInfo.subscriptionId,
        daysTillPlanExpiry: daysTillPlanExpiry.daysTillNextBilling,
      };

      const isSelfServe = newPlan.toLowerCase() === "professional";

      if (isSelfServe) {
        track(USER_UPGRADE_PLAN_SELF_SERVE, commonTrackingData);
        onSubscriptionModalOpen();
      } else {
        track(USER_UPGRADE_PLAN_CONTACT_US, commonTrackingData);
        toast.closeAll();
        toast({
          render: ({ onClose }) => (
            <ToastMessageContent
              message={`I will contact the team now and let them know that you want to upgrade to the ${newPlan} plan. Someone will be in touch via email shortly.`}
              onClose={onClose}
            />
          ),
          duration: 15000,
          isClosable: true,
          position: "top-right",
        });
      }
    },
    [
      isUpgrading,
      pathname,
      fullName,
      email,
      answerUsage?.used,
      projectUsage?.used,
      subscriptionInfo,
      daysTillPlanExpiry.daysTillNextBilling,
      onSubscriptionModalOpen,
      toast,
    ]
  );

  const debouncedSlideRef = useRef<((direction: "prev" | "next") => void) | null>(null);

  const handleSlide = useCallback((direction: "prev" | "next") => {
    if (!debouncedSlideRef.current) {
      debouncedSlideRef.current = debounce((dir: "prev" | "next") => {
        if (dir === "prev") {
          setCurrentTileIndex((prevIndex) => Math.max(0, prevIndex - 1));
        } else {
          setCurrentTileIndex((prevIndex) => Math.min(tiers.length - 1, prevIndex + 1));
        }
      }, 300);
    }
    debouncedSlideRef.current(direction);
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleSlide("next");
    },
    onSwipedRight: () => {
      handleSlide("prev");
    },
    trackMouse: true,
    trackTouch: true,
    delta: 2,
    rotationAngle: 0,
  });

  // Ensure all tiers have the required properties
  const validTiers = useMemo(() => {
    return (tiers as PricingTier[]).map((tier) => ({
      ...tier,
      isPopular: tier.isPopular || false,
    }));
  }, []);

  return (
    <Box position="relative" width="full" overflow="hidden" p="1rem">
      <Flex w="full" alignItems="center" justifyContent="center" position="relative">
        <Flex
          w="full"
          overflow="hidden"
          {...handlers}
          style={{
            touchAction: "pan-y pinch-zoom",
          }}>
          <Stack
            direction="row"
            spacing="1.5rem"
            pt="2.7rem"
            pb={isMobile ? "0" : "2.5rem"}
            style={{
              transform: `translateX(calc(-${currentTileIndex} * (${isMobile ? "90vw" : "21.5rem"})))`,
              transition: "transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
              width: `100%`,
              willChange: "transform",
              scrollSnapType: "x mandatory",
              scrollBehavior: "smooth",
              WebkitOverflowScrolling: "touch",
            }}>
            {validTiers.map((tier, index) => (
              <PricingTierTile
                key={index}
                tier={tier}
                isCurrentPlan={tier.internalPlanName === subscriptionInfo.internalPlanName}
                currentPlanName={subscriptionInfo.internalPlanName}
                onPlanClick={() => handleOnClick(tier.internalPlanName)}
                bgColor={bgColor}
                popularBgColor={popularBgColor}
                textColor={textColor}
                tilesPerView={tilesPerView}
              />
            ))}
          </Stack>
          {currentTileIndex > 0 && (
            <Text {...arrowStyles} left="0" onClick={() => handleSlide("prev")}>
              &#10094;
            </Text>
          )}
          {currentTileIndex < 4 && (
            <Text {...arrowStyles} right="0" onClick={() => handleSlide("next")}>
              &#10095;
            </Text>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default PricingTable;
