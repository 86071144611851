import React from "react";
import { UpsertCopyModal } from "screens/landing/tabs/admin/copies/components/UpsertCopy";
import { useUpsertCopyModal } from "../hooks/useUpsertCopyModal";

interface TickerUpsertCopyModalProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  copyKey: string;
  options: Array<{ value: string }>;
  entityName: string;
}

export const TickerUpsertCopyModal: React.FC<TickerUpsertCopyModalProps> = ({
  isOpen,
  onClose,
  isLoading,
  copyKey,
  options,
  entityName,
}) => {
  const { handleSubmit } = useUpsertCopyModal({ onClose, entityName });

  return (
    <UpsertCopyModal
      isOpen={isOpen}
      onClose={onClose}
      initialValue={{
        key: copyKey,
        value: options.map((option) => option.value),
        createdAt: new Date().toISOString(),
        createdByUser: "N/A",
      }}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
};
