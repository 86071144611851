import {
  Stack,
  Text,
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  useColorModeValue,
  Center,
  List,
  ListItem,
  ListIcon,
  IconButton,
  Tooltip,
  Portal,
  useBreakpointValue,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";
import OnboardingVideo from "../OnboardingVideo";
import { useButtonProps, useConfigMap, useGetViewConfig, useUserPreference } from "hooks";
import { BsCursor } from "react-icons/bs";

const FirstStep = ({ onClose, onNext }: { onClose: () => void; onNext: () => void }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.100", "gray.800");
  const commonButtonProps = useButtonProps("md", "cta");

  return (
    <PopoverContent
      boxShadow={"lg"}
      width="22rem"
      border="1px solid"
      borderColor={borderColor}
      bg={bgColor}
      _focus={{ boxShadow: "none" }}
      zIndex={10}>
      <PopoverArrow bg={bgColor} borderColor={borderColor} sx={{ top: "2rem !important" }} />
      <PopoverBody px={"1rem"}>
        <Tooltip label="Close the tour" aria-label="Close the tour" placement="top">
          <IconButton
            aria-label="Close popover"
            icon={<CloseIcon />}
            size="sm"
            position="absolute"
            right="0.5rem"
            top="0.5rem"
            onClick={onClose}
          />
        </Tooltip>
        <Stack spacing="1.5rem" p="1rem">
          <Text fontSize="xl" fontWeight="bold">
            Step 3: Tour the Features
          </Text>
          <Stack spacing="1rem">
            <Text fontSize="lg" fontWeight={"semibold"}>
              Check out Shared Research
            </Text>
            <Text fontSize="md">
              Shared Portfolios are a great way to see the power of Charli's automated AI research. This is read only access to real-time
              research performed by our analysts and customers that share access to specific portfolios.
            </Text>
            <Text fontSize="md">
              In each of the Shared Portfolios, you can see the level of detail provided by the AI, as well as the options to dig deeper for
              additional intelligence on the specific investments and the market.
            </Text>
          </Stack>
          <Center pt="1rem">
            <Button {...commonButtonProps} borderRadius="full" width="12rem" onClick={onNext}>
              Next
            </Button>
          </Center>
        </Stack>
      </PopoverBody>
    </PopoverContent>
  );
};

const SecondStep = ({ onClose }: { onClose: () => void }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.100", "gray.800");
  const commonButtonProps = useButtonProps("md", "cta");
  const configMap = useConfigMap();
  const homeOnboardingSteps = useGetViewConfig("onBoardingSteps", "home", configMap);
  const videoURL = homeOnboardingSteps && homeOnboardingSteps[0].url ? homeOnboardingSteps[0].url : "";

  return (
    <PopoverContent
      boxShadow={"lg"}
      width="22rem"
      border="1px solid"
      borderColor={borderColor}
      bg={bgColor}
      _focus={{ boxShadow: "none" }}
      zIndex={10}>
      <PopoverArrow bg={bgColor} borderColor={borderColor} sx={{ bottom: "2rem !important", top: "unset !important" }} />
      <PopoverBody px={"1rem"}>
        <Tooltip label="Close the tour" aria-label="Close the tour" placement="top">
          <IconButton
            aria-label="Close popover"
            icon={<CloseIcon />}
            size="sm"
            position="absolute"
            right="0.5rem"
            top="0.5rem"
            onClick={onClose}
          />
        </Tooltip>
        <Stack spacing="1rem" p="1rem">
          <Text fontSize="xl" fontWeight="bold">
            Step 3: Tour the Features
          </Text>
          <Box width="100%">
            <OnboardingVideo maxImageWidth="100%" videoURL={videoURL} showCloseButton={false} onCloseVideo={onClose} />
          </Box>
          <Text fontSize="lg" fontWeight={"semibold"}>
            Check out Interactive Video
          </Text>
          <Text fontSize="md">
            While Charli is analyzing your first project; the Interactive Video is a perfect way to get to know the features.
          </Text>
          <List spacing={2}>
            <ListItem fontSize="md" display="flex" alignItems="top">
              <ListIcon as={BsCursor} color="gray.500" transform="rotate(45deg)" mt="4px" />
              <Text>Charli will email you a summary report as soon as the research is complete.</Text>
            </ListItem>
            <ListItem fontSize="md" display="flex" alignItems="top">
              <ListIcon as={BsCursor} color="gray.500" transform="rotate(45deg)" mt="4px" />
              <Text>Start new research right away, Charli can work on many at the same time</Text>
            </ListItem>
            <ListItem fontSize="md" display="flex" alignItems="top">
              <ListIcon as={BsCursor} color="gray.500" transform="rotate(45deg)" mt="4px" />
              <Text>You can also check out the free shared research on the side panel</Text>
            </ListItem>
          </List>
          <Center pt="1rem">
            <Button {...commonButtonProps} borderRadius="full" width="12rem" onClick={onClose}>
              Close the Tour
            </Button>
          </Center>
        </Stack>
      </PopoverBody>
    </PopoverContent>
  );
};

const OnboardingVideoPopover = () => {
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false }, { fallback: "md", ssr: false });
  const hasCompletedOnboarding = useUserPreference("ui_onboarding_completed") as boolean;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(!hasCompletedOnboarding && !isMobile);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(1);
  };

  const handleComplete = () => {
    setIsOpen(false);
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_completed", value: true }));
  };

  return (
    <>
      {isOpen && (
        <Box position="fixed" top="0" left="0" right="0" bottom="0" bg="blackAlpha.600" zIndex={10} display="block" pointerEvents="none" />
      )}
      <Popover isOpen={isOpen} placement="right-start" arrowSize={40} closeOnEsc={false} closeOnBlur={false}>
        <PopoverTrigger>
          <Box position={"absolute"} top={activeStep === 1 ? "calc(100vh - 8rem)" : "14rem"} height="4rem" width="12rem" />
        </PopoverTrigger>
        <Portal>
          {activeStep === 0 ? <FirstStep onClose={handleComplete} onNext={handleNext} /> : <SecondStep onClose={handleComplete} />}
        </Portal>
      </Popover>
    </>
  );
};

export default OnboardingVideoPopover;
