import { Box, Stack, useColorModeValue, Image, Text, Button } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useCallback, useMemo, useState } from "react";
import { StockEquityChart } from "screens/collection/components/StockEquityChart";
import { generateMockStockEquityData } from "types/stock";
import charliLogo from "screens/common/static/logos/charli-logo-full.svg";
import { useButtonProps, useUserProfile } from "hooks";
import { useConversationDialogSubmitFunctions } from "screens/thread/useConversationDialogSubmitFunctions";
import { giveUserCreditForProjectsRun } from "api/usage";
import { useDispatch } from "react-redux";
import { downloadCurrentUserFeatureUsage } from "state/usage/operations";
import { AddIcon } from "@chakra-ui/icons";

interface Props {
  logo?: string;
  companyName?: string;
  ticker?: string;
  exchange?: string;
  securityType?: string;
  overview?: string;
}

export const HomePlaceholderTile: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  logo,
  companyName = "Charli AI",
  ticker,
  exchange,
  securityType,
  overview,
}) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const tileBorderColor = useColorModeValue("gray.300", "gray.700");
  const borderColor = useColorModeValue("gray.100", "gray.600");
  const overlayColor = useColorModeValue("rgba(255, 255, 255, 0.7)", "rgba(26, 32, 44, 0.7)");
  const commonButtonProps = useButtonProps("md", "cta");
  const { onSubmitDueDiligenceProject } = useConversationDialogSubmitFunctions({});
  const [isRequesting, setIsRequesting] = useState(false);
  const { id: userId } = useUserProfile();
  const dispatch = useDispatch();
  const stockEquityData = useMemo(() => generateMockStockEquityData(), []);

  const onStartDueDiligence = useCallback(() => {
    if (!ticker || !exchange || !securityType) {
      return;
    }
    setIsRequesting(true);
    onSubmitDueDiligenceProject(ticker, exchange, undefined, undefined, undefined, securityType);

    setTimeout(async () => {
      if (!userId) {
        return;
      }

      try {
        await giveUserCreditForProjectsRun(userId, "Giving user credit for already shown on the homepage");
      } catch (error) {
        console.error(error);
      }

      dispatch(downloadCurrentUserFeatureUsage());
    }, 3000);
    setTimeout(() => dispatch(downloadCurrentUserFeatureUsage()), 6000);
  }, [dispatch, exchange, onSubmitDueDiligenceProject, securityType, ticker, userId]);

  return (
    <Box
      position="relative"
      backgroundColor={bgColor}
      borderColor={tileBorderColor}
      borderWidth="1px"
      borderRadius="md"
      justifyContent="flex-start"
      width="100%"
      height={"100%"}
      minH="13rem"
      overflow="hidden"
      p="1rem"
      pr="11px"
      whiteSpace="normal"
      textAlign="left"
      className={`ch-collections-tab-placeholder-tile`}>
      <Stack direction="row" height="100%" width="100%" spacing="1rem">
        <Stack width="12rem" justifyContent={"space-between"}>
          <Box justifyContent={"left"} width="100%">
            <Image
              maxHeight="2rem"
              fit={"contain"}
              src={logo || charliLogo}
              backgroundColor={"transparent"}
              border={`1px solid ${borderColor}`}
              flexShrink={0}
              width={300}
              height={60}
              loading="lazy"
            />
          </Box>
          <Box>
            <StockEquityChart height="8rem" hideAxis stockEquityData={stockEquityData} />
          </Box>
        </Stack>
        <Box alignContent={"top"} pr="5px" width="100%" maxHeight="11rem" overflow={"hidden"}>
          <Stack>
            <Text fontSize={"xs"} fontWeight={"semibold"}>
              {`${companyName}'s Company Nature and Overview:`}
            </Text>
            <Text fontSize={"xs"}>{overview}</Text>
          </Stack>
        </Box>
      </Stack>
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        backgroundColor={overlayColor}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Button {...commonButtonProps} isDisabled={isRequesting} onClick={onStartDueDiligence} leftIcon={<AddIcon />}>
          {`Start a Free Analysis on ${ticker}`}
        </Button>
      </Box>
    </Box>
  );
};
