import type { Collection } from "types/collection";
import type { BuySellHoldData } from "../BuySellHoldChart/BuySellHoldChart";
import type { StockTickerData } from "api/stockTickerData";

export const generateBuySellData = (
  groupedCollections: Collection[],
  getStockData: (ticker: string, exchange: string) => StockTickerData | undefined
): BuySellHoldData => {
  const items = (groupedCollections || []).flatMap((collection) => {
    const { ticker, stockExchange, buySellScoreSentiment, id } = collection || {};
    if (!ticker || !stockExchange) return [];

    const sentimentValue = buySellScoreSentiment?.value ?? "";
    const regexRating = sentimentValue.match(/\d+(\.\d+)?/g);

    const stockData = getStockData?.(ticker, stockExchange);
    const price = parseFloat(stockData?.ticker_quote?.price ?? "0");
    const ratingValue = regexRating?.[0] ?? undefined;

    const rating = ratingValue && Number.isFinite(parseFloat(ratingValue)) ? Math.min(5, Math.max(0, parseFloat(ratingValue))) : undefined;

    if (!rating) return [];
    else return [{ projectId: id, ticker, currentPrice: price, rating }];
  });

  return { items };
};
