import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Stack,
  useBreakpointValue,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  Icon,
  IconButton,
  Portal,
  Tooltip,
  Tag,
  Button,
  useColorMode,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useButtonProps,
  useCollectionHasHighlights,
  useCollectionKey,
  useConfigMap,
  useGetViewConfig,
  useMainCollectionWorkflowId,
  useProjectInPortfolios,
  useProjectParams,
  useTileProps,
  useCompanyStockFromCollectionContentsOrConversation,
  useProjectStockEquityDataFromCollectionMetadata,
  useDownloadCollectionContents,
  useCollectionMetadataIds,
  useLatestCollectionWorkflowId,
  useFeatureFlags,
  useContents,
  useFilterAndGroupReports,
} from "hooks";
import type { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { ConversationContext, useConversationContext } from "screens/thread/ConversationContext";
import { ProjectDeleteButton } from "../../../../collection/views/ProjectActions/ProjectDeleteButton";
import { CollectionsFilterContext } from "screens/landing/tabs";
import { ProjectRerunCancelButton } from "../../../../collection/views/ProjectActions/ProjectRerunCancelButton";
import { WorkflowProgressStepperContainer } from "screens/landing/components/WorkflowProgressStepper";
import { CollectionStatProgress } from "../../../../collection/components/CollectionStatProgress";
import { IoEllipsisVertical } from "react-icons/io5";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { StockIndicator } from "./components/StockIndicator";
import { RiskIndicator } from "./components/RiskIndicator";
import { ProjectReportPanelButton } from "screens/collection/components/ProjectReportPanelButton";
import { ProjectHeaderImage } from "./components/ProjectHeaderImage";
import { TagInput } from "screens/common/components/Tags/TagInput";
import { ProjectMoveButton } from "screens/collection/views/ProjectActions/ProjectMoveButton";
import { MoveProjectModal } from "screens/collection/views/ProjectActions/MoveProjectModal";
import { v4 as uuid } from "uuid";
import { useWorkflowCompletionDateOrNow, useWorkflowStatus } from "hooks/useWorkflows";
import { BiShare } from "react-icons/bi";
import { usePausedWorkflowModal } from "screens/collection/views/ProjectActions/PausedWorkflowModal";
import { StockEquityChart } from "screens/collection/components/StockEquityChart";
import { WorkflowProgressBar } from "screens/landing/components/WorkflowProgressBar";
import { formatDistanceToNow } from "date-fns";
import { useWorkflowInProgressOrClarificationRequested } from "hooks/useCheckpoints";
import { generateMockStockEquityData } from "types/stock";
import { useBuySellChartColors } from "../../collections/chartComponents/BuySellHoldChart/BuySellHoldChart";
import { useWorkflowScheduleByProject } from "hooks/useWorkflowSchedules";
import { MockMilestonesStepper } from "screens/landing/components/MockMilestonesStepper";
import { AddIcon } from "@chakra-ui/icons";
import chartIcon from "screens/common/static/misc/chart_icon.svg";
import { FiShare2 } from "react-icons/fi";
import { GrLineChart } from "react-icons/gr";
import { ContentPreview } from "screens/content/contentView/previewSection/ContentPreview";
import type { ContentDetails } from "types/content/ContentDetails";
import { ProjectAnalysisPopover } from "../../collections/chartComponents/ProjectAnalysisPopover";

interface Props {
  collectionId: string;
  isChildProject?: boolean;
}

export const ProjectLandingDefaultTile: FunctionComponent<Props> = ({ collectionId, isChildProject }) => {
  const { projectFilter, isPortfolios, parentRoute } = useProjectParams();
  const tileBorderColor = useColorModeValue("gray.300", "gray.600");
  const tagBgColor = useColorModeValue("white", "gray.700");
  const tagBorderColor = useColorModeValue("gray.300", "gray.500");
  const tagColor = useColorModeValue("gray.600", "gray.500");
  const overlayBgColor = useColorModeValue("rgba(255, 255, 255, 0.9)", "rgba(26, 32, 44, 0.9)");
  const bgColorAddTicker = useColorModeValue("white", "transparent");
  const chartColors = useBuySellChartColors();
  const navigate = useNavigate();
  const { onConversationOpen, onConversationClose } = useConversationContext();
  const configMap = useConfigMap();
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const collectionUnstructuredData = useCollectionKey(collectionId, "unstructuredData");
  const collectionMetadata = useCollectionKey(collectionId, "metadata");
  const collectionName = useCollectionKey(collectionId, "name");
  const projectGroupId = useCollectionKey(collectionId, "projectGroupId");
  const shareDetails = useCollectionKey(collectionId, "shareDetails");
  const isSharedRead = shareDetails && shareDetails.accessMode === "read";
  const collectionConversationId = useCollectionKey(collectionId, "conversationId");
  const hasHighlights = useCollectionHasHighlights(collectionId);
  const projectRoute = useGetViewConfig("route", collectionType, configMap);
  const { searchText } = useContext(CollectionsFilterContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const ctaButtonProps = useButtonProps("md", "cta");
  const commonTileProps = useTileProps();
  const collectionMetadataIdsOrEmpty = useMemo(() => collectionMetadataIds ?? [], [collectionMetadataIds]);
  const filteredContentIdsWithoutProjectOutput = useCollectionMetadataIds(collectionId);
  const showProjectTags = useGetViewConfig("showProjectTags", collectionType, configMap);
  const tileRef = useRef<HTMLDivElement>(null);
  const leftSectionRef = useRef<HTMLDivElement>(null);
  const rightSectionRef = useRef<HTMLDivElement>(null);
  const [showWorkflowStepper, setShowWorkflowStepper] = useState(false);
  const workflowId = useMainCollectionWorkflowId(collectionId);
  const workflowStatus = useWorkflowStatus(workflowId);
  const portfoliosProjectIsIn = useProjectInPortfolios(collectionId);
  const { onDeleteProjectModalOpen } = useContext(ConversationContext);
  const { setSelectedProjectId } = useContext(CollectionsFilterContext);
  const { onOpen: onOpenMoveProject, onClose: onCloseMoveProject, isOpen: isOpenMoveProject } = useDisclosure();
  const { onOpen: onOpenPausedWorkflow } = usePausedWorkflowModal();
  const companyStockDataSource = useCompanyStockFromCollectionContentsOrConversation(collectionId);
  const stockEquityData = useProjectStockEquityDataFromCollectionMetadata(collectionMetadataIdsOrEmpty || []);
  const placeholderStockEquityData = useMemo(() => generateMockStockEquityData(), []);
  const currentWorkflowId = useLatestCollectionWorkflowId(collectionId);
  const currentWorkflowCompletionDate = useWorkflowCompletionDateOrNow(currentWorkflowId);
  const isInProgress = useWorkflowInProgressOrClarificationRequested(currentWorkflowId);
  const { disable_stock_equity: hasDisableStockEquity, buy_sell_tag: showBuySellTag } = useFeatureFlags();
  const oneTimeSchedule = useWorkflowScheduleByProject(collectionId);
  const isPlaceholderTile = collectionId === "placeholder";
  const showStockTicker = useGetViewConfig("showStockTicker", collectionType, configMap) || isPlaceholderTile;
  const { colorMode } = useColorMode();
  const isDueDiligence = useMemo(() => projectFilter === "due_diligence" || isPortfolios, [isPortfolios, projectFilter]);
  const contentData = useContents(collectionMetadataIds ?? [], { refreshFromNetwork: true });
  const contentDataFiltered = useFilterAndGroupReports(contentData);
  const firstContentDetailFromFilter = useMemo(() => {
    const firstGroup = Object.values(contentDataFiltered)[0];
    return firstGroup?.[0] as ContentDetails | undefined;
  }, [contentDataFiltered]);

  const mockCompanyStockData = useMemo(() => {
    return {
      name: "--",
      exchange: "--",
      ticker: "--",
    };
  }, []);

  // If the stock data is not available, use the mock data
  const companyStockData = isPlaceholderTile ? mockCompanyStockData : companyStockDataSource;

  const hasOneTimeSchedule = useMemo(() => !!oneTimeSchedule, [oneTimeSchedule]);

  const { minDate, maxDate } = useMemo(() => {
    if (!stockEquityData?.data?.chart?.result?.[0]?.timestamp) {
      return { minDate: "", maxDate: "" };
    }

    const timestamps = stockEquityData.data.chart.result[0].timestamp;
    const dates = timestamps.map((timestamp) => {
      const date = new Date(timestamp * 1000);
      return date.toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" });
    });

    return {
      minDate: dates[0],
      maxDate: dates[dates.length - 1],
    };
  }, [stockEquityData]);

  useEffect(() => {
    const tileElement = tileRef.current;
    const leftSectionElement = leftSectionRef.current;
    const rightSectionElement = rightSectionRef.current;

    if (tileElement && leftSectionElement && rightSectionElement) {
      const observer = new ResizeObserver(() => {
        const rightSectionWidth = 265;
        const doesContentFitInTile = tileElement.offsetWidth - 60 > leftSectionElement.offsetWidth + rightSectionWidth;
        setShowWorkflowStepper(doesContentFitInTile);
      });

      observer.observe(tileElement);
      observer.observe(leftSectionElement);
      observer.observe(rightSectionElement);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const hasRiskAssessment = useMemo(() => {
    return collectionUnstructuredData?.data?.assessment_results[0]?.risk_assessment_result || undefined;
  }, [collectionUnstructuredData]);

  const stockTitle = useMemo(() => {
    if (!companyStockData) return "-- : --";
    return `${companyStockData?.exchange}: ${companyStockData?.ticker}`;
  }, [companyStockData]);

  useDownloadCollectionContents(collectionMetadataIds, collectionId);

  const getPath = () => {
    if (projectRoute) {
      onConversationClose();
      return navigate(`/${parentRoute}/${projectFilter}/${collectionId}`);
    } else {
      onConversationOpen();
    }
  };

  const handleAddTicker = useCallback(() => {
    const inputElement = document.getElementById("view-input") as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  }, []);

  const renderTags = useCallback(() => {
    return showProjectTags ? (
      <Box
        width={
          showStockTicker || hasRiskAssessment ? ["6rem", "10rem", "10rem", "14rem", "17rem"] : ["6rem", "14rem", "15rem", "17rem", "23rem"]
        }>
        <TagInput
          size="xs"
          tags={collectionMetadata?.autoTags || []}
          tagType="auto"
          hideInput
          allowUpdate={false}
          allowDelete={false}
          maxTagsToShow={3}
          searchPhrase={searchText}
        />
      </Box>
    ) : (
      <React.Fragment />
    );
  }, [collectionMetadata?.autoTags, hasRiskAssessment, searchText, showProjectTags, showStockTicker]);

  return (
    <>
      <Box
        ref={tileRef}
        {...commonTileProps}
        pt="1rem"
        pb={isMobile ? "1.5rem" : "1rem"}
        onClick={() => {
          if (!isPlaceholderTile) {
            getPath();
          }
        }}
        _hover={{
          boxShadow: "0 0 6px rgba(0, 0, 0, 0.1)",
        }}
        onMouseEnter={() => setSelectedProjectId(collectionId)}
        overflow="visible"
        textAlign="left"
        className={`${
          isPlaceholderTile ? "ch-project-landing-placeholder-tile" : `ch-project-landing-default-${isSharedRead ? "shared" : "tile"}`
        } ${hasHighlights ? "has" : "no"}-highlights`}>
        {isDueDiligence && isPlaceholderTile && (
          <>
            <Box
              zIndex={1}
              className="ch-project-tile-overlay"
              position="absolute"
              width="100%"
              height="100%"
              top="0"
              left="0"
              right="0"
              bottom="0"
              alignItems="center"
              justifyContent="center"
              backgroundColor={overlayBgColor}
              opacity={colorMode === "light" ? 0.6 : 0.7}
              borderRadius="md"
            />
            <Box position={"absolute"} mt=".2rem" width="100%" ml="7rem" zIndex={1}>
              <Stack
                onClick={(event) => {
                  event.preventDefault();
                  handleAddTicker();
                }}
                width="18rem"
                direction="row"
                align="center"
                spacing="1.2rem"
                justifyContent={"start"}
                bgColor={bgColorAddTicker}>
                <Image src={chartIcon} boxSize={["1.5rem", "3rem", "3rem"]} />
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    handleAddTicker();
                  }}
                  {...ctaButtonProps}
                  className="ch-project-landing-add-ticker-button"
                  leftIcon={<AddIcon />}>
                  Add a New Ticker
                </Button>
              </Stack>
            </Box>
          </>
        )}
        <Stack direction="row" justifyContent="space-between" align="center" width="100%">
          {!isMobile && !isInProgress && showBuySellTag && (
            <Tooltip label={`BUy Sell Status`} aria-label="Portfolio Name" placement="top">
              <Tag
                position={"absolute"}
                top="-0.8rem"
                left="1rem"
                className="ch-project-landing-stock-status-tag"
                zIndex={2}
                variant="subtle"
                borderColor={"gray.300"}
                borderWidth="1px"
                bgColor={chartColors.hold}
                fontSize="0.65rem"
                cursor={"pointer"}>
                HOLD
              </Tag>
            </Tooltip>
          )}
          <Stack ref={leftSectionRef} direction="row" align="center" spacing="1.2rem" justifyContent={"start"}>
            {!isMobile &&
              (isSharedRead && firstContentDetailFromFilter ? (
                <Box position="relative" width="5rem" className="ch-project-landing-report-preview-container">
                  <ProjectAnalysisPopover projectId={collectionId}>
                    <Box borderColor={tileBorderColor} borderWidth="1px" borderRadius="md">
                      <ContentPreview
                        thumbnailFit="fill"
                        maxHeight="4rem"
                        backgroundColor={"transparent"}
                        itemContent={firstContentDetailFromFilter}
                        thumbnailHeight={"120px"}
                        thumbnailWidth={"260px"}
                        noOnClickAction
                      />
                    </Box>
                  </ProjectAnalysisPopover>
                </Box>
              ) : (
                <Stack width="6rem" align="center">
                  <CollectionStatProgress
                    {...(workflowStatus === "failed_checkstop" && {
                      onClick: () => {
                        onOpenPausedWorkflow(collectionId);
                      },
                    })}
                    hasOneTimeSchedule={hasOneTimeSchedule}
                    showTimeDetails
                    collectionId={collectionId}
                    size="xxs"
                    isPlaceholderTile={isPlaceholderTile}
                  />
                </Stack>
              ))}
            {(collectionType || isPlaceholderTile) && (
              <ProjectHeaderImage
                collectionId={collectionId}
                projectName={companyStockData?.name}
                metadataIds={filteredContentIdsWithoutProjectOutput}
                projectType={collectionType}
                projectGroupId={projectGroupId}
                logoOnly={isPlaceholderTile}
              />
            )}

            {showStockTicker ? (
              <>
                {!isMobile && (
                  <Stack className="ch-project-tile-stock-details" spacing="0" width={["6rem", "10rem", "10rem", "10rem", "11rem"]}>
                    <>
                      <TextOverflowTooltip
                        className="ch-project-company-name"
                        breakWord={false}
                        searchText={searchText}
                        label={companyStockData ? companyStockData.name : collectionName ?? "n/a"}
                      />

                      <TextOverflowTooltip
                        className="ch-project-stock-details-data"
                        breakWord={false}
                        searchText={searchText}
                        label={`${companyStockData?.exchange || "--"} : ${companyStockData?.ticker || "--"}`}
                      />
                    </>
                  </Stack>
                )}
                {!isMobile && !hasDisableStockEquity && (
                  <Tooltip zIndex={2} placement="top" label={!stockEquityData ? "Retrieving Stock Data" : `From ${minDate} to ${maxDate}`}>
                    <Box width="13rem">
                      <StockEquityChart
                        width={208}
                        height="2.5rem"
                        hideAxis
                        usingMockData={!stockEquityData}
                        stockEquityData={stockEquityData || placeholderStockEquityData}
                      />
                    </Box>
                  </Tooltip>
                )}
                {!isChildProject && (
                  <StockIndicator
                    companyTicker={companyStockData?.ticker}
                    companyStockTitle={stockTitle}
                    companyStockExchange={companyStockData?.exchange}
                  />
                )}
              </>
            ) : (
              <>
                {collectionName && (
                  <TextOverflowTooltip
                    className="ch-project-landing-default-tile-title"
                    label={collectionName}
                    style={{ width: showStockTicker || hasRiskAssessment || showProjectTags ? "10rem" : "20rem" }}
                  />
                )}
                {hasRiskAssessment && <RiskIndicator riskLevel={hasRiskAssessment} />}
              </>
            )}
            {!isMobile && renderTags()}
          </Stack>
          <Stack ref={rightSectionRef} direction="row" justifyContent="flex-end" align="center" spacing={"1.2rem"}>
            {showWorkflowStepper && (
              <>
                {isPlaceholderTile ? (
                  <MockMilestonesStepper />
                ) : (
                  <Box width={"100%"} display={"flex"} alignItems="center" justifyContent="flex-end">
                    <WorkflowProgressStepperContainer
                      hasOneTimeSchedule={hasOneTimeSchedule}
                      oneTimeScheduleDesiredExecutionDate={oneTimeSchedule?.desiredExecutionDate}
                      maxWidth="17rem"
                      collectionId={collectionId}
                      hideProgressBar={false}
                    />
                  </Box>
                )}
              </>
            )}
            {isMobile && currentWorkflowId && isInProgress && (
              <Box zIndex={2} position="absolute" left="3px" bottom="-5px" bgColor={tagBgColor} width="98%">
                <WorkflowProgressBar
                  workflowId={currentWorkflowId}
                  size="compact"
                  lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
                    addSuffix: true,
                    includeSeconds: false,
                  })}
                  progressIndicatorStyle="combined"
                  conversationId={collectionConversationId}
                />
              </Box>
            )}
            <Stack
              direction="row"
              borderColor={tileBorderColor}
              borderWidth="1px"
              borderRadius="md"
              py={isMobile ? "0" : "0.2rem"}
              px={isMobile ? "0.4rem" : "0.5rem"}>
              {!isMobile && <ProjectReportPanelButton isDisabled={isSharedRead} collectionId={collectionId} />}
              <Menu isLazy>
                <Tooltip label={isChildProject ? "Version Options" : "Project Options"} aria-label="Options" placement="top">
                  <MenuButton
                    isDisabled={!!isSharedRead}
                    {...secondaryButtonProps}
                    className="ch-project-landing-tile-options-menu"
                    _hover={{
                      color: "primary.default",
                    }}
                    bgColor={"transparent"}
                    _active={{}}
                    variant="ghost"
                    as={IconButton}
                    aria-label="content options"
                    icon={<Icon as={IoEllipsisVertical} _active={{}} _hover={{}} />}
                    onClick={(event) => event.stopPropagation()}
                    minWidth="0"
                  />
                </Tooltip>
                <Portal>
                  <MenuList minWidth={"unset"} zIndex={10}>
                    {collectionId && (
                      <>
                        {!isChildProject && (isPortfolios || projectFilter === "due_diligence") && (
                          <ProjectMoveButton isDisabled={workflowStatus === "in_progress"} onClick={onOpenMoveProject} />
                        )}
                        {!isChildProject && <ProjectRerunCancelButton reRunProjectId={collectionId} />}
                        <ProjectDeleteButton
                          label={isChildProject ? "Delete Version" : "Delete Project"}
                          onOpenConfirmation={onDeleteProjectModalOpen}
                          projectId={collectionId}
                          isMenuItem
                          projectType={isChildProject ? "version" : "project"}
                        />
                      </>
                    )}
                  </MenuList>
                </Portal>
              </Menu>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          align="center"
          justifyContent={"start"}
          position="absolute"
          bottom="-0.7rem"
          left={isMobile ? ".8rem" : "8rem"}>
          {isMobile && currentWorkflowId && !isInProgress && (
            <Box pt="5px" bgColor={"transparent"}>
              <WorkflowProgressBar
                workflowId={currentWorkflowId}
                size="compact"
                lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
                  addSuffix: true,
                  includeSeconds: false,
                })}
                progressIndicatorStyle="combined"
                conversationId={collectionConversationId}
              />
            </Box>
          )}
          {isMobile && currentWorkflowId && !isInProgress && showBuySellTag && (
            <Tooltip label={`Status of the stock`} aria-label="Portfolio Name" placement="top">
              <Tag
                className="ch-project-landing-stock-status-tag"
                zIndex={2}
                variant="subtle"
                borderColor={"gray.300"}
                borderWidth="1px"
                bgColor={chartColors.hold}
                fontSize="0.65rem"
                cursor={"pointer"}>
                HOLD
              </Tag>
            </Tooltip>
          )}
          {!isPortfolios &&
            portfoliosProjectIsIn.map((portfolio, index) => {
              return (
                <Tooltip
                  key={portfolio.id}
                  label={`Open  ${shareDetails?.accessMode === "read" ? "shared" : ""} portfolio ${portfolio.name}`}
                  aria-label="Portfolio Name"
                  placement="top">
                  <Tag
                    className="ch-project-landing-portfolio-tag"
                    zIndex={2}
                    variant="subtle"
                    borderWidth="1px"
                    borderColor={tagBorderColor}
                    bgColor={tagBgColor}
                    color={tagColor}
                    fontSize="0.65rem"
                    cursor={"pointer"}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      navigate(`/portfolios/${portfolio.id}`);
                    }}>
                    <Icon as={shareDetails?.accessMode === "read" ? FiShare2 : GrLineChart} boxSize=".8rem" color={tagColor} mr="5px" />
                    {portfolio.name}
                  </Tag>
                </Tooltip>
              );
            })}
          {shareDetails && (
            <Tooltip label={`Shared by ${shareDetails.ownerName}: ${shareDetails.accessMode}`} aria-label="Shared" placement="top">
              <Tag
                className="ch-project-landing-read-tag"
                zIndex={2}
                variant="subtle"
                borderWidth="1px"
                borderColor={tagBorderColor}
                bgColor={tagBgColor}
                color={tagColor}
                fontSize="0.65rem">
                <Icon as={BiShare} boxSize=".8rem" color={tagColor} mr="5px" />
                {shareDetails.ownerName}
              </Tag>
            </Tooltip>
          )}
        </Stack>
      </Box>
      <MoveProjectModal
        isOpen={isOpenMoveProject}
        onClose={onCloseMoveProject}
        collectionId={collectionId}
        conversationId={collectionConversationId || uuid()}
      />
    </>
  );
};
