import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Select as FilterSelect } from "chakra-react-select";
import { Button, Input, Link, Stack, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useButtonProps } from "hooks";
import { QueryParamFilters, useTickersFiltersContext } from "../contexts/TickersFiltersContext";
import { TickerStatus } from "api/tickers/models/TickerStatus";
import capitalize from "lodash/capitalize";
import { useExchangeOptions } from "../utils/exchangeOptions";
import { useTickerTypeOptions } from "../utils/tickerTypesOptions";
import { TickerUpsertCopyModal } from "./TickerUpsertCopyModal";

interface IProps {
  isLoading: boolean;
  hideClearButton?: boolean;
  totalCount?: number;
  filterCount?: number;
  refresh?: () => void;
}

interface FilterOption {
  value: string;
  label: string;
}

export const TickersFilters = (props: IProps) => {
  const StatusOptions = useMemo(
    () => [{ label: "All", value: "all" }, ...TickerStatus.alternatives.map(({ value }) => ({ value, label: capitalize(value) }))],
    []
  );

  const tickerTypeOptions = useTickerTypeOptions();
  const TypesOptionsWithDefault = useMemo(() => [{ label: "All", value: "all" }, ...tickerTypeOptions], [tickerTypeOptions]);
  const exchangeOptions = useExchangeOptions();
  const ExchangeOptionsWithDefault = useMemo(() => [{ label: "All", value: "all" }, ...exchangeOptions], [exchangeOptions]);
  const { isLoading, hideClearButton, filterCount, totalCount, refresh } = props;
  const { isOpen: isOpenUpsertExchange, onClose: onCloseUpsertExchange, onOpen: onOpenUpsertExchange } = useDisclosure();
  const { isOpen: isOpenUpsertType, onClose: onCloseUpsertType } = useDisclosure();
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const navigate = useNavigate();
  const headingTextColor = useColorModeValue("gray.600", "gray.100");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const [queryInput, setQueryInput] = useState<string>("");
  const [symbolInput, setSymbolInput] = useState<string>("");

  const { status, exchange, type } = useTickersFiltersContext();

  const selectedStatus = useMemo(() => {
    const label =
      StatusOptions.find((taskStatus) => (!status && taskStatus.value === "all") || taskStatus.value === status)?.label ?? status;
    return [{ label, value: status }];
  }, [StatusOptions, status]);

  const selectedExchange = useMemo(() => {
    const label =
      ExchangeOptionsWithDefault.find(
        (exchangeOption) => (!exchange && exchangeOption.value === "all") || exchangeOption.value === exchange
      )?.label ?? exchange;
    return [{ label, value: exchange }];
  }, [ExchangeOptionsWithDefault, exchange]);

  const selectedType = useMemo(() => {
    const label =
      TypesOptionsWithDefault.find((typeOption) => (!type && typeOption.value === "all") || typeOption.value === type)?.label ?? type;
    return [{ label, value: type }];
  }, [TypesOptionsWithDefault, type]);

  const handleFilter = (option: FilterOption | null, type: QueryParamFilters) => {
    if (option === null || option.value === "all") {
      searchParams.delete(type);
    } else {
      searchParams.set(type, option.value);
    }

    navigate({ pathname: location.pathname, search: searchParams.toString() });
  };

  const clearFilters = useCallback(() => {
    navigate({ pathname: location.pathname, search: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const debounced = setTimeout(() => {
      if (symbolInput) {
        searchParams.set(QueryParamFilters.symbol, symbolInput);
      } else {
        searchParams.delete(QueryParamFilters.symbol);
      }

      navigate({ pathname: location.pathname, search: searchParams.toString() });
    }, 1500);

    return () => {
      clearTimeout(debounced);
    };
  }, [symbolInput, navigate, searchParams, location.pathname]);

  useEffect(() => {
    const debounced = setTimeout(() => {
      if (queryInput) {
        searchParams.set(QueryParamFilters.query, queryInput);
      } else {
        searchParams.delete(QueryParamFilters.query);
      }

      navigate({ pathname: location.pathname, search: searchParams.toString() });
    }, 1500);

    return () => {
      clearTimeout(debounced);
    };
  }, [queryInput, navigate, searchParams, location.pathname]);

  return (
    <>
      <Stack width="100%" justifyContent="space-between" pb=".5rem" spacing="1rem">
        <Stack pt="1rem" direction="row" width="100%" spacing="2rem" justifyContent={"space-between"} alignContent="flex-start">
          <Stack width="100%">
            <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
              Filter by Status
            </Text>
            <FilterSelect
              className="ch-multi-select"
              useBasicStyles
              size="sm"
              selectedOptionStyle="check"
              options={StatusOptions}
              onChange={(newStatus) => {
                handleFilter(newStatus as FilterOption, QueryParamFilters.status);
              }}
              value={selectedStatus}
              isDisabled={isLoading}
              isClearable
            />
          </Stack>
          <Stack width="100%">
            <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
              Filter by Name or Symbol
            </Text>
            <Input
              isDisabled={isLoading}
              value={queryInput}
              onChange={(evt) => {
                setQueryInput(evt.target.value);
              }}
              size={"sm"}
              placeholder="Search by name or symbol"
            />
          </Stack>
          <Stack width="100%">
            <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
              Filter by Symbol
            </Text>
            <Input
              isDisabled={isLoading}
              value={symbolInput}
              onChange={(evt) => {
                setSymbolInput(evt.target.value.toUpperCase().replaceAll(" ", ""));
              }}
              size={"sm"}
              placeholder="Search by symbol"
            />
          </Stack>
          <Stack width="100%">
            <Stack direction="row" spacing="1rem" justifyContent={"space-between"}>
              <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
                Filter by Exchange
              </Text>
              <Link fontSize={"xs"} textAlign={"end"} onClick={onOpenUpsertExchange}>
                Edit
              </Link>
            </Stack>
            <FilterSelect
              className="ch-multi-select"
              useBasicStyles
              size="sm"
              selectedOptionStyle="check"
              options={ExchangeOptionsWithDefault}
              onChange={(newExchange) => {
                handleFilter(newExchange as FilterOption, QueryParamFilters.exchange);
              }}
              value={selectedExchange}
              isDisabled={isLoading}
              isClearable
            />
          </Stack>
          <Stack width="100%">
            <Stack direction="row" spacing="1rem" justifyContent={"space-between"}>
              <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
                Filter by Type
              </Text>
              {/* <Link fontSize={"xs"} textAlign={"end"} onClick={onOpenUpsertType}>
                Edit
              </Link> */}
            </Stack>
            <FilterSelect
              className="ch-multi-select"
              useBasicStyles
              size="sm"
              selectedOptionStyle="check"
              options={TypesOptionsWithDefault}
              onChange={(newType) => {
                handleFilter(newType as FilterOption, QueryParamFilters.type);
              }}
              value={selectedType}
              isDisabled={isLoading}
              isClearable
            />
          </Stack>
        </Stack>

        <Stack direction="row" width="100%" spacing="1rem" justifyContent={refresh ? "space-between" : "flex-end"}>
          {refresh && (
            <Stack direction="row">
              <Button width="100%" {...secondaryButtonProps} isDisabled={isLoading} onClick={() => refresh()}>
                Refresh Tasks
              </Button>
            </Stack>
          )}
          {filterCount && totalCount && (
            <Stack direction="row" spacing="1rem">
              <Text fontSize="xs" color={headingTextColor} lineHeight="2rem">
                {`Filtering ${filterCount} of ${totalCount} items`}
              </Text>
              {!hideClearButton && (
                <Button {...secondaryButtonProps} isDisabled={isLoading} onClick={() => clearFilters()}>
                  Clear Filters
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>

      <TickerUpsertCopyModal
        isOpen={isOpenUpsertExchange}
        onClose={onCloseUpsertExchange}
        isLoading={isLoading}
        copyKey="copy_ticker_exchange_options"
        options={exchangeOptions}
        entityName="exchange"
      />

      <TickerUpsertCopyModal
        isOpen={isOpenUpsertType}
        onClose={onCloseUpsertType}
        isLoading={isLoading}
        copyKey="copy_ticker_type_options"
        options={tickerTypeOptions}
        entityName="type"
      />
    </>
  );
};
