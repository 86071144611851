import { startTransition, useCallback, useContext, useDeferredValue, useEffect, useMemo, useReducer, useState } from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useCollectionKey, useEntitlementKey, useLatestCollectionWorkflowId, useProjectParams, useUserPreference } from "hooks";
import type { SuggestedQuestions } from "api/suggestions";
import { useAvailableCommands, useCurrentCollectionQuestions } from "screens/collection/views/AutoCompleteInputUtils";
import { InputBarDropzoneContext } from "screens/conversation/components/InputBar/InputBarDropzoneContext";
import { useDebouncedCallback } from "use-debounce";
import { useQASourceListFunction } from "hooks/useQASourceList";
import { useAnswerFocusOptions } from "hooks/useAnswerFocusOptions";
import { useDispatch } from "react-redux";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useWorkflowKey } from "hooks/useWorkflows";

interface Props {
  questionText?: string;
  setQuestionText?: (questionText: string) => void;
  maxSuggestions?: number;
}

const initialState = {
  currentSuggestionListType: undefined as "commands" | "entities" | "questions" | "tickers" | undefined,
  suggestedEntities: [] as SuggestedQuestions,
  tickersResultsMap: {} as { [key: string]: SuggestedQuestions },
  isLoading: false,
};

type Action =
  | { type: "SET_SUGGESTION_LIST_TYPE"; payload: typeof initialState.currentSuggestionListType }
  | { type: "SET_SUGGESTED_ENTITIES"; payload: SuggestedQuestions }
  | { type: "SET_TICKERS_RESULTS"; payload: { query: string; results: SuggestedQuestions } }
  | { type: "SET_LOADING"; payload: boolean };

const reducer = (state: typeof initialState, action: Action): typeof initialState => {
  switch (action.type) {
    case "SET_SUGGESTION_LIST_TYPE":
      return { ...state, currentSuggestionListType: action.payload };
    case "SET_SUGGESTED_ENTITIES":
      return { ...state, suggestedEntities: action.payload };
    case "SET_TICKERS_RESULTS":
      return { ...state, tickersResultsMap: { ...state.tickersResultsMap, [action.payload.query]: action.payload.results } };
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

export const useConversationDialogSuggestionLists = ({ questionText, setQuestionText, maxSuggestions = 30 }: Props) => {
  const { projectId } = useProjectParams();
  const { isConversationOpen, setInitialQuestionText, setInitialQuestionFocus } = useConversationContext();
  const { files } = useContext(InputBarDropzoneContext);
  const dispatch = useDispatch();
  const [state, dispatchState] = useReducer(reducer, initialState);
  const currentWorkflowId = useLatestCollectionWorkflowId(projectId);
  const currentWorkflowStatus = useWorkflowKey(currentWorkflowId, "status");
  const collectionQuestions = useCollectionKey(projectId, "questions");

  // COMMANDLINE SUGGESTIONS
  const hasCommandLineEntitlement = useEntitlementKey("ui_enable_command_suggestions");
  const hideCommandLineButtonUserPref = (useUserPreference("ui_hide_command_line_button") as boolean) || false;
  const { availableCommandsMemo: suggestedCommands, filterCommandEntitiesMemo: originalSuggestedEntities } = useAvailableCommands(
    questionText || "",
    hideCommandLineButtonUserPref || !hasCommandLineEntitlement
  );
  const [currentSuggestionList, setCurrentSuggestionList] = useState<SuggestedQuestions>([]);

  useEffect(() => {
    if (!questionText) {
      dispatchState({ type: "SET_SUGGESTED_ENTITIES", payload: originalSuggestedEntities });
      return;
    }

    startTransition(() => {
      const segments = questionText.split(" ");
      const entitySegments = segments.filter((segment) => segment.startsWith(">")).map((segment) => segment.trim().toLowerCase());
      const lastSegment = entitySegments[entitySegments.length - 1] || "";
      const removeEndSegment = segments.slice(0, -1).join(" ");

      const updatedSuggestions = originalSuggestedEntities.reduce<SuggestedQuestions>((acc, entity) => {
        const entityLabel = (entity.label || entity.question).toLowerCase();

        if (!entitySegments.includes(entityLabel) && entityLabel.includes(lastSegment)) {
          acc.push({
            ...entity,
            question: `${removeEndSegment} ${entity.question}`.trim(),
            label: (entity.label || entity.question).trim(),
          });
        }

        return acc;
      }, []);
      dispatchState({ type: "SET_SUGGESTED_ENTITIES", payload: updatedSuggestions });
    });
  }, [questionText, originalSuggestedEntities]);

  // QUESTION SUGGESTIONS
  const getSourceQuestions = useQASourceListFunction("questions");
  const [sourcesList, setSourcesList] = useState<SuggestedQuestions>([]);
  useEffect(() => {
    dispatchState({ type: "SET_LOADING", payload: true });
    getSourceQuestions({ limit: maxSuggestions, ...(questionText && { questionText }) })
      .then((response) => setSourcesList(response))
      .catch((error) => {
        dispatchState({ type: "SET_LOADING", payload: false });
        console.error("Error fetching question suggestions", error);
      })
      .finally(() => dispatchState({ type: "SET_LOADING", payload: false }));
  }, [getSourceQuestions, maxSuggestions, questionText]);
  const { filterQuestionsByFocusMemo: suggestedQuestions } = useCurrentCollectionQuestions(sourcesList, projectId);

  // QUESTION FOCUS
  const { answerFocusOptions, selectedSourceWeightsIndex, setSelectedSourceWeightsIndex } = useAnswerFocusOptions("due_diligence");
  const selectedFocus = useMemo(() => {
    return answerFocusOptions ? answerFocusOptions[selectedSourceWeightsIndex]?.key : undefined;
  }, [answerFocusOptions, selectedSourceWeightsIndex]);

  const handleUpdateSourceWeightPreference = useCallback(
    (value: 0 | 1) => {
      dispatch(
        updateTypedUserPreference({
          preferenceKey: "source_weights_index",
          value: value,
        })
      );

      setSelectedSourceWeightsIndex(value);
    },
    [dispatch, setSelectedSourceWeightsIndex]
  );

  // TICKER SUGGESTIONS
  const getSourceTickers = useQASourceListFunction("tickers");
  const debouncedTickerFilter = useDebouncedCallback((query: string) => {
    if (state.tickersResultsMap[query]) {
      return;
    }
    dispatchState({ type: "SET_LOADING", payload: true });

    getSourceTickers({ limit: maxSuggestions, ...(query && { query }) })
      .then((response) => {
        dispatchState({ type: "SET_LOADING", payload: false });
        dispatchState({ type: "SET_TICKERS_RESULTS", payload: { query, results: response } });
      })
      .catch((error) => {
        dispatchState({ type: "SET_LOADING", payload: false });
        console.error("Error fetching ticker suggestions", error);
      });
  }, 150);
  const deferredQuestionText = useDeferredValue(questionText);

  useEffect(() => {
    if (state.currentSuggestionListType !== "tickers") return;

    if (deferredQuestionText && !deferredQuestionText.startsWith("/")) debouncedTickerFilter(deferredQuestionText);
  }, [debouncedTickerFilter, deferredQuestionText, state.currentSuggestionListType]);

  const suggestedTickers = useMemo(() => {
    return state.tickersResultsMap[questionText || ""] || [];
  }, [state.tickersResultsMap, questionText]);

  const isValidTicker = useCallback(
    (query: string) => {
      if (!state.tickersResultsMap[query]) {
        return true;
      } else {
        return state.tickersResultsMap[query].some((suggestedTicker) => suggestedTicker.matchFilter?.includes(query.trim().toLowerCase()));
      }
    },
    [state.tickersResultsMap]
  );

  useEffect(() => {
    startTransition(() => {
      const determineListType = () => {
        if (currentWorkflowStatus === "in_progress" && !hasCommandLineEntitlement) {
          return undefined;
        }

        if (((questionText && questionText.startsWith("/")) || files.length > 0) && isConversationOpen) {
          return questionText?.includes(" ") ? "entities" : "commands";
        } else {
          return projectId ? "questions" : "tickers";
        }
      };

      const listType = determineListType();
      dispatchState({ type: "SET_SUGGESTION_LIST_TYPE", payload: listType });

      const listTypeMap = {
        disabled: [],
        entities: state.suggestedEntities,
        commands: suggestedCommands,
        questions: suggestedQuestions,
        tickers: suggestedTickers,
      };

      setCurrentSuggestionList(listType ? listTypeMap[listType] : []);
    });
  }, [
    currentWorkflowStatus,
    deferredQuestionText,
    files.length,
    hasCommandLineEntitlement,
    isConversationOpen,
    projectId,
    questionText,
    state.suggestedEntities,
    suggestedCommands,
    suggestedQuestions,
    suggestedTickers,
  ]);

  const isInputDisabled = useMemo(() => {
    if (hasCommandLineEntitlement) return false;
    // if (canBypassDisabled) return false;
    if (collectionQuestions?.length === 0) return true;

    return currentWorkflowStatus === "in_progress";
  }, [hasCommandLineEntitlement, collectionQuestions?.length, currentWorkflowStatus]);

  const onResetQuestion = useCallback(() => {
    setQuestionText && setQuestionText("");
    setInitialQuestionText("");
    setSelectedSourceWeightsIndex(0);
    setInitialQuestionFocus(undefined);
  }, [setInitialQuestionFocus, setInitialQuestionText, setQuestionText, setSelectedSourceWeightsIndex]);

  return useMemo(
    () => ({
      debouncedTickerFilter,
      isValidTicker,
      isLoading: state.isLoading,
      currentSuggestionList,
      currentSuggestionListType: state.currentSuggestionListType,
      isInputDisabled,
      selectedFocus,
      handleUpdateSourceWeightPreference,
      answerFocusOptions,
      selectedSourceWeightsIndex,
      onResetQuestion,
      suggestedEntities: state.suggestedEntities,
      suggestedCommands,
      suggestedQuestions,
      suggestedTickers,
    }),
    [
      debouncedTickerFilter,
      isValidTicker,
      state.isLoading,
      currentSuggestionList,
      state.currentSuggestionListType,
      isInputDisabled,
      selectedFocus,
      handleUpdateSourceWeightPreference,
      answerFocusOptions,
      selectedSourceWeightsIndex,
      onResetQuestion,
      state.suggestedEntities,
      suggestedCommands,
      suggestedQuestions,
      suggestedTickers,
    ]
  );
};
