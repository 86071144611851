import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import type { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Stack,
  AccordionIcon,
  useBreakpointValue,
  Flex,
  Tooltip,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import type { DropResult } from "@hello-pangea/dnd";
import type { IconType } from "react-icons";
import { SidebarButton } from "./SidebarButton";
import { useConversationContext } from "screens/thread/ConversationContext";
import {
  useCollections,
  filterCollectionsByType,
  useUserPreference,
  useProjectParams,
  useEntitlements,
  useAllUserPreference,
  useConfigMap,
  useMenuConfig,
} from "hooks";
import type { Collection } from "types/collection";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { AddIcon } from "@chakra-ui/icons";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";
import { getTypeFromRoute } from "configs/configMap";
import { FiShare2 } from "react-icons/fi";

interface Props {
  text?: string;
  selectedProjectCategory?: string;
  selectedProjectId?: string;
  icon?: IconType;
  className?: string;
}

export const SidebarCategory: FunctionComponent<React.PropsWithChildren<Props>> = React.memo(
  ({ text, selectedProjectCategory, selectedProjectId, icon, className }) => {
    const { projectId } = useProjectParams();
    const { onPortfolioModalOpen, setSelectedProjectCategory } = useConversationContext();
    const collections = useCollections();
    const navigate = useNavigate();
    const portfolioMenuOrder = useUserPreference("ui_sidebar_portfolio_order") as string[];
    const dispatch = useDispatch();
    const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
    const addButtonFontColor = useColorModeValue("gray.50", "gray.900");
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const { scrollbarStyle } = useCustomScrollbar(scrollContainerRef, { width: "2px" });
    const entitlements = useEntitlements();
    const hasAdmin = entitlements["manage_configured_workflows_read"] && entitlements["manage_entitlements"];

    const filteredCollections = useMemo(
      () => filterCollectionsByType(collections, selectedProjectCategory || ""),
      [collections, selectedProjectCategory]
    );

    const [orderedCollections, setOrderedCollections] = useState<Collection[]>([]);
    const [sharedCollections, setSharedCollections] = useState<Collection[]>([]);

    useEffect(() => {
      const storedOrder = portfolioMenuOrder || [];
      const nonSharedCollections = filteredCollections.filter((collection) => !collection.shareDetails);
      const sharedItems = filteredCollections.filter((collection) => collection.shareDetails);

      const newItems = nonSharedCollections.filter((collection) => !storedOrder.includes(collection.id));
      const orderedItems = [...storedOrder, ...newItems.map((item) => item.id)];

      const reorderedCollections = orderedItems
        .map((id) => nonSharedCollections.find((collection) => collection.id === id))
        .filter((collection): collection is Collection => collection !== undefined);

      setOrderedCollections(reorderedCollections);
      setSharedCollections(sharedItems);
    }, [filteredCollections, portfolioMenuOrder]);

    const onClickHandler = useCallback(() => {
      setSelectedProjectCategory(selectedProjectCategory);
      onPortfolioModalOpen();
    }, [setSelectedProjectCategory, selectedProjectCategory, onPortfolioModalOpen]);

    const onDragEnd = useCallback(
      (result: DropResult) => {
        if (!result.destination) return;

        setOrderedCollections((prevOrderedCollections) => {
          const newOrder = Array.from(prevOrderedCollections);
          const [reorderedItem] = newOrder.splice(result.source.index, 1);
          newOrder.splice(result.destination!.index, 0, reorderedItem);

          const newOrderIds = newOrder.map((collection) => collection.id);
          dispatch(
            updateTypedUserPreference({
              preferenceKey: "ui_sidebar_portfolio_order",
              value: newOrderIds,
            })
          );

          return newOrder;
        });
      },
      [dispatch]
    );

    const renderDraggableItem = useCallback(
      (collection: Collection, index: number) => (
        <Draggable key={collection.id} draggableId={collection.id} index={index}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <SidebarButton
                onClick={() => navigate(`/portfolios/${collection.id}`)}
                text={collection.name}
                screen={`/portfolios/${collection.id}`}
                cssClasses={[
                  `ch-menu-category${
                    collection.shareDetails ? (collection.shareDetails.accessMode === "read" ? "-shared-read" : "-shared-write") : ""
                  }`,
                ]}
                isChildProject
                menuProjectId={collection.id}
              />
            </div>
          )}
        </Draggable>
      ),
      [navigate]
    );

    const userPreferences = useAllUserPreference();
    const configMap = useConfigMap();
    const menuConfig = useMenuConfig(configMap, "entitlements", false);
    const hasHiddenOnboardingVideos = useUserPreference("ui_hide_onboarding_videos") as boolean;

    const shownProjectsHeight = useMemo(() => {
      const shownItems = menuConfig.filter((menuItem) => {
        const type = getTypeFromRoute(menuItem.config.route);
        return userPreferences[`ui_show_${type}_menu`] !== false;
      });
      const sharedPortfolios = sharedCollections.length * 2.4 + 3;
      return shownItems.length * 2.4 + sharedPortfolios + (hasHiddenOnboardingVideos && !isMobile ? 9 : 16);
    }, [menuConfig, sharedCollections.length, hasHiddenOnboardingVideos, isMobile, userPreferences]);

    return (
      <>
        <Accordion allowToggle defaultIndex={0} reduceMotion>
          <AccordionItem border="none">
            <Flex alignItems="center" width="100%">
              <SidebarButton text={text} icon={icon} cssClasses={[className || "ch-menu-category-parent"]}>
                <Stack direction={"row"} spacing="5px">
                  <AccordionButton _expanded={{}} _hover={{}} p="0" width="auto" minWidth="unset" paddingInline={0}>
                    <AccordionIcon color="gray.500" />
                  </AccordionButton>
                  <Tooltip
                    maxWidth={"10rem"}
                    label={projectId && projectId.length > 0 ? "" : "Add a new portfolio to manage your equities"}
                    aria-label="Add Portfolio">
                    <IconButton
                      as="div"
                      className="ch-sidebar-portfolio-button"
                      minWidth={"unset"}
                      boxSize="1.2rem"
                      icon={<AddIcon boxSize={".7rem"} />}
                      onClick={(evt) => {
                        evt.preventDefault();
                        onClickHandler();
                      }}
                      _hover={{}}
                      _active={{}}
                      bgColor="primary.default"
                      color={addButtonFontColor}
                      aria-label={""}
                    />
                  </Tooltip>
                </Stack>
              </SidebarButton>
            </Flex>
            {(orderedCollections.length > 0 || sharedCollections.length > 0) && (
              <AccordionPanel pl={"0"} pb="0" pt="1rem" paddingInline={0}>
                <Stack
                  maxHeight={`calc(100vh - ${hasAdmin ? `${shownProjectsHeight}rem` : `${shownProjectsHeight - 2.5}rem`})`}
                  spacing="1rem"
                  pl={isMobile ? "2rem" : "unset"}
                  css={scrollbarStyle}
                  overflowY={"auto"}
                  overflowX={"hidden"}>
                  {orderedCollections.length > 0 && (
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="sidebar-buttons">
                        {(provided) => (
                          <Stack spacing="1rem" {...provided.droppableProps} ref={provided.innerRef}>
                            {orderedCollections.map(renderDraggableItem)}
                            {provided.placeholder}
                          </Stack>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}
                </Stack>
              </AccordionPanel>
            )}
          </AccordionItem>
        </Accordion>
        {sharedCollections.length > 0 && (
          <Stack spacing="1rem" pt="2rem">
            <SidebarButton text="Shared Portfolios" icon={FiShare2} cssClasses={["ch-menu-category-shared-parent"]} isStatic />
            {sharedCollections.map((collection, index) => (
              <SidebarButton
                key={collection.id}
                onClick={() => navigate(`/portfolios/${collection.id}`)}
                text={collection.name}
                screen={`/portfolios/${collection.id}`}
                cssClasses={[`ch-menu-category${collection.shareDetails?.accessMode === "read" ? "-shared-read" : "-shared-write"}`]}
                isChildProject
                isStatic
                menuProjectId={collection.id}
              />
            ))}
          </Stack>
        )}
      </>
    );
  }
);
