import { useProjectConfigEntitlements } from "./useEntitlements";
import { useAllUserPreference, useUserPreferences } from "./useUserPreferences";
import { useCallback, useContext, useEffect, useMemo } from "react";
import type { IDynamicFormProps, IField, IFieldFormInput } from "screens/common/components";
import type { FieldValues } from "react-hook-form";
import { iso31661 } from "iso-3166";
import { ConversationContext } from "screens/thread/ConversationContext";
import { extractSplitEntities, getRequestValue } from "screens/conversation/components/RequestEntitiesUtils";
import type { EntitySeparator } from "screens/conversation/components/RequestEntitiesUtils";
import { updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { shallowEqual, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import type { ProjectConfigState } from "state/config/reducer";
import type { UserConfigProperty } from "types/config";
import { getTypeFromRoute, getViewConfig } from "configs/configMap";
import { useConfigMapBase } from "./useConfigMapBase";

export const defaultSourceWeights = {
  factset: 0.7,
  sec: 0.7,
  google_search: 0.7,
  bing: 0.7,
  duckduckgo: 0.7,
  user_uploaded: 0.7,
};

const InternalOptionFunctions = ["regionOptions", "countryOptions"] as const;
export type OptionToFill = typeof InternalOptionFunctions[number];

function isOptionToFill(internalOptionFunction: string): internalOptionFunction is OptionToFill {
  return (
    typeof internalOptionFunction === "string" &&
    InternalOptionFunctions.some((optionFunction) => optionFunction === internalOptionFunction)
  );
}

export const useConfigMap = useConfigMapBase;

export const useAllProjectConfigsIntents = (): string[] => {
  return useSelector(
    (state: RootState) => state.configMap.order.map((projectId) => state.configMap.defaultConfigById[projectId].config.intent),
    shallowEqual
  );
};

export const useIsLoadingProjectConfig = () => {
  return useSelector((state: RootState) => state.configMap.loading, shallowEqual);
};

export const useProjectConfigOrder = (): string[] => {
  return useSelector((state: RootState) => state.configMap.order ?? [], shallowEqual);
};

export const useMenuConfig = (
  configMap: Record<string, ProjectConfigState>,
  filtered?: "all" | "entitlements" | "user-preferences" | undefined,
  projectsOnly?: boolean
): ProjectConfigState[] => {
  const userProjectEntitlements = useProjectConfigEntitlements();
  const userPreferences = useAllUserPreference();
  const configOrder = useProjectConfigOrder();

  const getFilteredProjectConfigs = useCallback(
    () =>
      configOrder.flatMap((projectId) => {
        const projectConfig = configMap[projectId];
        const route = projectConfig.config.route;

        if (projectsOnly) {
          return route && route.includes("projects") ? [projectConfig] : [];
        } else {
          return route ? [projectConfig] : [];
        }
      }),
    [configMap, configOrder, projectsOnly]
  );

  const getViewsByEntitlements = useCallback(
    (filterViewsWithProject: () => ProjectConfigState[]): ProjectConfigState[] => {
      return filterViewsWithProject().flatMap((projectConfig) => {
        const shouldShowViewItem = (() => {
          const maybeEntitlement = projectConfig.config.entitlement;
          const maybeReverseEntitlement = projectConfig.config.reverseEntitlement;

          if (maybeReverseEntitlement && (userProjectEntitlements[maybeReverseEntitlement] ?? false)) {
            return false;
          } else if (maybeEntitlement) {
            return userProjectEntitlements[maybeEntitlement] ?? false;
          } else {
            return true; // default to showing the item if no entitlements are configured
          }
        })();

        const hasConfigShowMenu = projectConfig.config.showMenu ?? false;
        if (shouldShowViewItem && hasConfigShowMenu) {
          return [projectConfig];
        } else {
          return [];
        }
      });
    },
    [userProjectEntitlements]
  );

  const getViewsByUserPreference = useCallback(
    (filterViewsWithProject: () => ProjectConfigState[]): ProjectConfigState[] => {
      return filterViewsWithProject().flatMap((projectConfig) => {
        const hasUserPreference = userPreferences[`ui_show_${getTypeFromRoute(projectConfig.config.route)}_menu`] as boolean | undefined;
        if (hasUserPreference === true || hasUserPreference === undefined) {
          return [projectConfig];
        } else {
          return [];
        }
      });
    },
    [userPreferences]
  );

  return useMemo(() => {
    if (filtered === "all") {
      const filteredByEntitlements = getViewsByEntitlements(getFilteredProjectConfigs);
      return getViewsByUserPreference(() => filteredByEntitlements);
    } else if (filtered === "entitlements") {
      return getViewsByEntitlements(getFilteredProjectConfigs);
    } else if (filtered === "user-preferences") {
      return getViewsByUserPreference(getFilteredProjectConfigs);
    } else {
      return getFilteredProjectConfigs();
    }
  }, [filtered, getFilteredProjectConfigs, getViewsByEntitlements, getViewsByUserPreference]);
};

export const useInitialSyncCompleted = () => {
  return useSelector((state: RootState) => state.configMap.hasInitialSyncCompleted);
};

export const useConfigForm = (key: string, configMap: Record<string, ProjectConfigState>) => {
  const { requestEntities, setRequestEntities } = useContext(ConversationContext);
  const { userPreferences } = useUserPreferences();

  const updateRequestEntity = useCallback(
    (entityName: string, entityValue: string, source: string) => {
      updateBatchRequestEntities([{ entity: entityName, value: entityValue, source }], setRequestEntities);
    },
    [setRequestEntities]
  );

  const getOptions: (optionFunction: OptionToFill) => { label: string; value: string }[] = useCallback((optionFunction) => {
    switch (optionFunction) {
      // returns the regional code
      case "regionOptions": {
        return Object.entries(iso31661)
          .sort((a, b) => {
            return a[1].name.localeCompare(b[1].name);
          })
          .map(([code, country]) => {
            return {
              label: country.name,
              value: country.alpha2,
            };
          });
      }
      // returns the country name
      case "countryOptions": {
        return Object.entries(iso31661)
          .sort((a, b) => {
            return a[1].name.localeCompare(b[1].name);
          })
          .map(([code, country]) => {
            return {
              label: country.name,
              value: country.name,
            };
          });
      }

      default: {
        return [];
      }
    }
  }, []);

  const fillOptions = useCallback(
    (inputFields: IField<FieldValues>[]) => {
      inputFields = inputFields.map((field) => {
        if (field.type === "multi-select" || field.type === "select") {
          if (field.optionsFunction) {
            const options = isOptionToFill(field.optionsFunction) ? getOptions(field.optionsFunction) : [];
            return { ...field, options };
          }
        } else if (field.type === "stack" || field.type === "duplicate-stack" || field.type === "tabs" || field.type === "tab") {
          return { ...field, fields: fillOptions(field.fields) };
        }
        return field;
      });

      return inputFields;
    },
    [getOptions]
  );

  const getInputFields = useCallback((fields: IField<FieldValues>[], accumFields: IFieldFormInput<FieldValues>[] = []) => {
    fields.forEach((field) => {
      if (
        field.type === "text" ||
        field.type === "select" ||
        field.type === "multi-select" ||
        field.type === "collection-select" ||
        field.type === "integration-select" ||
        field.type === "report-select" ||
        field.type === "tags-input" ||
        field.type === "switch"
      ) {
        accumFields.push(field);
      } else if (field.type === "stack" || field.type === "duplicate-stack" || field.type === "tabs" || field.type === "tab") {
        getInputFields(field.fields, accumFields);
      } else {
        return;
      }
    });

    return accumFields;
  }, []);

  const getDefaultValues = useCallback(
    (innerFields: IField<FieldValues>[], defaultValues: FieldValues = {}) => {
      innerFields.forEach((field) => {
        if (field.type === "divider" || field.type === "space" || field.type === "description") return;
        if (
          field.type === "stack" ||
          field.type === "tabs" ||
          field.type === "tab" ||
          field.type === "duplicate-stack" ||
          field.type === "child-panel" ||
          field.type === "grid"
        ) {
          getDefaultValues(field.fields, defaultValues);
        } else {
          defaultValues[field.entity] = (() => {
            const entityValue = getRequestValue(field.entity, requestEntities);

            if (entityValue) {
              if (field.type === "multi-select") {
                const { optionsFunction: hasOptionsFunction, options, entitySeparator } = field;
                const entityArray = entityValue.split(entitySeparator || ",") || [];

                const multiOptions = hasOptionsFunction && isOptionToFill(hasOptionsFunction) ? getOptions(hasOptionsFunction) : options;

                return entityArray.flatMap((entityValue) => {
                  const option = multiOptions.find((option) => option.value === entityValue);

                  if (!option) {
                    return [];
                  } else {
                    return [{ label: option.label, value: option.value }];
                  }
                });
              } else {
                return entityValue;
              }
            }

            if (field.userPreferenceKey && userPreferences[field.userPreferenceKey]) {
              return userPreferences[field.userPreferenceKey];
            } else {
              return field.defaultValue;
            }
          })();
        }
      });

      return defaultValues;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getOptions, userPreferences]
  );

  const projectForm: IDynamicFormProps<FieldValues> | undefined = useMemo(() => {
    const configProjectForm = getViewConfig("newProjectForm", key, configMap);

    if (!configProjectForm) {
      return;
    }

    return {
      title: configProjectForm.title,
      projectFormStepOrder: configProjectForm.projectFormStepOrder,
      showUploadMaxFiles: configProjectForm.showUploadMaxFiles,
      uploadStepTags: configProjectForm.uploadStepTags,
      entitySource: configProjectForm.entitySource,
      fields: fillOptions(configProjectForm.fields),
      defaultValues: getDefaultValues(configProjectForm.fields),
      onSubmit(data) {
        // On Submit is never called in this case because button is outside the form logic
      },
      formId: configProjectForm.formId,
      onSetEntity(entity, value, source) {
        updateRequestEntity(entity, value, source);
      },
    };
  }, [key, configMap, fillOptions, getDefaultValues, updateRequestEntity]);

  useEffect(() => {
    const inputs = getInputFields(projectForm?.fields || []);

    inputs.forEach((input) => {
      const defaultValue = (() => {
        switch (input.type) {
          case "text": {
            return input.defaultValue || undefined;
          }

          case "select": {
            return input.defaultValue || undefined;
          }

          case "multi-select": {
            return input.defaultValue.map((value) => value.value).join(input.entitySeparator || ",") || undefined;
          }

          case "collection-select": {
            return input.defaultValue || undefined;
          }

          case "switch": {
            return input.trueValue;
          }

          case "integration-select": {
            return input.defaultValue || undefined;
          }

          default:
            return undefined;
        }
      })();

      const entitySource = (() => {
        switch (input.type) {
          case "collection-select": {
            return "collection-selector";
          }

          case "integration-select": {
            return "integration-selector";
          }

          case "report-select": {
            return "report-selector";
          }

          case "tags-input": {
            return "tag-selector";
          }

          default:
            return projectForm?.entitySource;
        }
      })();

      if (defaultValue && input.entity && entitySource) {
        updateRequestEntity(input.entity, defaultValue, entitySource);
      }
    });
  }, [getInputFields, projectForm?.entitySource, projectForm?.fields, updateRequestEntity]);

  return {
    projectForm,
  };
};

export const useCommaSeparatedEntitiesMap = (configMap: Record<string, ProjectConfigState>): Record<string, EntitySeparator> => {
  return useMemo(
    () => ({
      email_address: ",",
      topic: ",",
      tag: ",",
      expense_id: ",",
      file_id: ",",
      link_id: ",",
      collection_id: ",",
      content_id: ",",
      highlight_id: ",",
      company_equity: ",",
      selection: ",",
      ...extractSplitEntities(
        Object.values(configMap).flatMap((projectConfig) =>
          projectConfig.config.newProjectForm ? projectConfig.config.newProjectForm.fields : []
        )
      ),
    }),
    [configMap]
  );
};

export const useConfigProjectTypes = (configMap: Record<string, ProjectConfigState>): ProjectConfigState[] => {
  return useMemo(() => {
    return Object.entries(configMap).map(([key, config]) => ({ ...config }));
  }, [configMap]);
};

export const useGetViewConfig = <K extends UserConfigProperty>(
  type: K,
  configId: string | undefined,
  configMap: Record<string, ProjectConfigState>
) => {
  return useMemo(() => {
    if (!configId) {
      return;
    }

    return getViewConfig(type, configId, configMap);
  }, [configId, configMap, type]);
};
