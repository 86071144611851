import { useToast } from "@chakra-ui/react";
import { useAppDispatch } from "hooks";
import { upsertCopy } from "state/copies/operations";

interface UseUpsertCopyModalProps {
  onClose: () => void;
  entityName: string;
}

export const useUpsertCopyModal = ({ onClose, entityName }: UseUpsertCopyModalProps) => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const handleSubmit = async (values: {
    key: string;
    type?: "string" | "list";
    stringValue?: string;
    listValue?: Array<{ value: string }>;
  }) => {
    const response = await dispatch(
      upsertCopy({
        key: values.key,
        value: values.type === "string" ? values.stringValue ?? "" : (values.listValue || []).map((v) => v.value),
      })
    );

    if (response.type === upsertCopy.rejected.type) {
      toast({
        title: `Failed to save ${entityName} options`,
        status: "error",
      });
      return false;
    }

    toast({
      title: `${entityName} options saved successfully`,
      status: "success",
    });
    onClose();
    return true;
  };

  return { handleSubmit };
};
