import { Box, Button, Center, FormControl, FormErrorMessage, Image, Input, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useCallback } from "react";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import logo from "screens/common/static/logos/charli-logo-full.svg";
import { useForm } from "react-hook-form";
import type { RootState } from "state/rootReducer";
import { verifyEmail } from "state/session/operations";
import { buttonStyles, useAppDispatch } from "hooks";
import { generateVerificationKey } from "api/user";
import { TabTitle } from "screens/common/components/TabTitle";
import { CommonModal } from "screens/landing/components/popoverComponent/CommonModal";

type FormValues = {
  verify: string;
};

interface VerifyProps {
  viewType?: "full" | "modal" | "wizard" | undefined;
  onCloseModal?: () => void;
  onNextStep?: () => void;
  isModalOpen?: boolean;
}

export const Verify: FunctionComponent<VerifyProps> = ({ viewType, isModalOpen, onCloseModal, onNextStep }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<FormValues>();
  const { user, isAuthorized } = useSelector((state: RootState) => state.session, shallowEqual);
  const { pathname } = useLocation();
  const [lastError, setLastError] = useState<string | undefined>(undefined);
  const [resendConfirmationMessage, setResendConfirmationMessage] = useState<string | undefined>(undefined);
  const buttonColorRegister = useColorModeValue("primary.default", "gray.100");
  const trialBannerStyle = buttonStyles["subscribe"];
  const [searchParams] = useSearchParams();
  const maybeCode = searchParams.get("code");
  const verifyCodeBtn = React.useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (viewType === "full" && isAuthorized && user?.isEmailVerified === true && pathname === "/verify") {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, user, viewType]);

  const onSubmit = useCallback(
    (fields: FormValues) => {
      setResendConfirmationMessage(undefined);

      dispatch(verifyEmail(fields.verify)).then((result) => {
        if (result.payload === false) {
          setLastError("Provided code was not valid");
        } else {
          setLastError(undefined);
        }
      });

      if (viewType === "modal" && onCloseModal) {
        onCloseModal();
      }

      if (onNextStep && viewType === "wizard") {
        onNextStep();
      }
    },
    [dispatch, onCloseModal, onNextStep, viewType]
  );
  const onResendSubmit = async () => {
    await generateVerificationKey();
    setResendConfirmationMessage("Code was resent, check your email");
  };

  useEffect(() => {
    if (maybeCode) {
      setValue("verify", maybeCode);
      verifyCodeBtn.current?.click();
    }
  }, [maybeCode, setValue]);

  const content = (
    <Stack justifyContent={"space-between"} width={"100%"} height={viewType === "modal" ? "100%" : "100vh"} alignSelf="center">
      <TabTitle title="Charli > Verify" />
      <Box>
        {viewType !== "wizard" && (
          <Center py="4rem">
            <Image src={logo} alt="Charli logo" width="12rem" cursor="pointer" />
          </Center>
        )}
        <Center>
          <Stack justifyContent={"space-between"} spacing="2rem" px="2rem" maxWidth={["90%", "25rem", "22rem"]}>
            {viewType !== "wizard" && (
              <Text pb="2rem" fontSize="sm" textAlign="center">
                Enter the verification code that was emailed to you in the box below and click Verify Code.
              </Text>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack align="center" justify="center" spacing="2rem">
                <FormControl isInvalid={errors.verify !== undefined || lastError !== undefined}>
                  <Input
                    {...register("verify", { required: true })}
                    id="verify-code"
                    type="verify"
                    placeholder="Paste Verification Code here"
                    borderColor={errors.verify ? "red.500" : "gray.700"}
                    fontSize="md"
                    boxShadow="none"
                  />
                  {errors.verify && <FormErrorMessage>Code is required.</FormErrorMessage>}
                  {lastError && <FormErrorMessage>{lastError}</FormErrorMessage>}
                </FormControl>
                <Button
                  isLoading={isSubmitting}
                  ref={verifyCodeBtn}
                  {...trialBannerStyle}
                  size="lg"
                  color="white"
                  fontWeight={"normal"}
                  bgColor={"#81c34b"}
                  borderColor={"#81c34b"}
                  width="16rem"
                  _hover={{}}
                  borderRadius={viewType !== "wizard" ? "md" : "full"}
                  id="verify-submit"
                  type="submit">
                  Verify Code
                </Button>
              </Stack>
            </form>
            <Stack direction="row" justifyContent="space-between" width="100%" pt={viewType !== "wizard" ? "3rem" : "0"} pb="3rem">
              <Button
                disabled={isSubmitting}
                id="link-button"
                fontWeight="400"
                fontFamily="print"
                fontSize="sm"
                color={buttonColorRegister}
                variant="link"
                onClick={onResendSubmit}>
                Resend code
              </Button>
              <Button
                disabled={isSubmitting}
                id="link-button"
                fontWeight="400"
                fontFamily="print"
                fontSize="sm"
                color={buttonColorRegister}
                variant="link"
                onClick={() => {
                  navigate("/logout");
                }}>
                Logout
              </Button>
            </Stack>
            {resendConfirmationMessage && (
              <Text pt="1rem" pb="1.5rem" fontSize="sm" textAlign={"center"}>
                {resendConfirmationMessage}
              </Text>
            )}
          </Stack>
        </Center>
      </Box>
    </Stack>
  );

  if (viewType === "modal" && isModalOpen && onCloseModal) {
    return (
      <CommonModal showCloseButton isOpen={isModalOpen} onClose={onCloseModal} modalBody={content} minWidth={["90%", "40rem", "40rem"]} />
    );
  }

  if (viewType === "wizard") {
    return <Box height="12rem">{content}</Box>;
  }

  return content;
};
