import { Stack, useColorModeValue, Box, useBreakpointValue } from "@chakra-ui/react";
import { useConfigMap, useCustomTheme, useGetViewConfig, useUserPreference } from "hooks";
import React, { useEffect, useState, useRef } from "react";
import { SidebarNavigationMain } from "./SidebarNavigationMain";
import { HelpMenu } from "./HelpMenu";
import { SidebarAdminButton } from "./SidebarAdminButton";
import { useDebouncedCallback } from "use-debounce";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";
import { SidebarNavigationLogos } from "./SidebarNavigationLogo";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";
import OnboardingVideoPopover from "./popoverComponent/OnboardingVideoPopover";
import OnboardingVideo from "./OnboardingVideo";

export const SIDEBAR_PADDING = "1rem";

const MIN_SIDEBAR_WIDTH = 208;
const MAX_SIDEBAR_WIDTH = 500;
export const DEFAULT_SIDEBAR_WIDTH = 208;

export const SidebarNavigation = () => {
  const bgColor = useColorModeValue("charli.lightBlue", "gray.800");
  const { isEmbeddedApp } = useCustomTheme();
  const userPrefSidebarWidth = useUserPreference("ui_sidebar_width") as number;
  const hasHiddenOnboardingVideos = useUserPreference("ui_hide_onboarding_videos") as boolean;
  const [width, setWidth] = useState(userPrefSidebarWidth || DEFAULT_SIDEBAR_WIDTH);
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false }, { fallback: "md", ssr: false });
  const dispatch = useDispatch();
  const bgColorResizer = useColorModeValue("gray.300", "gray.900");
  const mainNavRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(scrollContainerRef, { width: "2px" });
  const configMap = useConfigMap();
  const homeOnboardingSteps = useGetViewConfig("onBoardingSteps", "home", configMap);
  const videoURL = homeOnboardingSteps && homeOnboardingSteps[0].url ? homeOnboardingSteps[0].url : "";
  const hasCompletedOnboarding = useUserPreference("ui_onboarding_completed") as boolean;

  // dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_completed", value: false }));
  // dispatch(updateTypedUserPreference({ preferenceKey: "ui_hide_onboarding_videos", value: false }));

  useEffect(() => {
    if (userPrefSidebarWidth) {
      setWidth(userPrefSidebarWidth);
    }
  }, [userPrefSidebarWidth]);

  const debouncedUpdateWidth = useDebouncedCallback((newWidth: number) => {
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_sidebar_width", value: newWidth }));
  }, 150);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

    const handleMouseMove = (e: MouseEvent) => {
      const newWidth = Math.min(Math.max(e.clientX, MIN_SIDEBAR_WIDTH), MAX_SIDEBAR_WIDTH);
      setWidth(newWidth);

      debouncedUpdateWidth(newWidth);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);

      debouncedUpdateWidth.flush();
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleResetWidth = () => {
    const newWidth = DEFAULT_SIDEBAR_WIDTH;
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_sidebar_width", value: newWidth }));
    setWidth(newWidth);
  };

  return (
    <Stack
      pointerEvents={!isMobile && !hasCompletedOnboarding ? "none" : "initial"}
      gap="0"
      mt="0!important"
      direction="row"
      backgroundColor={bgColor}
      width={["100vw", "100%", "100%", `${width}px`, `${width}px`]}
      justifyContent="space-between"
      height="100vh"
      ref={containerRef}
      zIndex={1}>
      <Stack justifyContent="space-between" height="100vh" width="100%">
        {!isEmbeddedApp && <SidebarNavigationLogos />}
        <Stack
          ref={scrollContainerRef}
          css={scrollbarStyle}
          overflow={"hidden"}
          gap="1rem"
          width="100%"
          direction="column"
          height="100%"
          align="flex-start"
          justifyContent="space-between">
          <Stack spacing={"1rem"} height="100%" width="100%" pl="1rem" pr=".5rem" pt="1rem">
            <Box ref={mainNavRef}>
              <SidebarNavigationMain />
            </Box>
          </Stack>
          <Stack width="100%" height="100%" spacing="1rem" px="1rem" justifyContent="flex-end" pb={isMobile ? "5rem" : "1rem"}>
            {!isMobile && !hasHiddenOnboardingVideos && (
              <Box className="ch-sidebar-onboarding-popover" position="relative">
                <OnboardingVideo videoURL={videoURL} />
              </Box>
            )}
            {!isMobile && !hasCompletedOnboarding && <OnboardingVideoPopover />}
            {!isEmbeddedApp && <SidebarAdminButton />}
            {!isEmbeddedApp && <HelpMenu />}
          </Stack>
        </Stack>
      </Stack>
      <Box
        height="100%"
        width="5px"
        cursor="ew-resize"
        backgroundColor="transparent"
        onMouseDown={handleMouseDown}
        onDoubleClick={handleResetWidth}
        _hover={{ backgroundColor: bgColorResizer }}
      />
    </Stack>
  );
};
