import type { ReactNode } from "react";
import { useCallback } from "react";
import React, { createContext, useState, useEffect, useContext } from "react";
import type { AnswerStatus, Question } from "types/question";
import type { Question as APIQuestion } from "api/collection/models/Question";
import { getCollectionQuestions } from "api/collection/collection";
import { captureException } from "@sentry/react";
import { useUserProfile } from "hooks";
import { getWebsocketClient } from "api/websocket/client";
import { useIsWebsocketConnected } from "hooks/useWebsocket";

const transformAPIQuestionsToQuestions = (questions: APIQuestion[]): Question[] => {
  return questions.map((question) => ({
    ...question,
    status: question.status as AnswerStatus,
    answers: question.answers.map((answer) => ({
      ...answer,
      answerStatus: answer.answerStatus as AnswerStatus,
      creationDate: new Date(answer.creationDate),
    })),
    creationDate: new Date(question.creationDate),
  }));
};

interface ProjectQuestionsContextProps {
  questions: Question[];
}

interface ProjectQuestionsProviderProps {
  collectionId?: string;
  children: ReactNode;
}

export const ProjectQuestionsContext = createContext<ProjectQuestionsContextProps | undefined>(undefined);

export const useProjectQuestionsContext = (): ProjectQuestionsContextProps => {
  const context = useContext(ProjectQuestionsContext);

  if (context === undefined) {
    throw new Error("useProjectQuestionsContext must be used within a ProjectQuestionsProvider");
  }

  return context;
};

export const ProjectQuestionsProvider: React.FC<ProjectQuestionsProviderProps> = ({ collectionId, children }) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const userId = useUserProfile()?.id;
  const isWebsocketConnected = useIsWebsocketConnected();

  const fetchQuestions = useCallback(
    (collectionId: string) => {
      getCollectionQuestions(collectionId)
        .then((questions) => setQuestions(transformAPIQuestionsToQuestions(questions)))
        .catch((error) => {
          captureException(error, {
            extra: {
              collectionId,
              userId,
            },
          });
        });
    },
    [userId]
  );

  useEffect(() => {
    if (!collectionId) {
      return;
    }

    fetchQuestions(collectionId);
  }, [collectionId, fetchQuestions]);

  useEffect(() => {
    if (!isWebsocketConnected || !collectionId) {
      return;
    }

    const subscription = getWebsocketClient().subscribeToEvent(
      "content/refresh_questions",
      (message) => {
        const { collectionId: messageCollectionId } = message as { collectionId: string };

        if (collectionId === messageCollectionId) {
          fetchQuestions(collectionId);
        }
      },
      true
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [isWebsocketConnected, collectionId, fetchQuestions]);

  return <ProjectQuestionsContext.Provider value={{ questions }}>{children}</ProjectQuestionsContext.Provider>;
};
