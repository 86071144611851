import React from "react";
import { Box, Stack, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { UpgradePlanButton } from "screens/panels/settings/tabs/subscription/SubscriptionUpgrade";
import { useFocusBadgeColors } from "./hooks";
import type { SuggestedQuestion } from "api/suggestions";

interface SuggestionItemProps {
  suggestion: SuggestedQuestion & { id: string };
  value?: string;
  hasPrivateTickers: boolean;
  inputSubmitColor: string;
  textColor: string;
  betaBgColor: string;
}

export const SuggestionItem: React.FC<SuggestionItemProps> = ({
  suggestion,
  value,
  hasPrivateTickers,
  inputSubmitColor,
  textColor,
  betaBgColor,
}) => {
  const { question, label, focus, type, status } = suggestion;
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const colors = useFocusBadgeColors();
  const typeBgColor = useColorModeValue("green.100", "green.800");
  const focusBgColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing="1rem"
      alignItems="center"
      width="100%"
      cursor={focus === "PRIVATE" && !hasPrivateTickers ? "not-allowed" : "pointer"}>
      <TextOverflowTooltip
        style={{
          opacity: focus === "PRIVATE" && !hasPrivateTickers ? 0.6 : 1,
          maxWidth: "calc(100% - 9rem)",
        }}
        className="ch-autocomplete-suggestion-text"
        noOfLines={isMobile ? 1 : 3}
        label={label || question.replace("|", "")}
        searchText={value}
        highlightBackground={false}
      />
      <Stack direction="row">
        {status === "beta" && (
          <Box
            opacity={focus === "PRIVATE" && !hasPrivateTickers ? 0.6 : 1}
            className="ch-autocomplete-suggestion-type"
            borderRadius="4px"
            py="2px"
            px="5px"
            fontSize="10px"
            color={textColor}
            whiteSpace="nowrap"
            background={betaBgColor}>
            BETA
          </Box>
        )}
        {type && (
          <Box
            opacity={focus === "PRIVATE" && !hasPrivateTickers ? 0.6 : 1}
            className="ch-autocomplete-suggestion-type"
            borderRadius="4px"
            py="2px"
            px="5px"
            fontSize="10px"
            color={textColor}
            whiteSpace="nowrap"
            background={colors[type.toUpperCase()] || typeBgColor}>
            {isMobile ? type.toUpperCase().slice(0, 1) : type.toUpperCase().replace(/_/g, " ")}
          </Box>
        )}
        {focus && (
          <Box
            opacity={focus === "PRIVATE" && !hasPrivateTickers ? 0.6 : 1}
            className="ch-autocomplete-suggestion-tag"
            borderRadius="4px"
            py="2px"
            px="5px"
            fontSize="10px"
            color={textColor}
            whiteSpace="nowrap"
            bgColor={focusBgColor}
            maxWidth={"7rem"}
            minWidth={"2rem"}>
            <TextOverflowTooltip fontSize="10px" color={textColor} label={focus.toUpperCase().replace(/_/g, " ")} />
          </Box>
        )}
        {focus === "PRIVATE" && hasPrivateTickers && (
          <UpgradePlanButton
            tooltip="Upgrade to Business to access private tickers."
            buttonSize="xs"
            style={{
              fontSize: "10px",
              textTransform: "uppercase",
              py: "2px",
              px: "5px",
              height: "unset",
              width: "4rem",
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};
