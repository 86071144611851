import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import localForage from "localforage";
import {
  astrellaTokenRefreshMiddleware,
  refreshUserDataMiddleware,
  sessionManagerMiddleware,
  unsentMessageMiddleware,
  websocketRecoveryMiddleware,
  workflowFetchingMiddleware,
  projectConfigMiddleware,
  loggerMiddleware,
} from "./middleware";
import rootReducer from "./rootReducer";
import type { RootState } from "./rootReducer";

const persistConfig = {
  key: "root",
  storage: localForage,
  blacklist: [
    "modal",
    "selection",
    "conversation",
    "content",
    "checkpoint",
    "collection",
    "workflow",
    "configuredWorkflow",
    "collectionMetadata",
    "milestones",
  ],
};

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);
const middleware = getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: false,
}).concat(
  loggerMiddleware,
  sessionManagerMiddleware,
  websocketRecoveryMiddleware,
  unsentMessageMiddleware,
  refreshUserDataMiddleware,
  astrellaTokenRefreshMiddleware,
  workflowFetchingMiddleware,
  projectConfigMiddleware
);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware,
});

/* eslint @typescript-eslint/no-var-requires: "off" */
if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export default store;
