import { Tab, TabList, TabPanel, TabPanels, Tabs, useColorModeValue } from "@chakra-ui/react";
import React, { useRef } from "react";
import { Profile } from "./tabs/profile";
import { About } from "./tabs/about";
import { Config } from "./tabs/config";
import { ConfigLayoutOptions } from "./tabs/config/ConfigLayoutOptions";
import { useTabProps } from "hooks";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";

export const Settings = () => {
  const tileRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(tileRef, { width: "3px" });
  const { commonTabProps } = useTabProps();
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  return (
    <Tabs overflow="hidden" bgColor={bgColor} color={textColor}>
      <TabList>
        <Tab {...commonTabProps} className="ch-settings-profile-button">
          Profile
        </Tab>
        <Tab {...commonTabProps} className="ch-settings-config-button">
          General
        </Tab>
        <Tab {...commonTabProps} className="ch-config-layout-options">
          Project
        </Tab>
        <Tab {...commonTabProps} className="ch-settings-about-button">
          About Charli
        </Tab>
      </TabList>
      <TabPanels overflow={"auto"} height={"100%"} maxHeight={`calc(100vh - 13rem)`} ref={tileRef} css={scrollbarStyle}>
        <TabPanel>
          <Profile />
        </TabPanel>
        <TabPanel>
          <Config />
        </TabPanel>
        <TabPanel>
          <ConfigLayoutOptions />
        </TabPanel>
        <TabPanel>
          <About />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
