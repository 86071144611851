import { Box, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, useColorModeValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useCallback } from "react";
import type { TableContent } from "types/content/TableContent";
import { ReactGridTable } from "../EditableTable/ReactGridTable";
import capitalize from "lodash/capitalize";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { BlockSectionHeader } from "screens/content/contentView/previewSection/BlockSectionHeader";

interface Props {
  tablesData: TableContent[] | undefined;
  isEditEnabled?: boolean;
  isModal?: boolean;
  onEditTableData?: (tablesData: TableContent[], editedTableContent?: TableContent) => void;
  isTabs?: boolean;
  isLoading?: boolean;
}

export const MultiColumnTableEditable: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  tablesData,
  isEditEnabled = false,
  isModal = false,
  onEditTableData,
  isTabs,
  isLoading = false,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.300");

  const onEditCell = useCallback(
    (table: TableContent) => {
      if (onEditTableData) {
        const newTablesData = tablesData?.map((t) => (t.title === table.title ? table : t));
        onEditTableData(newTablesData ?? [], table);
      }
    },
    [onEditTableData, tablesData]
  );

  return (
    <>
      {isTabs ? (
        <Tabs isLazy variant="line" colorScheme="gray">
          <TabList width="100%" overflowX={isModal ? "inherit" : "auto"} overflowY="hidden">
            {tablesData?.map((table, index) => {
              return (
                <Tab key={`tab-${index}`} _selected={{ fontWeight: "bold", color: "primary.hover" }} pl="0" fontSize="sm" color={textColor}>
                  {table.title}
                </Tab>
              );
            })}
          </TabList>
          <TabPanels>
            {tablesData?.map((table, tableIndex) => {
              return (
                <TabPanel key={`tabpanel-${tableIndex}`} p="0">
                  <Box
                    fontSize="sm"
                    overflow="auto"
                    width="100%"
                    height={isModal ? "calc(75vh - 3rem)" : "100%"}
                    maxH={isModal ? "100%" : "15rem"}>
                    <ReactGridTable tablesData={table} onEditCell={onEditCell} isEditEnabled={isEditEnabled} />
                  </Box>
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      ) : (
        <Stack spacing="1.5rem" justifyContent="space-between">
          {tablesData?.map((table, index) => {
            return (
              <Box className={`multi-table-${index}`} key={index} width="100%">
                <BlockSectionHeader flexDirection={"row"} title={capitalize(table.label ?? table.title)} />
                <Box position={"relative"}>
                  {isLoading ? (
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      position={"absolute"}
                      zIndex={100}
                      opacity={0.3}
                      backgroundColor={"gray.500"}
                      width={"100%"}
                      height={"100%"}>
                      <TypingIndicator />
                    </Box>
                  ) : null}

                  <Box
                    fontSize="sm"
                    overflow="auto"
                    width="100%"
                    height={isModal ? "calc(75vh - 3rem)" : "100%"}
                    maxH={isModal ? "100%" : "15rem"}>
                    <ReactGridTable tablesData={table} onEditCell={onEditCell} isEditEnabled={isEditEnabled} />
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Stack>
      )}
    </>
  );
};
