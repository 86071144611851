import type { PlanName } from "api/subscription/models/PlanName";
import type { ComponentType } from "react";
import { LoginPreview, RegistrationPreview } from "screens/landing/tabs/admin/copies/components/CopyPreviews";

export interface CopyPreviewProps {
  value: string | string[];
}

export type CopyPreviewComponent = ComponentType<CopyPreviewProps>;

export const copyPreviews: Partial<Record<CopyTypedKey, CopyPreviewComponent>> = {
  copy_login_heading: LoginPreview,
  copy_login_list_items: LoginPreview,
  copy_registration_heading: RegistrationPreview,
  copy_registration_subheading: RegistrationPreview,
  copy_registration_list_items: RegistrationPreview,
  copy_registration_footer_text: RegistrationPreview,
  copy_registration_cta: RegistrationPreview,
  copy_registration_submit_button: RegistrationPreview,
};

export const CopiesKeys = [
  "copy_login_list_items",
  "copy_login_heading",
  "copy_registration_heading",
  "copy_registration_subheading",
  "copy_registration_list_items",
  "copy_registration_footer_text",
  "copy_registration_cta",
  "copy_registration_submit_button",
  "copy_subscription_features",
  "copy_subscription_plan",
  "copy_subscription_plan_price",
  "copy_maintenance_banner",
  "copy_maintenance_banner_bg_color",
  "copy_maintenance_banner_text_color",
  "copy_ticker_placeholder_text",
  "copy_promotion_code",
  "copy_chart_quadrant_help",
  "copy_chart_buysellhold_help",
  "copy_chart_stockmarket_help",
  "copy_ticker_exchange_options",
  "copy_ticker_type_options",
  "copy_ticker_excluded_exchanges",
  "copy_ticker_excluded_types",
  "copy_ticker_type_order",
] as const;
export type CopyTypedKey = typeof CopiesKeys[number];

export interface CopiesTypeMapping {
  copy_login_list_items: string[];
  copy_login_heading: string;
  copy_registration_heading: string;
  copy_registration_subheading: string;
  copy_registration_list_items: string[];
  copy_registration_footer_text: string[];
  copy_registration_cta: string;
  copy_registration_submit_button: string;
  copy_subscription_features: string[];
  copy_subscription_plan: PlanName;
  copy_subscription_plan_price: string;
  copy_maintenance_banner: string;
  copy_maintenance_banner_bg_color: string;
  copy_maintenance_banner_text_color: string;
  copy_ticker_placeholder_text: string;
  copy_promotion_code: string;
  copy_chart_quadrant_help: [string, string];
  copy_chart_buysellhold_help: [string, string];
  copy_chart_stockmarket_help: [string, string];
  copy_ticker_exchange_options: string[];
  copy_ticker_type_options: string[];
  copy_ticker_excluded_exchanges: string[];
  copy_ticker_excluded_types: string[];
  copy_ticker_type_order: string[];
}

export type CopyValueMap = {
  [key in CopyTypedKey]: CopiesTypeMapping[key];
};

export type CopyValueType<K extends keyof CopyValueMap> = CopyValueMap[K];

export const defaultCopiesValues: CopiesTypeMapping = {
  copy_login_list_items: [
    "Portfolio status at a glance with the Pi Quadrant Index | A powerful investment analysis tool available directly in your analysis portfolios.",
    "Private Company analysis is now available | Interested in the Pre-IPO market or accelerating a Private-to-Public Strategy? Get the right insights.",
    "Portfolio Sharing & Collaboration | A great way for analysts to collaborate on equity research and share results.",
  ],
  copy_login_heading: "Market Research & Analysis on AutoPilot",
  copy_registration_heading: "Ready to Analyze your Investments?",
  copy_registration_cta: "Register to Get Started",
  copy_registration_subheading: "What to Expect",
  copy_registration_list_items: [
    "Register with Charli | Complete the registration form and receive a free secure account to manage your investment analysis.",
    "Enter a Stock for Charli to Analyze | Pick a stock or ticker that is important for your portfolio that Charli can research and analyze.",
    "Explore Shared Portfolios | While Charli researches your equity you can explore the shared research and discover the power of the Pi Quadrant Index.",
    "Review your First Analysis | Charli will email you a report on the stock you selected and you can review the details in the app and dive deeper.",
    "Schedule and Automate Analysis | Upgrade to Professional to set custom schedules for your portfolio analysis.",
  ],
  copy_registration_footer_text: [
    "Charli is secure, trusted and used by thousands of investors worldwide. Charli works with top tier partners and providers, including national exchanges and regulators for data that is accurate, comprehensive, and timely.",
    "**There is no credit card and no contract required. The Starter package allows you to explore the features for free and get access to free reports. Upgrade to Professional for access to many advanced features and automation.",
  ],
  copy_registration_submit_button: "Register for Free",
  copy_ticker_placeholder_text: "Enter the stock or company that you would like me to analyze",
  copy_subscription_features: [
    "Access to trusted FactSet Data | true | false | true | true | true",
    "Access to Nasdaq Real Time Data | true | false | true | true | true",
    "Automatically collect and analyze information from SEC, EDGAR & SEDAR | true | true | true | true | true",
    "Augment research using web content and internet search | true | true | true | true | true",
    "Number of Concurrent Equity Research Reports Analysis | 2 | 6 | 10 | 20 | Custom",
    "Number of individual Equity Research Reports per month | 3 | 15 | 40 | 100 | Custom",
    "Number of Easy Project Reruns and re-analysis per project per month | 0 | 1 | 4 | 10 | Custom",
    "Number of Personalized Research Questions per month | 45 | 225 | 600 | 1500 | Custom",
    "Data archiving for projects, questions and outcomes | 6 Months | 6 Months | 1 Year | 3 Years | Custom",
  ],
  copy_subscription_plan: "professional",
  copy_subscription_plan_price: "99",
  copy_maintenance_banner: "",
  copy_maintenance_banner_bg_color: "orange",
  copy_maintenance_banner_text_color: "gray.800",
  copy_promotion_code: "PRO-INTRO-PRICING",
  copy_chart_quadrant_help: ["Learn more about the Portfolio Investment Quadrant Chart", "http://charliai.com/"],
  copy_chart_buysellhold_help: ["", ""],
  copy_chart_stockmarket_help: ["", ""],
  copy_ticker_exchange_options: ["NYSE", "NASDAQ", "TSX", "BATS", "NYSEARCA", "PRIVATE", "LON", "SWX", "ETR"],
  copy_ticker_type_options: ["equity", "etf", "mutual_funds", "private"],
  copy_ticker_excluded_exchanges: ["LON", "SWX", "ETR", "EPA"],
  copy_ticker_excluded_types: ["etf", "mutual_funds"],
  copy_ticker_type_order: ["equity"],
};

export function isDefinedCopyKey(key: string): key is CopyTypedKey {
  return (CopiesKeys as readonly string[]).includes(key);
}
