import { Box, Icon, Tooltip, useColorModeValue, Center, Stack, Text, Button } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useContext } from "react";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { OptionsMenuItem } from "../../components/OptionsMenuItem";
import { ConversationContext } from "screens/thread/ConversationContext";

interface Props {
  size?: string;
  isMenuItem?: boolean;
  projectType: "portfolio" | "project" | "version";
  isDeletingCollection?: boolean;
  onOpenConfirmation: () => void;
  isIcon?: boolean;
  label?: string;
  className?: string;
  projectId: string;
  navigatePath?: string;
  onCloseFunction?: () => void;
}

export const ProjectDeleteButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  size = "1rem",
  isMenuItem,
  projectType = "version",
  isDeletingCollection = false,
  onOpenConfirmation,
  isIcon,
  label,
  className = "ch-delete-project",
  projectId,
  navigatePath,
  onCloseFunction,
}) => {
  const tileBorderColor = useColorModeValue("gray.500", "gray.600");
  const additionalThumbnailCountTextColor = useColorModeValue("gray.500", "gray.400");
  const { setIsDeleteProjectId, setNavigatePath, setIsDeleteProjectType } = useContext(ConversationContext);

  const onHandleDeleteProject = (deleteType?: string) => {
    onCloseFunction && onCloseFunction();
    setIsDeleteProjectId(projectId);
    setIsDeleteProjectType(projectType);
    onOpenConfirmation();
    navigatePath && setNavigatePath(navigatePath);
  };

  return (
    <>
      {isMenuItem ? (
        <OptionsMenuItem
          menuLabel={label ? label : `Delete ${projectType}`}
          className={className}
          onClick={(event) => {
            event.stopPropagation();
            onHandleDeleteProject();
          }}
          isDisabled={isDeletingCollection}
        />
      ) : isDeletingCollection ? (
        <Stack direction="row" spacing="1rem">
          <Text color={additionalThumbnailCountTextColor} fontSize={"xs"}>
            {`Deleting ${projectType}`}
          </Text>
          <TypingIndicator size="small" />
        </Stack>
      ) : (
        <Box
          className={className}
          width="unset"
          cursor="pointer"
          backgroundColor="transparent"
          borderRadius="md"
          onClick={(event) => {
            event.stopPropagation();
            onHandleDeleteProject();
          }}>
          {!isIcon ? (
            <Button aria-label={`Delete ${projectType}`} colorScheme="red" size="sm">
              {label ? label : `Delete ${projectType}`}
            </Button>
          ) : (
            <Tooltip aria-label="" label={label ? label : `Delete ${projectType}`} placement="top" hasArrow>
              <Center>
                <Icon as={AiOutlineDelete} color={tileBorderColor} boxSize={size} _hover={{ color: additionalThumbnailCountTextColor }} />
              </Center>
            </Tooltip>
          )}
        </Box>
      )}
    </>
  );
};
