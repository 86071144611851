export const FeatureFlags = [
  "show_maintenance_screen",
  "show_maintenance_banner",
  "workflow_summary_2",
  "enable_redux_logger",
  "enable_admin_questions",
  "enable_credit_card_processing",
  "disable_stock_equity",
  "buy_sell_tag",
  "test_new_ux",
] as const;
