import { prependProxyBaseUrl, request } from "api/request";
import type { Collection } from "types/collection";
import { CollectionQuery } from "./models/CollectionQuery";
import { GetQuestions } from "./models/GetQuestions";

const COLLECTIONS_ENDPOINT = prependProxyBaseUrl("/api/contents/collections");

export async function getCollections(ids?: string[]) {
  return (await request()
    .url(`${COLLECTIONS_ENDPOINT}`)
    .query({ ...(ids && { ids }) })
    .get()
    .json()) as Collection[];
}

export async function getCollectionsByPortfolio(portfolioId?: string) {
  return (await request().url(`${COLLECTIONS_ENDPOINT}/by-portfolio/${portfolioId}`).get().json()) as Collection[];
}

export async function getCollectionsByGroup(groupId?: string) {
  return (await request().url(`${COLLECTIONS_ENDPOINT}/by-group/${groupId}`).get().json()) as Collection[];
}

export async function getCollectionsQuery(options?: { collectionType?: string; limit?: number; pageToken?: string }) {
  const response = await request()
    .url(`${COLLECTIONS_ENDPOINT}/query`)
    .query({
      ...(options && {
        ...(options.collectionType && { collectionType: options.collectionType }),
        ...(options.limit && { limit: options.limit }),
        ...(options.pageToken && { pageToken: options.pageToken }),
      }),
    })
    .get()
    .json();

  const validatedResponse = CollectionQuery.validate(response);

  if (validatedResponse.success) {
    return validatedResponse.value;
  } else {
    console.error(validatedResponse.message);
    throw new Error(validatedResponse.message);
  }
}

export async function getCollectionQuestions(collectionId: string, filters?: { byQuestions?: string[] }): Promise<GetQuestions> {
  const response = await request()
    .url(`${COLLECTIONS_ENDPOINT}/${collectionId}/questions`)
    .query({
      ...(filters?.byQuestions && { filterByQuestion: filters.byQuestions }),
    })
    .get()
    .json();

  const validate = GetQuestions.validate(response);

  if (validate.success) {
    return validate.value;
  } else {
    console.error(validate.message);
    throw new Error(validate.message);
  }
}
