import { prependProxyBaseUrl, request } from "api/request";
import type { AuthToken, User } from "../types/auth";
import store from "state/store";
import { identify, track } from "api/analytics";
import { USER_LOGGED_IN } from "api/analytics/events";
import omit from "lodash/omit";
import { getUserSubscription } from "./subscription";
import { BadRequestError } from "./networkingErrors";

const SCOPES = "email profile offline_access";
export const TOKEN_ENDPOINT = prependProxyBaseUrl("/api/auth/token");
export const ASTRELLA_KEYCLOAK_TOKEN_ENDPOINT = prependProxyBaseUrl("/api/astrella-keycloak/auth/token");
export const USER_ENDPOINT = prependProxyBaseUrl("/api/user");
export const REGISTER_ENDPOINT = prependProxyBaseUrl("/api/register");
export const REGISTER_IDP_ENDPOINT = prependProxyBaseUrl("/api/register-idp");
export const ASTRELLA_TOKEN_EXCHANGE_ENDPOINT = prependProxyBaseUrl("/api/astrella/auth");

const api = (url: string, injectToken = false) => request(injectToken, false).url(url);

export function getToken(tokenEndpoint: string, params): Promise<AuthToken> {
  return api(tokenEndpoint)
    .formUrl({
      ...params,
      client_id: process.env.REACT_APP_CHARLI_CLIENT_ID!,
      scope: SCOPES,
    })
    .post()
    .json();
}

async function authenticate(params: { [key: string]: string }, avatarUrl?: string, preAuthorizedToken?: AuthToken, isAstrella?: boolean) {
  const tokenEndpoint = isAstrella ? ASTRELLA_KEYCLOAK_TOKEN_ENDPOINT : TOKEN_ENDPOINT;
  const token = preAuthorizedToken || (await getToken(tokenEndpoint, params));
  const user = await getUser(token.access_token);
  if (avatarUrl) {
    user.avatarUrl = avatarUrl;
  }

  if (isAstrella) {
    user.identityProvider = "astrella";
  } else if (params.subject_issuer === "google" || params.subject_issuer === "apple") {
    user.identityProvider = params.subject_issuer;
  } else if (params.grant_type === "password") {
    user.identityProvider = "email";
  }

  // Don't emit login event when token is refreshed
  if (params.grant_type !== "refresh_token") {
    if (user.analyticsId) {
      identify(user.analyticsId, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        createdAt: user.creationDate,
      });
    }

    if (!user.hasJustRegistered) {
      track(USER_LOGGED_IN, { auth_type: params.subject_issuer });
    }
  }

  if (params.grant_type === "refresh_token") {
    try {
      await getUserSubscription(user.id, token.access_token);
    } catch (e) {
      console.error("Failed to fetch user subscription details");
    }
  }

  return {
    user: omit(user, "hasJustRegistered") as User,
    token: token,
  };
}

export async function getUser(accessToken: string) {
  const user = (await api(USER_ENDPOINT).auth(`Bearer ${accessToken}`).get().json()) as {
    sub: string;
    email: string;
    given_name?: string;
    family_name?: string;
    creation_date: Date;
    has_just_registered: boolean;
    is_email_verified: boolean;
    analyticsId?: string;
    avatarUrl?: string;
  };

  return {
    id: user.sub,
    email: user.email,
    firstName: user.given_name,
    lastName: user.family_name,
    creationDate: user.creation_date,
    hasJustRegistered: user.has_just_registered,
    isEmailVerified: user.is_email_verified,
    analyticsId: user.analyticsId,
    avatarUrl: user.avatarUrl,
  } as User & { hasJustRegistered: boolean };
}

export async function signUpIdentityProviderUser(accessToken: string, issuer: string, avatarUrl?: string, marketingOptIn?: boolean) {
  const token = await getToken(TOKEN_ENDPOINT, {
    grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
    subject_token_type: "urn:ietf:params:oauth:token-type:access_token",
    requested_token_type: "urn:ietf:params:oauth:token-type:refresh_token",
    subject_token: accessToken,
    subject_issuer: issuer,
  });

  try {
    await api(REGISTER_IDP_ENDPOINT).auth(`Bearer ${token.access_token}`).post({ marketingOptIn }).res();
  } catch (error) {
    if (error.message === BadRequestError.USER_ALREADY_EXISTS) {
      throw new Error(
        "It looks like you already have a Charli AI account associated with your Google account. Please log in with your existing account to continue."
      );
    } else {
      throw new Error(error);
    }
  }

  return await authenticateIdp(accessToken, issuer, avatarUrl);
}

export async function authenticateIdp(accessToken: string, issuer: string, avatarUrl?: string) {
  return await authenticate(
    {
      grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
      subject_token_type: "urn:ietf:params:oauth:token-type:access_token",
      requested_token_type: "urn:ietf:params:oauth:token-type:refresh_token",
      subject_issuer: issuer,
      subject_token: accessToken,
    },
    avatarUrl
  );
}

export async function authenticateIdToken(idToken: string, issuer: string, audience: string) {
  return await authenticate({
    grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
    subject_token_type: "urn:ietf:params:oauth:token-type:id_token",
    requested_token_type: "urn:ietf:params:oauth:token-type:refresh_token",
    subject_issuer: issuer,
    subject_token: idToken,
    audience: audience,
  });
}

export async function authenticateWithCredentials(username: string, password: string) {
  return await authenticate({
    grant_type: "password",
    username,
    password,
  });
}

export async function signupWithCredentials(
  firstName: string,
  lastName: string,
  username: string,
  organization: string,
  factset: boolean,
  role: string,
  password: string,
  marketingOptIn: boolean
) {
  const signupResponse = (await api(REGISTER_ENDPOINT)
    .post({
      firstName,
      lastName,
      email: username,
      organization,
      factset,
      role,
      password,
      marketingOptIn,
    })
    .json()) as { userId: string };

  // todo handle errors

  console.log(`Successfully signed up with email and password, user id = ${signupResponse.userId}`);

  return authenticateWithCredentials(username, password);
}

export async function restoreSession(token: AuthToken) {
  return await authenticate({
    grant_type: "refresh_token",
    refresh_token: token.refresh_token,
  });
}

export async function authenticateWithAstrellaAccessToken(accessToken: string) {
  const token = (await api(ASTRELLA_TOKEN_EXCHANGE_ENDPOINT)
    .formUrl({
      accessToken,
    })
    .post()
    .json()) as AuthToken;
  return await authenticate({}, undefined, token, true);
}

export async function restoreAstrellaSession(token: AuthToken) {
  return await authenticate(
    {
      grant_type: "refresh_token",
      refresh_token: token.refresh_token,
    },
    undefined,
    undefined,
    true
  );
}

export const getAccessToken = () => {
  return getFullToken()?.access_token;
};

export const getFullToken = () => {
  const state = store.getState();

  return state.session.token;
};

export const getUserInfo = () => {
  const state = store.getState();

  return state.session.user;
};
