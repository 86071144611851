import { Array, Literal, Record, String, Union } from "runtypes";
import { Answer } from "./Answer";
import type { Static } from "runtypes";
import { QuestionAnswerStatus } from "./QuestionAnswerStatus";

export const Question = Record({
  id: String,
  question: String,
  collectionId: String,
  creationDate: String,
  initiator: Union(Literal("user"), Literal("charli"), Literal("system")),
  status: QuestionAnswerStatus,
  answers: Array(Answer),
  focus: String.nullable(),
});

export type Question = Static<typeof Question>;
