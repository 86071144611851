import { Badge, Box, Button, Flex, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import { createTicker, exportTickersToCSV, getPaginatedTickers, importTickers, updateTicker } from "api/tickers";
import { CreateTickerPanel } from "./components/CreateTicker";
import { ImportTickersPanel } from "./components/ImportTickersPanel";
import { TickersFilters } from "./components/TickersFilters";
import { TickersFiltersContextProvider, useTickersFiltersContext } from "./contexts/TickersFiltersContext";
import { TickerStatus } from "api/tickers/models/TickerStatus";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { UpdateTickerPanel } from "./components/UpdateTicker";
import { useButtonProps, useInfiniteLoading, useSidebarNavigation, useUserPreference } from "hooks";
import capitalize from "lodash/capitalize";
import React, { useCallback, useEffect } from "react";
import type { CreateTickerForm } from "./components/CreateTicker";
import type { CreateTickerRequest } from "api/tickers/models/CreateTickerRequest";
import type { ImportTickersForm } from "./components/ImportTickersPanel";
import type { Ticker } from "api/tickers/models/Ticker";
import type { UpdateTickerForm } from "./components/UpdateTicker";
import type { UpdateTickerRequest } from "api/tickers/models/UpdateTickerRequest";
import { getTickerTypeOption } from "./utils/getTickerTypeOption";
import { ExportTickersPanel } from "./components/ExportTickersPanel";
import { getEnvironment } from "screens/common/app";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { DEFAULT_SIDEBAR_WIDTH } from "screens/landing/components";

const PAGE_SIZE = 20;

const TickersInner = () => {
  const { status, exchange, searchQuery, searchSymbol, type } = useTickersFiltersContext();
  const bgColor = useColorModeValue("gray.200", "gray.700");
  const titleColor = useColorModeValue("charli.lightGray", "gray.500");
  const commonButtonProps = useButtonProps("sm", "primary");
  const { isOpen: isOpenImport, onClose: onCloseImport, onOpen: onOpenImport } = useDisclosure();
  const { isOpen: isOpenEdit, onClose: onCloseEdit, onOpen: onOpenEdit } = useDisclosure();
  const { isOpen: isOpenCreate, onClose: onCloseCreate, onOpen: onOpenCreate } = useDisclosure();
  const { isOpen: isOpenExport, onClose: onCloseExport, onOpen: onOpenExport } = useDisclosure();
  const toast = useToast();
  const [isLoadingForm, setIsLoadingForm] = React.useState(false);
  const [tempTicker, setTempTicker] = React.useState<Ticker | undefined>(undefined);
  const sidebarWidth = (useUserPreference("ui_sidebar_width") as number) || (DEFAULT_SIDEBAR_WIDTH as number);
  const { currentSidebarType } = useSidebarNavigation();

  const fetchMore = useCallback(
    async (nextToken: string | null) => {
      const validateStatus = TickerStatus.validate(status);

      const response = await getPaginatedTickers({
        ...(nextToken && { token: nextToken }),
        ...(status && validateStatus.success && { status: validateStatus.value }),
        ...(searchQuery && { query: searchQuery }),
        ...(exchange && { exchange }),
        ...(searchSymbol && { symbol: searchSymbol }),
        ...(type && { type }),
        limit: PAGE_SIZE,
      });

      return { data: response.data, nextToken: response.nextToken };
    },
    [status, exchange, searchQuery, searchSymbol, type]
  );

  const {
    loading: isLoading,
    items,
    lastMessageObserverRef: lastMessageObserver,
    hasNextPage,
    clear,
  } = useInfiniteLoading<Ticker>({
    loadItems: fetchMore,
  });

  const handleSubmitExport = (values: { exchange: string }) => {
    setIsLoadingForm(true);

    exportTickersToCSV({ exchange: values.exchange })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${getEnvironment().label}-${values.exchange}-TICKERS.csv`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: "Error exporting tickers",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  };

  const handleSubmitImport = (values: ImportTickersForm) => {
    const { file, exchange } = values;

    if (!file) {
      return;
    }

    setIsLoadingForm(true);
    importTickers({ exchange, file })
      .then(() => {
        toast({
          title: "Tickers imported",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onCloseImport();
        clear();
      })
      .catch((error) => {
        toast({
          title: "Error importing tickers",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  };

  const handleSubmitUpdate = (values: UpdateTickerForm, id?: string) => {
    if (!id) {
      return;
    }

    const payload: UpdateTickerRequest = {
      name: values.name,
      exchange: values.exchange,
      status: values.status,
      notes: values.notes || null,
      companyLogoUrl: values.companyLogoUrl || null,
      companyUrl: values.companyUrl || null,
      ...(values.type !== "null" && { type: values.type }),
    };

    setIsLoadingForm(true);
    updateTicker({ id, ticker: payload })
      .then(() => {
        toast({
          title: "Ticker updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onCloseEdit();
        clear();
      })
      .catch((error) => {
        toast({
          title: "Error updating ticker",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  };

  const handleSubmitCreate = (values: CreateTickerForm) => {
    const payload: CreateTickerRequest = {
      name: values.name,
      exchange: values.exchange,
      symbol: values.symbol,
      type: values.type,
      notes: values.notes || null,
      companyLogoUrl: values.companyLogoUrl || null,
      companyUrl: values.companyUrl || null,
    };

    setIsLoadingForm(true);
    createTicker({ ticker: payload })
      .then(() => {
        toast({
          title: "Ticker created",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onCloseCreate();
        clear();
      })
      .catch((error: Error) => {
        toast({
          title: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  };

  useEffect(() => {
    clear();
  }, [clear, status, exchange, searchQuery, searchSymbol, type]);

  return (
    <>
      <ImportTickersPanel isLoading={isLoadingForm} onSubmit={handleSubmitImport} isOpen={isOpenImport} onClose={onCloseImport} />
      <ExportTickersPanel isLoading={isLoadingForm} onSubmit={handleSubmitExport} isOpen={isOpenExport} onClose={onCloseExport} />
      <TickersFilters isLoading={isLoading} />
      <UpdateTickerPanel
        isLoading={isLoadingForm}
        ticker={tempTicker}
        isOpen={isOpenEdit}
        onClose={onCloseEdit}
        onSubmit={handleSubmitUpdate}
      />

      <CreateTickerPanel
        isLoading={isLoadingForm}
        onSubmit={(values) => handleSubmitCreate(values)}
        isOpen={isOpenCreate}
        onClose={onCloseCreate}
      />

      <Flex mb={"1rem"}>
        <Button mr={"0.5rem"} onClick={() => onOpenImport()} {...commonButtonProps}>
          Import Tickers
        </Button>
        <Button mr={"0.5rem"} onClick={() => onOpenExport()} {...commonButtonProps}>
          Export Tickers
        </Button>
        <Button onClick={() => onOpenCreate()} {...commonButtonProps}>
          Create Ticker
        </Button>
      </Flex>

      {/* Table */}
      <Box overflowX="auto">
        <Box width={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth + 30}px)`}>
          <Box
            fontSize={"small"}
            borderBottomColor={bgColor}
            borderBottom={`2px solid`}
            mb={1}
            color={titleColor}
            display={"flex"}
            justifyContent="space-between">
            <Box width={200}>Name</Box>
            <Box width={20}>Symbol</Box>
            <Box width={20}>Exchange</Box>
            <Box width={20}>Status</Box>
            <Box width={20}>Type</Box>
            <Box width={200}>Notes</Box>
            <Box width={200}>Company Logo</Box>
            <Box width={200}>Company URL</Box>
            <Box width={120}>Updated date</Box>
            <Box width={120}>Update by</Box>
          </Box>

          {items.map((item) => (
            <Box
              key={`${item.id}`}
              onClick={(evt) => {
                evt.preventDefault();
                setTempTicker(item);
                onOpenEdit();
              }}
              cursor={"pointer"}
              fontSize="sm"
              _hover={{ bgColor }}
              display={"flex"}
              justifyContent="space-between"
              p={1}>
              <Box width={200}>
                <TextOverflowTooltip noOfLines={1} label={item.name} highlightBackground={false} />
              </Box>
              <Box width={20} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
                {item.symbol}
              </Box>
              <Box width={20} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
                {item.exchange}
              </Box>
              <Box width={20} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
                <Badge colorScheme={item.status === "beta" ? "blue" : item.status === "active" ? "green" : "red"}>
                  {capitalize(item.status)}
                </Badge>
              </Box>
              <Box width={20} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
                <TextOverflowTooltip
                  noOfLines={1}
                  label={item.type ? getTickerTypeOption(item.type).label : ""}
                  highlightBackground={false}
                />
              </Box>
              <Box width={200} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
                <TextOverflowTooltip breakWord={false} noOfLines={1} label={item.notes ?? ""} highlightBackground={false} />
              </Box>
              <Box width={200} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
                <TextOverflowTooltip breakWord={false} noOfLines={1} label={item.companyLogoUrl ?? ""} highlightBackground={false} />
              </Box>
              <Box width={200} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
                <TextOverflowTooltip breakWord={false} noOfLines={1} label={item.companyUrl ?? ""} highlightBackground={false} />
              </Box>
              <Box width={120} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
                {item.lastUpdatedDate ? format(parseISO(item.lastUpdatedDate), "dd/MMM/yy HH:mm") : ""}
              </Box>
              <Box width={120} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
                {item.lastUpdatedByUser ?? ""}
              </Box>
            </Box>
          ))}

          {hasNextPage && (
            <Flex visibility={isLoading ? "visible" : "hidden"} height={"3rem"} ref={lastMessageObserver} align="center" justify="center">
              <TypingIndicator />
            </Flex>
          )}
        </Box>
      </Box>
    </>
  );
};

export const Tickers = () => {
  return (
    <TickersFiltersContextProvider>
      <TickersInner />
    </TickersFiltersContextProvider>
  );
};
