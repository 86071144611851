import { useColorModeValue } from "@chakra-ui/react";

export const useFocusBadgeColors = () => {
  const sentiment = useColorModeValue("orange.200", "orange.900");
  const analytical = useColorModeValue("twitter.50", "twitter.900");
  const equity = useColorModeValue("twitter.50", "twitter.900");
  const etf = useColorModeValue("teal.100", "teal.900");
  const question = useColorModeValue("gray.200", "gray.800");
  const mandatory = useColorModeValue("orange.200", "orange.700");
  const optional = useColorModeValue("twitter.50", "twitter.700");
  const recommended = useColorModeValue("purple.100", "purple.700");

  return {
    SENTIMENT: sentiment,
    ANALYTICAL: analytical,
    EQUITY: equity,
    ETF: etf,
    QUESTION: question,
    MANDATORY: mandatory,
    OPTIONAL: optional,
    RECOMMENDED: recommended,
  };
};
