import appleLogo from "screens/common/static/logos/apple_logo.png";
import microsoftLogo from "screens/common/static/logos/microsoft_logo.png";
import teslaLogo from "screens/common/static/logos/tesla_logo.png";
import alphabetLogo from "screens/common/static/logos/alphabet_logo.png";
import nvidiaLogo from "screens/common/static/logos/nvidia_logo.png";
import metaLogo from "screens/common/static/logos/meta_logo.png";
import amazonLogo from "screens/common/static/logos/amazon_logo.png";

export interface PlaceholderCompany {
  ticker: string;
  securityType: string;
  companyName: string;
  logo: any;
  exchange: string;
  overview: string;
}

export const placeholderCompanies: PlaceholderCompany[] = [
  {
    logo: appleLogo,
    companyName: "Apple Inc",
    ticker: "AAPL",
    exchange: "NASDAQ",
    securityType: "equity",
    overview:
      "Apple Inc. is a multinational technology company that designs, manufactures, and markets consumer electronics, computer software, and online services. The company's products and services include iPhones, Mac computers, iPads, Apple Watches, Apple TVs, iOS, macOS, watchOS, and tvOS operating systems, as well as iCloud, Apple Music, Apple Pay, and other digital content and services.",
  },
  {
    logo: microsoftLogo,
    companyName: "Microsoft Corp",
    ticker: "MSFT",
    exchange: "NASDAQ",
    securityType: "equity",
    overview:
      "Microsoft Corporation is a multinational technology company that develops, manufactures, licenses, and supports a wide range of software products, services, and devices. The company's product offerings include operating systems, cross-device productivity applications, server applications, business solution applications, desktop and server management tools, software development tools, video games, and online advertising platforms. Microsoft also provides cloud-based computing services, artificial intelligence, and machine learning capabilities, as well as a range of services, including consulting, support, and training.",
  },
  {
    logo: teslaLogo,
    companyName: "Tesla Inc",
    ticker: "TSLA",
    exchange: "NASDAQ",
    securityType: "equity",
    overview:
      "Tesla, Inc. is an American multinational corporation that specializes in electric vehicles, clean energy, and autonomous driving technology. The company designs, manufactures, and sells electric cars, solar panels, and energy storage systems, while also providing services such as vehicle maintenance, insurance, and charging infrastructure. Additionally, Tesla is a leading player in the development of autonomous driving technology and offers a range of software and services to support its products.",
  },
  {
    logo: alphabetLogo,
    companyName: "Alphabet Inc Class A",
    ticker: "GOOGL",
    exchange: "NASDAQ",
    securityType: "equity",
    overview:
      "Alphabet Inc. is a holding company that operates through two main segments: Google Services and Google Cloud, as well as a collection of non-Google businesses referred to as Other Bets. The company generates revenue through various means, including: Delivering relevant and cost-effective online advertising. Providing cloud-based solutions, including infrastructure, platform services, communication, and collaboration tools Selling other products and services, such as apps, in-app purchases, and hardware.",
  },
  {
    logo: nvidiaLogo,
    companyName: "Nvidia Corp",
    ticker: "NVDA",
    exchange: "NASDAQ",
    securityType: "equity",
    overview:
      "NVIDIA Corporation is a leading technology company that designs, manufactures, and markets computer graphics interface and high-performance computing hardware, including graphics processing units (GPUs) and high-performance computing hardware, as well as provides related software and services, primarily serving the gaming, professional visualization, datacenter, and automotive markets.",
  },
  {
    logo: amazonLogo,
    companyName: "Amazon.com Inc",
    ticker: "AMZN",
    exchange: "NASDAQ",
    securityType: "equity",
    overview:
      "Amazon.com, Inc. is a multinational technology company that focuses on e-commerce, cloud computing, and artificial intelligence. The company operates through three segments: North America, International, and Amazon Web Services (AWS). It serves various customer sets, including consumers, sellers, developers, enterprises, content creators, advertisers, and employees. Amazon offers a range of products and services, including online and physical stores, programs for sellers and content creators, AWS technology services, electronic devices, and advertising services.",
  },
  {
    logo: metaLogo,
    companyName: "Meta Platforms Inc",
    ticker: "META",
    exchange: "NASDAQ",
    securityType: "equity",
    overview:
      "Meta Platforms, Inc. is a technology company that operates a range of businesses, including social media platforms, online advertising, and e-commerce, with a focus on connecting people, building communities, and bringing the world closer together.",
  },
];
