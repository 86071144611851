import { Box, Button, Center, Highlight, Link, Stack, Tag, TagLabel, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { useButtonProps, useConversation } from "hooks";
import type { FunctionComponent } from "react";
import React, { useState, useCallback, useEffect, useContext } from "react";
import type { DetailSectionDataAction } from "types/content/DetailSectionDataAction";
import { StringWithEditMode } from "./StringWithEditMode";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { SmallActionConfidenceScore } from "screens/content/contentCanvas/cell/SmallActionConfidenceScore";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { ContentFilterContext } from "screens/content/contentCanvas/body/ContentFilterContext";

export type TableRowValue = {
  id?: string;
  className?: string;
  title: string;
  value: string | undefined;
  confidence?: number;
  url?: string;
  isHighlighted?: boolean;
  entityName?: string;
  type?: "string" | "currency" | "currency_code" | "date" | "url" | "hyperlink" | "number" | "action";
  rowMaxHeight?: string;
  action?: DetailSectionDataAction;
  onSendAction?: (intent?: string, entities?: { [key: string]: string }) => void;
  valueType?: string;
};

interface Props {
  sectionHeader?: string;
  rows: TableRowValue[];
  onEdit?: (entity: string, value: string, id?: string) => void;
  isEditEnabled?: boolean;
  conversationId?: string;
  showEmptyValues?: boolean;
  valueColumnAlignRight?: boolean;
  isTextTruncated?: boolean;
}

export const TwoColumnTable: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  sectionHeader,
  rows,
  onEdit,
  isEditEnabled = false,
  conversationId,
  showEmptyValues = true,
  valueColumnAlignRight = true,
  isTextTruncated = false,
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const highlightColor = useColorModeValue("teal.500", "teal.200");
  const categoryTagBgColor = useColorModeValue("tags.category", "teal.800");
  const tagTextColor = useColorModeValue("tags.color", "tags.colorDarkMode");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [actionIntent, setActionIntent] = useState<string | undefined>("");
  const actionState = useConversation(conversationId || "", [actionIntent || ""]);
  const onHandleAction = useCallback((row: TableRowValue) => {
    setIsActionLoading(true);
    row.action && row.action.intent !== null && setActionIntent(row.action?.intent);
    row.onSendAction && row.action?.intent !== null && row.onSendAction(row.action?.intent, row.action?.entities);
  }, []);
  const { searchText } = useContext(ContentFilterContext);

  useEffect(() => {
    if (actionState.conversationState === "completed") {
      setIsActionLoading(false);
    }
  }, [actionState.conversationState]);

  const getRowForType = (row: TableRowValue) => {
    if (row.entityName && onEdit) {
      return (
        <StringWithEditMode
          id={row.id}
          value={row.value}
          onEdit={onEdit}
          isEditEnabled={isEditEnabled}
          entity={row.entityName!}
          rowMaxHeight={row.rowMaxHeight}
          valueColumnAlignRight={valueColumnAlignRight}
          isTextTruncated={isTextTruncated}
        />
      );
    } else if (row.type === "url" || row.type === "hyperlink" || (typeof row.value === "string" && row.value.includes("://"))) {
      return (
        <Text minHeight="1.4rem" fontSize="sm" whiteSpace="break-spaces" wordBreak="break-word">
          <Link fontSize="sm" isExternal href={row.url || row.value} color={highlightColor}>
            {row.value && <Highlight query={searchText}>{row.value}</Highlight>}
          </Link>
        </Text>
      );
    } else if (row.type === "action" && row.action !== undefined) {
      return (
        <Tooltip label={row.action?.context} placement="top" hasArrow>
          <Button minWidth={"7rem"} onClick={() => onHandleAction(row)} {...secondaryButtonProps}>
            {!isActionLoading ? `${row.action?.label}` : <TypingIndicator size="small" />}
          </Button>
        </Tooltip>
      );
    } else if (row.title === "Category" && sectionHeader?.toLowerCase() === "content data") {
      return (
        <Tag
          mt="3px"
          size="xs"
          px="0.4rem"
          py="0.1rem"
          mr="0.4rem"
          backgroundColor={categoryTagBgColor}
          color={tagTextColor}
          borderColor={categoryTagBgColor}
          borderWidth="0.15rem"
          borderRadius="full"
          cursor={"default"}>
          <TagLabel fontSize="xs">{row.value}</TagLabel>
        </Tag>
      );
    } else {
      return (
        <Text
          minHeight="1.4rem"
          color={row.isHighlighted ? highlightColor : textColor}
          whiteSpace="break-spaces"
          backgroundColor={row.value?.length === 0 ? "#fffee6" : undefined}
          wordBreak="break-word">
          <Highlight query={searchText}>{row.value || ""}</Highlight>
        </Text>
      );
    }
  };

  return (
    <Box width="100%">
      {rows.map((row, index) => {
        if (!showEmptyValues && (row.value === undefined || row.value === "")) {
          return null;
        }
        return (
          <Stack direction="row" width="100%" spacing=".5rem" key={`${index}-${row.value}`} justifyContent="space-between">
            <Stack direction="row" justifyContent="space-evenly" width="100%" spacing="1rem">
              <Box
                width="100%"
                pt={(row.title === "Category" && sectionHeader?.toLowerCase() === "content data") || row.type === "action" ? "9px" : "1"}>
                <TextOverflowTooltip
                  searchText={searchText}
                  label={row.title}
                  fontSize="sm"
                  color={textColor}
                  style={{ minHeight: "1.4rem" }}
                />
              </Box>
              <Box className={row.className} fontSize="sm" px="0" py="1" width="100%">
                {getRowForType(row)}
              </Box>
            </Stack>
            {row.confidence && (
              <Center>
                <SmallActionConfidenceScore
                  fontSize="10px"
                  score={Math.round(row.confidence * 100)}
                  tooltip={`Confidence score ${Math.round(row.confidence * 100)}`}
                />
              </Center>
            )}
          </Stack>
        );
      })}
    </Box>
  );
};
