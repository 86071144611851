export interface Feature {
  text: string;
  available?: boolean;
  description?: string;
}

export interface PricingTier {
  name: string;
  internalPlanName?: string;
  price: string | "Free" | "Contact For Pricing";
  originalPrice?: string;
  description: string;
  limitedTimeOffer?: string;
  dataSources?: Feature[];
  features: Feature[];
  buttonText: string;
  isPopular?: boolean;
  isCurrent?: boolean;
  canUpgradeTo?: string[];
}

export const tiers: PricingTier[] = [
  {
    name: "Starter",
    internalPlanName: "free_trial",
    price: "Free",
    description: "For everyone. Get started. No credit card required and realize the business benefits immediately",
    limitedTimeOffer: "Unlimited *Magnificent 7 Summary Analysis for all Plans. (*TSLA, GOOG, NVDA, MSFT, META, AAPL, AMZN)",
    dataSources: [
      { text: "Automatically collect and analyze information from SEC EDGAR & SEDAR", available: true },
      { text: "Augment research using web content and internet search", available: true },
      { text: "ETF and Mutual Funds", available: false },
      { text: "FactSet Data Feeds and Analysis", available: false },
    ],
    features: [
      { text: "Number of individual Equities Analyzed per Month", description: "2" },
      { text: "ETF, Mutual Funds and International Exchanges", available: false },
      { text: "Private Company Analysis", available: false },
      { text: "On Demand Additional Equities", description: "$20 per additional equity" },
      { text: "Data archiving for projects, questions and outcomes", description: "6 Months" },
      { text: "Portfolio Pi Quadrant Index Analysis", available: true },
      { text: "Advanced Portfolio Monitoring Charts", available: false },
    ],
    buttonText: "Free Reports",
    canUpgradeTo: ["professional", "business", "premium", "enterprise"],
  },
  {
    name: "Professional",
    internalPlanName: "professional",
    price: "$150",
    originalPrice: "As little as $9.40 per equity monitored",
    limitedTimeOffer: "Pay only $20 per month for the first 3 months, No Contract",
    description:
      "For Individual Investors, RIA's, Analysts AND small teams who want to automate research with deep financial data, Nasdaq, SEC, TMX, and SEDAR.",
    dataSources: [
      { text: "Automatically collect and analyze information from SEC EDGAR & SEDAR", available: true },
      { text: "Augment research using web content and internet search", available: true },
      { text: "ETF and Mutual Funds", available: true },
      { text: "FactSet Data Feeds and Analysis", available: false },
    ],
    features: [
      { text: "Number of individual Equities Analyzed per Month", description: "18 ($9.40 per equity)" },
      { text: "ETF, Mutual Funds and International Exchanges", available: true },
      { text: "Private Company Analysis", available: false },
      { text: "On Demand Additional Equities", description: "$15 per additional equity" },
      { text: "Data archiving for projects, questions and outcomes", description: "1 Year" },
      { text: "Portfolio Pi Quadrant Index Analysis", available: true },
      { text: "Advanced Portfolio Monitoring Charts", available: true },
    ],
    buttonText: "Upgrade Plan",
    isPopular: true,
    canUpgradeTo: ["business", "premium", "enterprise"],
  },
  {
    name: "Business",
    internalPlanName: "business",
    price: "$595",
    limitedTimeOffer: "Includes Unlimited Scheduled Reruns for projects for the first 3 months",
    description:
      "For small and mid-sized teams that want the power of combining qualitative and quantitative data. Includes advanced Portfolio Metrics and Analysis.",
    dataSources: [
      { text: "Automatically collect and analyze information from SEC EDGAR & SEDAR", available: true },
      { text: "Augment research using web content and internet search", available: true },
      { text: "ETF and Mutual Funds", available: true },
      { text: "FactSet Data Feeds and Analysis", description: "*Add-On" },
    ],
    features: [
      { text: "Number of individual Equities Analyzed per Month", description: "80 ($7.50 per equity)" },
      { text: "ETF, Mutual Funds and International Exchanges", available: true },
      { text: "Private Company Analysis", available: true },
      { text: "On Demand Additional Equities", description: "$10 per additional equity" },
      { text: "Data archiving for projects, questions and outcomes", description: "2 Years" },
      { text: "Portfolio Pi Quadrant Index Analysis", available: true },
      { text: "Advanced Portfolio Monitoring Charts", available: true },
    ],
    buttonText: "Contact Us",
    canUpgradeTo: ["premium", "enterprise"],
  },
  {
    name: "Premium",
    internalPlanName: "premium",
    price: "$1,495",
    limitedTimeOffer: "Includes Unlimited Scheduled Reruns for projects for the first 6 months",
    description: "For mid to larger teams that need increased volumes, features, and integrations.",
    dataSources: [
      { text: "Automatically collect and analyze information from SEC EDGAR & SEDAR", available: true },
      { text: "Augment research using web content and internet search", available: true },
      { text: "ETF and Mutual Funds", available: true },
      { text: "FactSet Data Feeds and Analysis", description: "*Add-On" },
    ],
    features: [
      { text: "Number of individual Equities Analyzed per Month", description: "230 ($6.50 per equity)" },
      { text: "ETF, Mutual Funds and International Exchanges", available: true },
      { text: "Private Company Analysis", available: true },
      { text: "On Demand Additional Equities", description: "$8 per additional equity" },
      { text: "Data archiving for projects, questions and outcomes", description: "3 Years" },
      { text: "Portfolio Pi Quadrant Index Analysis", available: true },
      { text: "Advanced Portfolio Monitoring Charts", available: true },
    ],
    buttonText: "Contact Us",
    canUpgradeTo: ["enterprise"],
  },
  {
    name: "Enterprise",
    internalPlanName: "enterprise",
    price: "Contact For Pricing",
    limitedTimeOffer: "2 Free Custom Integrations, 2 Free Custom Templates",
    description:
      "For enterprise teams that have a greater need for a data feed, analysis integration customization, and private data sources for the deepest qualitative and quantitative data insights.",
    dataSources: [
      { text: "Automatically collect and analyze information from SEC EDGAR & SEDAR", available: true },
      { text: "Augment research using web content and internet search", available: true },
      { text: "ETF and Mutual Funds", available: true },
      { text: "FactSet Data Feeds and Analysis", description: "*Add-On" },
    ],
    features: [
      { text: "Number of individual Equities Analyzed per Month", description: "Custom" },
      { text: "ETF, Mutual Funds and International Exchanges", available: true },
      { text: "Private Company Analysis", available: true },
      { text: "On Demand Additional Equities", description: "Custom" },
      { text: "Data archiving for projects, questions and outcomes", description: "Custom" },
      { text: "Portfolio Pi Quadrant Index Analysis", available: true },
      { text: "Advanced Portfolio Monitoring Charts", available: true },
    ],
    buttonText: "Contact Us",
  },
];
