import React, { useRef, useEffect, useMemo, useCallback, useState } from "react";
import { AutocompleteInput } from "screens/collection/views/AutocompleteInput";
import { Box, Flex, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { SemanticFocusedAnswer } from "screens/collection/views/SemanticFocusedAnswer";
import { WorkflowProgressBar } from "screens/landing/components/WorkflowProgressBar";
import { formatDistanceToNow } from "date-fns";
import { useConversationDialogUtils } from "./useConversationDialogUtils";
import { useConversationDialogSuggestionLists } from "./useConversationDialogSuggestionLists";
import { useConversationDialogSubmitFunctions } from "./useConversationDialogSubmitFunctions";
import { useEntitlementKey, useProjectParams } from "hooks";
import { useLocation } from "react-router-dom";
import { useConversationContext } from "./ConversationContext";
import type { SuggestedQuestion } from "api/suggestions";
import { ProjectQuestionsProvider } from "screens/collection/ProjectQuestionsContext";

export type ConversationInputIds = "conversation-input" | "view-input";

interface Props {
  initialText?: string;
  className?: string;
  inputId: ConversationInputIds;
  minWidth?: string;
  minWidthList?: string;
  marginLeftList?: string;
  afterSubmit?: () => void;
  conversationId?: string;
  renderRole?: "cli" | "embedded";
  portfolioId?: string;
  defaultIsOpen?: boolean;
  hasBoxShadow?: boolean;
  disableComponent?: boolean;
  tooltip?: string;
  showToast?: boolean;
}

export const ConversationDialogInputInner: React.FC<Props> = React.memo(
  ({
    initialText,
    className,
    inputId,
    minWidth,
    minWidthList,
    marginLeftList,
    afterSubmit,
    conversationId,
    renderRole = "cli",
    portfolioId,
    defaultIsOpen,
    hasBoxShadow = true,
    disableComponent,
    tooltip,
    showToast,
  }) => {
    const { projectId } = useProjectParams();
    const inputRef = useRef<HTMLInputElement>(null);
    const { pathname } = useLocation();
    const {
      showCommands,
      setShowCommands,
      onConversationClose,
      initialQuestionText,
      setInitialQuestionText,
      initialQuestionFocus,
      setInitialQuestionFocus,
      focusedInputId,
      setFocusedInputId,
    } = useConversationContext();
    const [questionText, setQuestionText] = useState(initialQuestionText);

    const {
      hasExceededMaxProjects,
      currentCollectionType,
      maxAllowedProjects,
      currentWorkflowId,
      workflowStatus,
      currentWorkflowCompletionDate,
      canBypassDisabled,
      setCanBypassDisabled,
    } = useConversationDialogUtils();

    const {
      isLoading,
      currentSuggestionList,
      currentSuggestionListType,
      isInputDisabled: isBaseInputDisabled,
      handleUpdateSourceWeightPreference,
      selectedFocus,
      answerFocusOptions,
      selectedSourceWeightsIndex,
      onResetQuestion,
      debouncedTickerFilter,
    } = useConversationDialogSuggestionLists({
      questionText,
      setQuestionText,
      maxSuggestions: pathname.includes("/register") ? 5 : 60,
    });

    const isInputDisabled = isBaseInputDisabled || (focusedInputId !== inputId && focusedInputId !== undefined);
    const hasCommandLineEntitlement = useEntitlementKey("ui_enable_command_suggestions");
    const canBypassUsageLimits = useEntitlementKey("bypass_usage_limits");

    const { currentSubmitFunction, onSubmitDueDiligenceProject, currentConversationID, isConversationOpen } =
      useConversationDialogSubmitFunctions({
        questionText,
        selectedFocus,
        afterSubmit,
        conversationId,
        mode: renderRole === "cli" ? renderRole : "suggested",
        showToast,
      });

    useEffect(() => {
      if (currentSuggestionListType !== "tickers" || questionText.startsWith("/")) return;
      debouncedTickerFilter(questionText);
    }, [debouncedTickerFilter, currentSuggestionListType, questionText]);

    useEffect(() => {
      setQuestionText(initialQuestionText.length > 0 ? initialQuestionText : questionText);
      initialQuestionFocus && handleUpdateSourceWeightPreference(initialQuestionFocus);
    }, [pathname, setQuestionText, initialQuestionText, questionText, handleUpdateSourceWeightPreference, initialQuestionFocus]);

    useEffect(() => {
      if (questionText !== initialQuestionText) {
        setInitialQuestionText("");
      }
    }, [questionText, initialQuestionText, setInitialQuestionText, setInitialQuestionFocus, pathname]);

    useEffect(() => {
      onResetQuestion();
    }, [onResetQuestion, pathname]);

    useEffect(() => {
      if (hasCommandLineEntitlement && isConversationOpen && inputId === "conversation-input" && showCommands) {
        setQuestionText("/");
      }
      setShowCommands(false);
    }, [inputId, isConversationOpen, setShowCommands, showCommands, setQuestionText, hasCommandLineEntitlement]);

    useEffect(() => {
      const handleKeyDown = (ev: KeyboardEvent) => {
        if (ev.key === "Escape" && isConversationOpen && questionText.length === 0 && inputId === "conversation-input") {
          onConversationClose();
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => document.removeEventListener("keydown", handleKeyDown);
    }, [inputId, isConversationOpen, onConversationClose, questionText]);

    const errorMessage = useMemo(() => {
      const commonStyles = {
        fontSize: "sm",
        bgColor: "orange.100",
        borderRadius: "md",
        p: "8px",
        lineHeight: "1.4rem",
      };

      if (!canBypassUsageLimits && hasExceededMaxProjects) {
        return (
          <Box {...commonStyles}>
            {`You can only have ${maxAllowedProjects} active ${currentCollectionType?.replace(/_/g, " ")} projects at a time`}
          </Box>
        );
      }

      return null;
    }, [canBypassUsageLimits, hasExceededMaxProjects, maxAllowedProjects, currentCollectionType]);

    const handleChange = useCallback((value: string) => {
      setQuestionText(value);
    }, []);

    const handleSelectOption = useCallback(
      (selectedQuestion: SuggestedQuestion) => {
        const { question, focus, matchFilter, type } = selectedQuestion;
        if (workflowStatus === "clarification_needed" || projectId || questionText.startsWith("/")) {
          if (focus && ["analytical", "sentiment"].includes(focus)) {
            handleUpdateSourceWeightPreference(focus === "analytical" ? 0 : 1);
          }
          setQuestionText(question);
        } else {
          const stockExchange = focus && ["analytical", "sentiment"].includes(focus) ? undefined : focus;
          matchFilter &&
            onSubmitDueDiligenceProject(
              matchFilter[0].toUpperCase(),
              stockExchange,
              afterSubmit,
              undefined,
              portfolioId,
              type,
              undefined,
              undefined,
              showToast
            );
          setQuestionText("");
        }
      },
      [
        workflowStatus,
        projectId,
        questionText,
        handleUpdateSourceWeightPreference,
        onSubmitDueDiligenceProject,
        afterSubmit,
        portfolioId,
        showToast,
      ]
    );

    const handleClickSubmit = useCallback(
      (selectedQuestion?: SuggestedQuestion) => {
        const { question, focus, matchFilter } = selectedQuestion || {};
        const currentQuestion = question || questionText.trim();
        const maybeTickerFromMatchFilter = matchFilter && matchFilter[0] && matchFilter[0].toUpperCase();

        if (isInputDisabled) return;
        currentSubmitFunction(currentQuestion.startsWith("/") ? currentQuestion : maybeTickerFromMatchFilter ?? currentQuestion, focus);
        setQuestionText("");
      },
      [questionText, isInputDisabled, currentSubmitFunction]
    );

    const handleFocus = useCallback(() => {
      setFocusedInputId(inputId);
    }, [inputId, setFocusedInputId]);

    const handleBlur = useCallback(() => {
      isConversationOpen && setFocusedInputId(isConversationOpen ? "conversation-input" : "view-input");
    }, [isConversationOpen, setFocusedInputId]);
    const disabledBg = useColorModeValue("whiteAlpha.700", "blackAlpha.400");

    return (
      <Box position="relative">
        {isConversationOpen && !projectId && (
          <Box pl=".5rem" bottom="3rem" zIndex={2} position="absolute" width="calc(100% - 5rem)" ml="2.5rem" mr="3rem">
            {errorMessage}
          </Box>
        )}
        <Box position="relative">
          <Box bg="transparent" borderRadius={"30px 30px 30px 30px!important"} border="none" minWidth={minWidth || "33rem"}>
            <AutocompleteInput
              inputId={inputId}
              externalInputRef={inputRef}
              className={className}
              minWidthList={minWidthList || "22rem"}
              marginLeftList={marginLeftList}
              conversationId={conversationId || currentConversationID}
              value={isInputDisabled ? "" : questionText}
              onChange={handleChange}
              onReset={onResetQuestion}
              initialText={workflowStatus === "clarification_needed" ? "Respond to Charli..." : initialText}
              suggestedQuestions={workflowStatus === "clarification_needed" || isInputDisabled ? [] : currentSuggestionList}
              suggestionListType={disableComponent ? undefined : currentSuggestionListType}
              isInputDisabled={isInputDisabled}
              isSubmitDisabled={isInputDisabled}
              onSelectOption={handleSelectOption}
              onClickSubmit={handleClickSubmit}
              isLoading={isLoading}
              conversationState={workflowStatus}
              canBypassDisabled={canBypassDisabled}
              setCanBypassDisabled={setCanBypassDisabled}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disableFilter={currentSuggestionListType === "tickers"}
              defaultIsOpen={defaultIsOpen}
              disableComponent={disableComponent}
              hasBoxShadow={hasBoxShadow}
            />
          </Box>
          {disableComponent && (
            <Tooltip label={tooltip || "Input disabled"} maxWidth={"15rem"} textAlign={"center"}>
              <Box
                position="absolute"
                className="ch-input-disabled-overlay"
                top={"-10px"}
                left={"-6px"}
                right={"-6px"}
                bottom={"-9px"}
                bg={disabledBg}
                opacity={0.7}
                borderRadius="30px"
                cursor="not-allowed"
                zIndex={1}
              />
            </Tooltip>
          )}
        </Box>
        {projectId && !pathname.includes("/panel") && (
          <Box height="1rem" width="100%" mt="5px">
            {!isConversationOpen && currentWorkflowId && workflowStatus === "in_progress" ? (
              <Box pr="1rem" pl="10rem" justifyContent="end" width="100%">
                <WorkflowProgressBar
                  workflowId={currentWorkflowId}
                  lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
                    addSuffix: true,
                    includeSeconds: false,
                  })}
                  size="compact"
                  showInline={false}
                />
              </Box>
            ) : answerFocusOptions ? (
              <Flex justifyContent="flex-end">
                <SemanticFocusedAnswer
                  isDisabled={currentSuggestionListType === "commands" || disableComponent}
                  answerFocusOptions={answerFocusOptions}
                  selectedSourceWeightsIndex={selectedSourceWeightsIndex}
                  onSourceWeightPreferenceChange={(index) => handleUpdateSourceWeightPreference(index)}
                />
              </Flex>
            ) : null}
          </Box>
        )}
        {!isConversationOpen && !projectId && (
          <Box ml="3rem" position="absolute" width="30rem" boxShadow="xl">
            {errorMessage}
          </Box>
        )}
      </Box>
    );
  }
);

export const ConversationDialogInput: React.FC<Props> = React.memo(({ disableComponent, tooltip, showToast, ...props }) => {
  const { projectId } = useProjectParams();

  return (
    <ProjectQuestionsProvider collectionId={projectId}>
      <ConversationDialogInputInner disableComponent={disableComponent} tooltip={tooltip} showToast={showToast} {...props} />
    </ProjectQuestionsProvider>
  );
});
