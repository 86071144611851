import { createAction, createSlice } from "@reduxjs/toolkit";
import uniq from "lodash/uniq";
import orderBy from "lodash/orderBy";
import type { PersistedState } from "redux-persist";
import { REHYDRATE } from "redux-persist";
import type { Collection } from "types/collection";
import {
  downloadCollections,
  downloadCollectionsByGroup,
  downloadCollectionsByPortfolio,
  downloadCollectionsByType,
  removeCollection,
} from "./operations";

const MAX_RECENT_COLLECTIONS = 15;

const rehydrate = createAction<CollectionState>(REHYDRATE);

const storeDownloadedCollections = (state: CollectionState, payload: Collection[]) => {
  state.isLoading = false;

  payload.forEach((collection) => {
    if (state.deletedCollectionIds.includes(collection.id)) {
      return;
    }

    if (!state.collections[collection.id]) {
      const currentTotalByType = state.collectionsByTypeCount[collection.collectionType];
      state.collectionsByTypeCount[collection.collectionType] = (currentTotalByType ?? 0) + 1;

      state.order.push(collection.id);
    }

    const { questions, ...restOfCollection } = collection;

    state.collections[collection.id] = restOfCollection;
  });

  const orderedCollectionsIds = orderBy(state.order, (id) => state.collections[id].metadata.createdTime, "desc");
  state.order = orderedCollectionsIds;

  const newRecentCollectionsIds = state.order
    .flatMap((id) => (state.collections[id].collectionType !== "portfolio" ? [id] : []))
    .slice(0, MAX_RECENT_COLLECTIONS);

  const collectionsToRemove = state.recentCollectionsIds.filter((id) => !newRecentCollectionsIds.includes(id));

  // This state won't be used across the app,
  // but it's used to keep the recent collections in the state
  // and rehydrate collections state with them when the app is reloaded
  state.recentCollectionsIds = newRecentCollectionsIds;
  newRecentCollectionsIds.forEach((id) => {
    state.recentCollections[id] = state.collections[id];
  });

  collectionsToRemove.forEach((id) => {
    delete state.recentCollections[id];
  });
};

export type PersistedCollectionState = PersistedState & CollectionState;

interface CollectionState {
  isLoading: boolean;
  order: string[];
  collections: { [key: string]: Collection };
  collectionsByTypeCount: Record<string, number | undefined>;
  deletedCollectionIds: string[];
  hasInitialSyncCompleted: boolean;
  collectionsIdsByGroup: Record<string, string[]>;
  recentCollections: Record<string, Collection>;
  recentCollectionsIds: string[];
  arePortfolioProjectsLoaded: Record<string, boolean>;
}

const initialState: CollectionState = {
  isLoading: true,
  order: [],
  collections: {},
  collectionsByTypeCount: {},
  deletedCollectionIds: [],
  hasInitialSyncCompleted: false,
  collectionsIdsByGroup: {},
  recentCollections: {},
  recentCollectionsIds: [],
  arePortfolioProjectsLoaded: {},
};

export const { actions, reducer } = createSlice({
  name: "collection",
  initialState,
  reducers: {
    flush: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrate, (_, action) => {
      if (action.payload?.deletedCollectionIds !== undefined) {
        return {
          ...initialState,
          collections: action.payload.recentCollections ?? {},
          order: action.payload.recentCollectionsIds ?? [],
          deletedCollectionIds: action.payload.deletedCollectionIds,
          isLoading: false,
          hasInitialSyncCompleted: action.payload.hasInitialSyncCompleted,
          collectionsByTypeCount: action.payload.collectionsByTypeCount ?? {},
          collectionsIdsByGroup: action.payload.collectionsIdsByGroup || {},
          recentCollections: action.payload.recentCollections ?? {},
          recentCollectionsIds: action.payload.recentCollectionsIds ?? [],
        };
      }
    });

    builder.addCase(removeCollection.fulfilled, (state, action) => {
      const id = action.payload;

      if (id !== undefined) {
        state.order = state.order.filter((collectionId) => collectionId !== id);
        const collectionType = state.collections[id] && state.collections[id].collectionType;
        const groupId = state.collections[id] && state.collections[id].projectGroupId;
        const currentTotalByType = state.collectionsByTypeCount[collectionType];

        if (collectionType && currentTotalByType && currentTotalByType > 0) {
          state.collectionsByTypeCount[collectionType] = currentTotalByType - 1;
        }

        if (groupId && state.collectionsIdsByGroup[groupId]) {
          state.collectionsIdsByGroup[groupId] = state.collectionsIdsByGroup[groupId].filter((collectionId) => collectionId !== id);
        }

        delete state.collections[id];
        state.deletedCollectionIds = uniq([...state.deletedCollectionIds, id]);
      }
    });

    builder.addCase(downloadCollectionsByType.rejected, (state, action) => {
      state.collectionsByTypeCount[action.meta.arg.collectionType] = 0;
    });
    builder.addCase(downloadCollectionsByType.fulfilled, (state, action) => {
      state.collectionsByTypeCount[action.meta.arg.collectionType] = action.payload.totalCount;
    });

    builder.addCase(downloadCollections.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadCollections.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadCollections.fulfilled, (state, action) => {
      storeDownloadedCollections(state, action.payload);

      state.hasInitialSyncCompleted = true;
    });

    builder.addCase(downloadCollectionsByPortfolio.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadCollectionsByPortfolio.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadCollectionsByPortfolio.fulfilled, (state, action) => {
      state.arePortfolioProjectsLoaded[action.meta.arg.portfolioId] = true;

      storeDownloadedCollections(state, action.payload);
    });

    builder.addCase(downloadCollectionsByGroup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadCollectionsByGroup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadCollectionsByGroup.fulfilled, (state, action) => {
      state.collectionsIdsByGroup[action.meta.arg.groupId] = action.payload.map((collection) => collection.id);

      storeDownloadedCollections(state, action.payload);
    });
  },
});

export default reducer;
