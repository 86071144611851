import type { FunctionComponent } from "react";
import React, { useState } from "react";
import { Box, Stack } from "@chakra-ui/react";
import { MarkupTextViewer } from "screens/content/contentView/sections/MarkupTextViewer";

interface Props {
  message: string;
  moreDetails?: string;
  icon?: React.ReactNode;
  fontSize?: string;
}

export const MessageContent: FunctionComponent<Props> = ({ message, moreDetails, icon, fontSize }) => {
  const [showMoreDetails, setShowMoreDetails] = useState(false);

  return (
    <Stack fontSize={fontSize} direction={"column"} spacing="0.5rem" display="flex">
      <Stack spacing={"1.5rem"} direction="row" justifyContent={"space-between"} alignItems="center" width="100%">
        <Box>{message}</Box>
        {icon && <>{icon}</>}
      </Stack>
      {moreDetails && (
        <Stack spacing="0.5rem" direction="column">
          {showMoreDetails && <MarkupTextViewer markupText={moreDetails} maxHeight="none" fontSize={fontSize} />}
          <Box
            fontSize={"xs"}
            textAlign={"left"}
            as="button"
            onClick={(e) => {
              e.stopPropagation();
              setShowMoreDetails(!showMoreDetails);
            }}>
            {showMoreDetails ? "Show less" : "Show more"}
          </Box>
        </Stack>
      )}
    </Stack>
  );
};
