import { Stack, Text, Center, Box, useBreakpointValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React, { useCallback, useEffect } from "react";
import { useCollectionKey, useConfigMap, useGetViewConfig, useProjectParams } from "hooks";
import { CollectionAddNewButton } from "../library/CollectionAddNewButton";
import { CharliLogoCircle } from "screens/thread/CharliLogoCircle";
import { AddNewMenu } from "screens/landing/components/AddNewMenu";
import { useLocation } from "react-router-dom";
import { ConversationDialogInput } from "screens/thread/ConversationDialogInput";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useCopyValue } from "hooks/useCopies";

interface Props {
  collectionType?: string;
  collectionId?: string;
}

export const ProjectNewInput: FunctionComponent<Props> = ({ collectionType, collectionId }) => {
  const { projectId } = useProjectParams();
  const configMap = useConfigMap();
  const showQAinputProjectLanding = useGetViewConfig("projectLandingQAInput", collectionType, configMap);
  const showQAinputProjectLandingShowInput = showQAinputProjectLanding?.showInput || false;
  const showQAinputProjectLandingSource = showQAinputProjectLanding?.source || false;
  const showQAinputProjectDetail = useGetViewConfig("projectDetailQAInput", collectionType, configMap)?.showInput || false;
  const { pathname } = useLocation();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { setIsUsingDefaultConversationDialog } = useConversationContext();
  const copyTickerPlaceholder = useCopyValue("copy_ticker_placeholder_text");
  const shareDetails = useCollectionKey(projectId, "shareDetails");

  useEffect(() => {
    const isHomePage = pathname.includes("/home");
    const isDueDiligence = collectionType === "due_diligence";
    const isLandingOrPanel = !collectionId || pathname.includes("/panel");
    const showLandingInput = showQAinputProjectLandingShowInput && showQAinputProjectLandingSource;

    const shouldUseDefaultDialog = !(
      isHomePage ||
      (isDueDiligence && ((showLandingInput && isLandingOrPanel) || (showQAinputProjectDetail && collectionId)))
    );

    setIsUsingDefaultConversationDialog(shouldUseDefaultDialog);
  }, [
    pathname,
    collectionType,
    collectionId,
    showQAinputProjectLandingShowInput,
    showQAinputProjectLandingSource,
    showQAinputProjectDetail,
    setIsUsingDefaultConversationDialog,
  ]);

  const renderConversationDialogInput = useCallback(
    () => (
      <Box width="100%" maxWidth="33rem">
        <ConversationDialogInput
          disableComponent={shareDetails?.accessMode === "read"}
          tooltip={shareDetails?.accessMode === "read" ? "Questions can not be asked in shared projects" : undefined}
          minWidth="unset"
          inputId={"view-input"}
          className="ch-question-input"
          initialText={projectId && !pathname.includes("/panel") ? "Ask me a question about this project" : copyTickerPlaceholder}
          renderRole="embedded"
        />
      </Box>
    ),
    [copyTickerPlaceholder, pathname, projectId, shareDetails?.accessMode]
  );

  const renderNewProjectButton = useCallback(
    () => (
      <Stack width={isMobile ? "unset" : "100%"} direction="row" spacing="1rem" justifyContent={"space-between"} maxWidth={"33rem"}>
        <Stack direction={"row"} spacing="1rem" pl="1px">
          <CharliLogoCircle className="project-conversation-button" style={{ zIndex: 10 }} />
          {!isMobile && (
            <Center>
              <Text fontSize={"md"}>Click on the button to start a project...</Text>
            </Center>
          )}
        </Stack>
        <Center>
          {collectionType === "all" ? (
            <Box>
              <AddNewMenu buttonLabel="New Project" className="add-new-menu-home" showProjectsOnly buttonType="secondary" />
            </Box>
          ) : (
            <CollectionAddNewButton collectionType={collectionType || ""} />
          )}
        </Center>
      </Stack>
    ),
    [collectionType, isMobile]
  );

  const renderInput = useCallback(() => {
    const isHomePage = pathname.includes("/home");
    const isDueDiligence = collectionType === "due_diligence";
    const isLandingOrPanel = !collectionId || pathname.includes("/panel");
    const showLandingInput = showQAinputProjectLandingShowInput && showQAinputProjectLandingSource;

    if (isHomePage || (isDueDiligence && ((showLandingInput && isLandingOrPanel) || (showQAinputProjectDetail && collectionId)))) {
      return renderConversationDialogInput();
    }

    if (collectionId && !showQAinputProjectDetail) {
      return null;
    }

    return renderNewProjectButton();
  }, [
    pathname,
    collectionType,
    collectionId,
    showQAinputProjectLandingShowInput,
    showQAinputProjectLandingSource,
    showQAinputProjectDetail,
    renderConversationDialogInput,
    renderNewProjectButton,
  ]);

  return <>{renderInput()}</>;
};
