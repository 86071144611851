import type { SuggestedQuestions } from "api/suggestions";
import { useCallback, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import type { Source } from "types/config";
import { getTickersQuery } from "api/tickers";
import orderBy from "lodash/orderBy";
import { useEntitlementKey } from "./useEntitlements";
import { useCopyValue } from "./useCopies";

export const qaSourcesMap: Record<string, { label: string; value: Source }> = {
  tickers: {
    label: "Tickers list",
    value: "tickers",
  },
  questions: {
    label: "Questions list",
    value: "questions",
  },
};

interface QueryParams {
  limit?: number;
  query?: string;
}

export const useQASourceListFunction = (source: Source | undefined): ((params: QueryParams) => Promise<SuggestedQuestions>) => {
  const questionsData = useSelector((state: RootState) => state.suggestedQuestions.questionData, shallowEqual);
  const showAdditionalTickers = useEntitlementKey("ui_show_additional_security_types");
  const showAdditionalExchanges = useEntitlementKey("ui_show_additional_exchanges");
  const excludedExchanges = useCopyValue("copy_ticker_excluded_exchanges") as string[];
  const excludedTypes = useCopyValue("copy_ticker_excluded_types") as string[];
  const typeOrder = useCopyValue("copy_ticker_type_order") as string[];

  const getTickers = useCallback(
    async (params: QueryParams) => {
      try {
        const { limit, query } = params;
        const data = await getTickersQuery({
          limit,
          query,
          ...(!showAdditionalTickers && { excludedType: excludedTypes }),
          ...(!showAdditionalExchanges && { excludedExchanges: excludedExchanges }),
        });
        // First sort by query match
        let sortedData = orderBy(
          data,
          [(tickerData) => (query ? tickerData.symbol.toLowerCase() === query.toLowerCase() : false)],
          ["desc"]
        );

        // Then sort by type order and symbol
        sortedData = orderBy(
          sortedData,
          [
            // First by type order (if type is in the order list)
            (tickerData) => {
              const type = tickerData.type ?? "";
              const index = typeOrder.indexOf(type);
              return index === -1 ? typeOrder.length : index;
            },
            // Then by symbol
            (tickerData) => tickerData.symbol.toLowerCase(),
          ],
          ["asc", "asc"]
        );

        return sortedData.map((tickerData) => ({
          question: `${tickerData.name} | (${tickerData.symbol})`,
          matchFilter: [tickerData.symbol.toLowerCase(), tickerData.name.toLowerCase()],
          focus: tickerData.exchange,
          type: tickerData.type,
          status: tickerData.status,
        }));
      } catch (error) {
        console.error("Error fetching tickers:", error);
        return [];
      }
    },
    [showAdditionalTickers, excludedTypes, showAdditionalExchanges, excludedExchanges, typeOrder]
  );

  const getQuestions = useCallback(
    async (_params: QueryParams) => {
      try {
        return questionsData;
      } catch (error) {
        console.error("Error fetching questions:", error);
        return [];
      }
    },
    [questionsData]
  );

  return useMemo(() => {
    if (source === "tickers") {
      return getTickers;
    } else if (source === "questions") {
      return getQuestions;
    } else {
      return () => Promise.resolve([]);
    }
  }, [getQuestions, getTickers, source]);
};
