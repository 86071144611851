import React, { useState, useEffect } from "react";
import { Box, Icon, useBreakpointValue, useColorModeValue, usePrevious, useToast } from "@chakra-ui/react";
import { IoCloudOffline } from "react-icons/io5";
import { ToastMessageContent } from "screens/common/components/ToastMessageContent";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import { actions as websocketActions } from "state/websocket/reducer";
import { useUserPreference, useUserProfile } from "hooks";
import { useRefreshCollections, useRefreshPortfolioProjects } from "hooks/useCollections";
import { track } from "api/analytics";
import { WEBSOCKET_DISCONNECTED, WEBSOCKET_REESTABLISHED } from "api/analytics/events";
import { useLocation } from "react-router-dom";
import { getEnvironment } from "screens/common/app";

// Only warn user there has been a disconnection once meaningful time has elapsed.
// Will remain silent about disconnection and reconnection when below threshold.
const DISCONNECTION_TIME_THRESHOLD_TO_SHOW_MESSAGE = 5000; // 5 seconds
const DISCONNECTION_TIME_THRESHOLD_TO_TRACK = 60000; // 1 minute
const RECONNECTION_MESSAGE_DURATION = 4000; // 4 seconds

const DISCONNECTED_TOAST_KEY = "disconnected-toast-key";
const CONNECTED_TOAST_KEY = "connected-toast-key";

export const WebsocketNotifier = () => {
  const isWebsocketConnected = useSelector((state: RootState) => state.websocket.isConnected);
  const didWebsocketPreviouslyConnect = usePrevious(isWebsocketConnected);
  const [showToast, setShowToast] = useState(false);
  const [hasTrackedDisconnection, setHasTrackedDisconnection] = useState(false);
  const toast = useToast();
  const primaryTextColor = useColorModeValue("gray.700", "gray.300");
  const bgColorDisconnect = useColorModeValue("#f9e4dc", "gray.800");
  const bgColor = useColorModeValue("#cbe0d0", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.900");
  const { email, fullName } = useUserProfile();
  const location = useLocation();
  const refreshCollections = useRefreshCollections();
  const refreshPortfolioProjects = useRefreshPortfolioProjects();
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");
  const dispatch = useDispatch();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  useEffect(() => {
    // Check initial online state
    if (!navigator.onLine) {
      dispatch(websocketActions.disconnect({ userInitiated: false }));
      setShowToast(true);
    }

    const handleOnline = () => {
      track(WEBSOCKET_REESTABLISHED, { userName: fullName, email, environment: getEnvironment().label });
    };

    const handleOffline = () => {
      track(WEBSOCKET_DISCONNECTED, { userName: fullName, email, environment: getEnvironment().label });
      dispatch(websocketActions.disconnect({ userInitiated: false }));
      toast.close(DISCONNECTED_TOAST_KEY);
      toast.close(CONNECTED_TOAST_KEY);
      setShowToast(true);
    };

    if (injectDebugEntityPreference) {
      console.log("Websocket connected: ", {
        "Websocket connected": isWebsocketConnected,
        "Did previously connect": didWebsocketPreviouslyConnect,
        "Browser online": navigator.onLine,
      });
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [injectDebugEntityPreference, isWebsocketConnected, didWebsocketPreviouslyConnect, toast, dispatch, fullName, email]);

  // Handle collection refresh when tab becomes active or websocket reconnects
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        refreshCollections();
        refreshPortfolioProjects();
      }
    };

    if (isWebsocketConnected && didWebsocketPreviouslyConnect === false) {
      refreshCollections();
      refreshPortfolioProjects();
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isWebsocketConnected, didWebsocketPreviouslyConnect, refreshCollections, refreshPortfolioProjects, location.pathname]);

  useEffect(() => {
    // If the websocket disconnects set a timer so that if it stays disconnected for a meaningful time it can be later reported.
    if (isWebsocketConnected === false && !showToast) {
      const timeout = setTimeout(() => {
        setShowToast(true);
      }, DISCONNECTION_TIME_THRESHOLD_TO_SHOW_MESSAGE);
      return () => clearTimeout(timeout);
    }
  }, [isWebsocketConnected, didWebsocketPreviouslyConnect, showToast]);

  // Track disconnection if it lasts for a meaningful time duration
  useEffect(() => {
    let disconnectionTimeout: NodeJS.Timeout;

    if (isWebsocketConnected === false && didWebsocketPreviouslyConnect && !hasTrackedDisconnection) {
      disconnectionTimeout = setTimeout(() => {
        setHasTrackedDisconnection(true);
      }, DISCONNECTION_TIME_THRESHOLD_TO_TRACK);
    }

    // Reset tracking state when reconnected
    if (isWebsocketConnected && hasTrackedDisconnection) {
      setHasTrackedDisconnection(false);
    }

    return () => {
      if (disconnectionTimeout) {
        clearTimeout(disconnectionTimeout);
      }
    };
  }, [isWebsocketConnected, didWebsocketPreviouslyConnect, hasTrackedDisconnection, fullName, email]);

  useEffect(() => {
    // Don't do anything if we haven't been disconnected for a meaningful time duration.
    if (!showToast) return;

    // Notify user they have been disconnected
    if (!isWebsocketConnected) {
      toast.close(DISCONNECTED_TOAST_KEY);
      toast.close(CONNECTED_TOAST_KEY);

      toast({
        id: DISCONNECTED_TOAST_KEY,
        render: () => (
          <ToastMessageContent
            message="Charli is trying to connect, requests can not be sent yet."
            backgroundColor={bgColorDisconnect}
            showCloseButton={false}
            icon={<Icon as={IoCloudOffline} color="gray.600" boxSize="1.8rem" />}
            maxWidth={isMobile ? "100%" : "22rem"}
            moreDetails="Charli relies on standard and secure WebSockets for communication with the cloud services and the AI system. <br>Charli cannot currently connect with the cloud services, and this might be blocked due to your network firewall policies or ad blockers in your browser. Please check with your IT organization or network administrator to have WebSockets enabled for charliai.com.<br>You can also contact our <a href='https://support.charli.ai/hc/en-us/articles/33739429556877--Charli-is-trying-to-connect-information-message' target='_blank'>Customer Service</a> team for troubleshooting."
          />
        ),
        duration: null,
        isClosable: false,
        position: "top-right",
      });
    }

    // Notify user when connection is re-established
    if (isWebsocketConnected && didWebsocketPreviouslyConnect === false) {
      toast.close(DISCONNECTED_TOAST_KEY);
      toast.close(CONNECTED_TOAST_KEY);
      toast({
        render: ({ onClose }) => (
          <Box
            mb=".5rem"
            boxShadow={"lg"}
            cursor={"pointer"}
            borderColor={borderColor}
            onClick={onClose}
            fontSize="xs"
            py="5px"
            px="10px"
            color={primaryTextColor}
            backgroundColor={bgColor}
            borderRadius="full">
            Connection re-established with Charli.
          </Box>
        ),
        duration: RECONNECTION_MESSAGE_DURATION,
        isClosable: true,
        position: "bottom",
        containerStyle: {
          minWidth: "unset",
        },
      });

      // If we get disconnected again we want the disconnection to last for a meaningful time duration before we notify the user
      setShowToast(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWebsocketConnected, didWebsocketPreviouslyConnect, toast, showToast]);

  return <React.Fragment />;
};
