import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useButtonProps } from "hooks";
import React, { useEffect } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import * as yup from "yup";
import type { CopyWithAdditionalProps } from "..";
import { copyPreviews } from "types/copy";
import { CopiesFieldArray } from "./CopiesFieldArray";

export interface UpsertCopyForm {
  key: string;
  type: "string" | "list";
  stringValue?: string;
  listValue?: { value: string }[];
}

interface IProps {
  onSubmit: (values: UpsertCopyForm) => void;
  isLoading?: boolean;
  initialValue?: CopyWithAdditionalProps;
}

interface IPanelProps extends IProps {
  onClose: () => void;
  isOpen: boolean;
}

const validationSchema = yup.object().shape({
  key: yup.string().required("Key is required"),
  type: yup.string().oneOf(["string", "list"]).required("Type is required"),
  stringValue: yup.string().optional(),
  listValue: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().default("").required("Value is required"),
      })
    )
    .optional()
    .default([]),
});

const UpsertCopy = ({ onSubmit, isLoading, initialValue, onClose }: IPanelProps) => {
  const commonButtonProps = useButtonProps("sm", "primary");

  const methods = useForm<UpsertCopyForm>({
    defaultValues: initialValue
      ? {
          key: initialValue.key,
          ...(typeof initialValue.value === "string"
            ? {
                type: "string",
                stringValue: initialValue.value,
                listValue: undefined,
              }
            : {
                type: "list",
                stringValue: undefined,
                listValue: initialValue.value.map((value) => ({ value })),
              }),
        }
      : {
          key: "",
          type: "string",
          stringValue: "",
          listValue: [],
        },
    resolver: yupResolver<UpsertCopyForm>(validationSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = methods;

  const watchType = useWatch({ control, name: "type" });

  useEffect(() => {
    if (watchType === "string") {
      setValue("listValue", []);
    } else {
      setValue("stringValue", "");
    }
  }, [watchType, setValue]);

  const PreviewComponent = initialValue ? copyPreviews[initialValue.key] : undefined;

  return (
    <Box pb="5rem">
      {PreviewComponent && (
        <>
          <Text fontSize="sm" color="gray.600" mb={2}>
            Preview:
          </Text>
          <PreviewComponent
            value={watchType === "string" ? methods.watch("stringValue") : methods.watch("listValue")?.map((v) => v.value) || []}
          />
        </>
      )}
      <FormProvider {...methods}>
        <form id="upsert-copy-form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="key"
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <FormControl display={"none"} isInvalid={errors.key !== undefined} pb="1rem">
                <FormLabel htmlFor={field.name}>Key</FormLabel>
                <Input
                  isDisabled={!!initialValue}
                  {...field}
                  onChange={(evt) =>
                    onChange(
                      evt.target.value
                        .trim()
                        .normalize()
                        .replace(/[^\w-]+/g, "_")
                        .toLowerCase()
                    )
                  }
                  size="sm"
                  type="text"
                />
                {errors.key && <FormErrorMessage>{errors.key.message}</FormErrorMessage>}
              </FormControl>
            )}
          />

          {!initialValue && (
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <FormControl pb="1rem">
                  <FormLabel htmlFor={field.name}>Type</FormLabel>
                  <Select {...field} size="sm">
                    <option value="string">String</option>
                    <option value="list">List</option>
                  </Select>
                </FormControl>
              )}
            />
          )}

          {watchType === "string" ? (
            <Controller
              name="stringValue"
              control={control}
              render={({ field }) => (
                <FormControl isInvalid={errors.stringValue !== undefined} pb="1rem">
                  <FormLabel htmlFor={field.name}>Value</FormLabel>
                  <Input {...field} placeholder="Write copy here..." size="sm" type="text" />
                  {errors.stringValue && <FormErrorMessage>{errors.stringValue.message}</FormErrorMessage>}
                </FormControl>
              )}
            />
          ) : (
            <CopiesFieldArray />
          )}
        </form>
      </FormProvider>

      <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
        <Button isLoading={isLoading} {...commonButtonProps} type="submit" form="upsert-copy-form">
          Save Preference
        </Button>
      </Box>
    </Box>
  );
};

export const UpsertCopyModal = ({ isOpen, onClose, ...rest }: IPanelProps) => {
  return (
    <PanelView
      isOpen={isOpen}
      onClose={onClose}
      panelTitle={rest.initialValue ? `Edit ${rest.initialValue.key}` : "Create Delivery Preference"}>
      <Wizard>
        <PanelStep>
          <Box pt="1rem">{isOpen && <UpsertCopy isOpen={isOpen} onClose={onClose} {...rest} />}</Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
