export enum AnswerStatus {
  pinned = "pinned",
  unpinned = "unpinned",
  removed = "removed",
  pending = "pending_review",
}

export interface Question {
  id: string;
  question: string;
  collectionId: string;
  creationDate: Date;
  initiator: "user" | "charli" | "system";
  status: AnswerStatus;
  answers: Answer[];
  focus: string | null;
}

export interface Answer {
  id: string;
  answer: string;
  nodeId?: string;
  chunkId: string;
  confidence: number;
  answerStatus: AnswerStatus;
  metadataIds: string[];
  creationDate: Date;
}
