import React, { useCallback, useContext, useMemo } from "react";
import { Box, Button, Stack, useColorModeValue, Text, useBreakpointValue } from "@chakra-ui/react";
import type { FunctionComponent, ReactNode } from "react";
import { ContentView } from "./sections/ContentView";
import { PanelView } from "screens/panels/components/PanelView";
import { useButtonProps, useContentDetails, useProjectParams } from "hooks";
import { ContentHeader } from "../contentCanvas/header/ContentHeader";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ConversationContext } from "screens/thread/ConversationContext";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { ContentFilterContext } from "../contentCanvas/body/ContentFilterContext";
import { getSortedContentCells } from "../common/utils";
import { hasProjectOutput } from "screens/collection/components/utils";

const ContentViewPanelInner: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  const { parentRoute, contentId: routeContentId } = useProjectParams();
  const { availableContent } = useContext(ContentFilterContext);
  const { setSelectedContentId, selectedContentId } = useContext(ConversationContext);
  const contentId = selectedContentId || routeContentId || "";
  const getContentDetails = useContentDetails(selectedContentId || contentId);
  const { onContentViewClose, isContentViewOpen } = useAddToCharliContext();
  const { setRequestEntities, setConversationId } = useContext(ConversationContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const buttonSize = isMobile ? "xs" : "sm";
  const commonButtonProps = useButtonProps(buttonSize, "secondary");
  const backgroundColor = useColorModeValue("white!important", "#191f23");

  const contentToRender = useMemo(() => {
    return getSortedContentCells(availableContent, "Date", "DESC");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableContent]);

  const contentDataIds = useMemo(
    () => contentToRender.filter((content) => !hasProjectOutput(content))?.map((content) => content.id),
    [contentToRender]
  );

  const handleClose = useCallback(() => {
    setRequestEntities([]);
    onContentViewClose();
    setConversationId("");
    setSelectedContentId(undefined);
  }, [setRequestEntities, onContentViewClose, setConversationId, setSelectedContentId]);

  const handleOnNavChange = useCallback(
    (direction: "previous" | "next", currentIndex: any, action: "nav" | "name" | "index") => {
      const currentIndexInContentDataIds = contentDataIds?.indexOf(currentIndex);
      const newContentId =
        direction === "previous" ? contentDataIds?.[currentIndexInContentDataIds - 1] : contentDataIds?.[currentIndexInContentDataIds + 1];
      if (newContentId && action === "nav") {
        setSelectedContentId(newContentId);
      } else if (newContentId && action === "name") {
        return contentToRender.filter((content) => content.id === newContentId)[0].name
          ? contentToRender.filter((content) => content.id === newContentId)[0].name
          : direction === "previous"
          ? "Previous Item"
          : "Next Item";
      } else if (newContentId && action === "index") {
        return newContentId;
      }
    },
    [contentDataIds, contentToRender, setSelectedContentId]
  );

  const panelFooter: ReactNode = useMemo(
    () => (
      <Stack
        {...(!isMobile && { direction: "row" })}
        justifyContent={handleOnNavChange("previous", contentId, "index") ? "space-between" : "flex-end"}
        width="100%"
        bgColor={backgroundColor}>
        {handleOnNavChange("previous", contentId, "index") && (
          <Button
            {...commonButtonProps}
            leftIcon={<ChevronLeftIcon boxSize={"1rem"} />}
            maxWidth={"15rem"}
            id="publish-button"
            aria-label="previous-step"
            onClick={() => handleOnNavChange("previous", contentId, "nav")}>
            <Text isTruncated>{handleOnNavChange("previous", contentId, "name")}</Text>
          </Button>
        )}
        {handleOnNavChange("next", contentId, "index") && (
          <Button
            {...commonButtonProps}
            rightIcon={<ChevronRightIcon boxSize={"1rem"} />}
            maxWidth={"15rem"}
            id="publish-button"
            aria-label="previous-step"
            onClick={() => handleOnNavChange("next", contentId, "nav")}>
            <Text isTruncated>{handleOnNavChange("next", contentId, "name")}</Text>
          </Button>
        )}
      </Stack>
    ),
    [isMobile, backgroundColor, commonButtonProps, handleOnNavChange, contentId]
  );

  let decodedName: string;
  try {
    decodedName = decodeURIComponent(getContentDetails?.name || "");
  } catch (error) {
    decodedName = "Resource Item";
  }

  return (
    <PanelView
      isOpen={isContentViewOpen}
      onClose={handleClose}
      panelTitle={decodedName}
      panelMenuBar={<ContentHeader itemContent={getContentDetails} showActionMenu showAddToCollection={parentRoute === "search"} />}
      panelFooter={panelFooter}>
      <Box width="100%" height="100%">
        <ContentView isPanel />
      </Box>
    </PanelView>
  );
};

export const ContentViewPanel: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  const { isContentViewOpen } = useAddToCharliContext();

  if (isContentViewOpen) {
    return <ContentViewPanelInner />;
  } else {
    return <React.Fragment />;
  }
};
