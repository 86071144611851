import type { Static } from "runtypes";
import { Record, String, Array, Dictionary, Number, Union } from "runtypes";

// Define field record type
const Field = Record({
  name: String,
  type: String,
});

// Define schema type
const Schema = Record({
  fields: Array(Field),
});

// Define data record type using Dictionary for dynamic keys
const DataRecord = Dictionary(Union(String, Number));

export const TableContent = Record({
  title: String.optional(),
  schema: Schema,
  data: Array(DataRecord),
  label: String.optional(),
  recordId: String.optional(),
  recordEntity: String.optional(),
  confidence: Number.optional(),
});

export type TableContent = Static<typeof TableContent>;
