const TYPE_LABELS: Record<string, string> = {
  equity: "Equity",
  fixed_income: "Fixed Income",
  cash: "Cash and cash equivalents",
  mutual_funds: "Mutual Funds",
  index_funds: "Index Funds",
  real_estate: "Real Estate",
  commodities: "Commodities",
  currencies: "Currencies",
  etf: "ETF",
  crypto: "Crypto Currencies",
};

export const getTickerTypeOption = (type: string): { value: string; label: string } => {
  // If we have a predefined label, use it, otherwise use the type as both value and label
  const label = TYPE_LABELS[type] ?? type;
  return { value: type, label };
};
