import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Stack,
  useBreakpointValue,
  useColorModeValue,
  Icon,
  Text,
  Center,
  useTheme,
  useColorMode,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Portal,
  useToast,
} from "@chakra-ui/react";
import { useTileProps } from "hooks";
import type { FunctionComponent } from "react";
import { IoCalendarClearOutline } from "react-icons/io5";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { StockEquityChart } from "screens/collection/components/StockEquityChart";
import { generateMockStockEquityData } from "types/stock";
import { MockMilestonesStepper } from "screens/landing/components/MockMilestonesStepper";
import { TfiTimer } from "react-icons/tfi";
import { CustomStat } from "../../collections/chartComponents/CustomStat";
import { getStatusColor } from "screens/common/components/WorkflowSummary";
import { WorkflowStatusIcon } from "screens/common/components/WorkflowStatusIcon";
import { useWorkflowSchedule } from "hooks/useWorkflowSchedules";
import { ToastMessageContent } from "screens/common/components";
import { useConversationContext } from "screens/thread/ConversationContext";
import type { RequestEntities } from "types/charliui";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import { v4 as uuid } from "uuid";

interface Props {
  scheduleId: string;
  ticker: string;
  exchange: string;
  companyName: string;
}

export const ScheduledProjectPlaceholderTile: FunctionComponent<Props> = ({ companyName, ticker, exchange, scheduleId }) => {
  const theme = useTheme();
  const [showWorkflowStepper, setShowWorkflowStepper] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const commonTileProps = useTileProps();
  const tileRef = useRef<HTMLDivElement>(null);
  const leftSectionRef = useRef<HTMLDivElement>(null);
  const rightSectionRef = useRef<HTMLDivElement>(null);
  const placeholderStockEquityData = useMemo(() => generateMockStockEquityData(), []);
  const fontColor = useColorModeValue("charli.mediumGray", "gray.500");
  const { colorMode } = useColorMode();
  const iconColor = useColorModeValue("200", "800");
  const workflowSchedule = useWorkflowSchedule(scheduleId);
  const { onConversationOpen } = useConversationContext();
  const toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    const tileElement = tileRef.current;
    const leftSectionElement = leftSectionRef.current;
    const rightSectionElement = rightSectionRef.current;

    if (tileElement && leftSectionElement && rightSectionElement) {
      const observer = new ResizeObserver(() => {
        const rightSectionWidth = 265;
        const doesContentFitInTile = tileElement.offsetWidth - 60 > leftSectionElement.offsetWidth + rightSectionWidth;
        setShowWorkflowStepper(doesContentFitInTile);
      });

      observer.observe(tileElement);
      observer.observe(leftSectionElement);
      observer.observe(rightSectionElement);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const onCancelSchedule = () => {
    const entities: RequestEntities = [];

    entities.push({ entity: "workflow_schedule_id", value: scheduleId });

    dispatch(
      sendMessage({
        conversationId: uuid(),
        intent: "/delete_workflow_schedule_from_service",
        entities: entities,
      })
    );

    toast.closeAll();

    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`Cancelling new project schedule for ${companyName}`}
          onClick={() => {
            onConversationOpen();
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  return (
    <>
      <Box
        ref={tileRef}
        {...commonTileProps}
        pt="1rem"
        cursor={"initial"}
        pb={isMobile ? "1.5rem" : "1rem"}
        mb={"1.3rem"}
        _hover={{
          boxShadow: "0 0 6px rgba(0, 0, 0, 0.1)",
        }}
        overflow="visible"
        className={"ch-project-landing-placeholder-tile"}
        opacity={colorMode === "light" ? 0.6 : 0.7}
        textAlign="left">
        <Stack direction="row" justifyContent="space-between" align="center" width="100%">
          <Stack ref={leftSectionRef} direction="row" align="center" spacing="1.2rem" justifyContent={"start"}>
            {!isMobile && (
              <Stack width="6rem" align="center">
                <Stack color={fontColor} spacing="3px" className="ch-project-workflow-status-indicator">
                  <CustomStat value={"scheduled"} label={"SCHEDULED"} />
                  <Stack fontWeight={"light"} fontSize="11px" width="100%" align="center" spacing="0px">
                    <Stack direction="row" spacing="3px" align="center">
                      <Icon as={TfiTimer} /> <Text>{"--:--"}</Text>
                    </Stack>
                    <Stack direction="row" spacing="3px" align="center">
                      <Icon as={IoCalendarClearOutline} />
                      <Text>
                        {workflowSchedule?.desiredExecutionDate && new Date(workflowSchedule.desiredExecutionDate).toLocaleDateString()}
                      </Text>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            )}
            <Stack spacing={"0.3rem"} direction="row" className="ch-project-default-icon" width={"8rem"}>
              <Center>
                <TextOverflowTooltip
                  className="ch-project-landing-default-tile-title"
                  label={companyName}
                  style={{ fontSize: "1.4rem", fontWeight: "bold", fontFamily: theme.fonts.logo }}
                />
              </Center>
            </Stack>

            <>
              {!isMobile && (
                <Stack className="ch-project-tile-stock-details" spacing="0" width={["6rem", "10rem", "10rem", "10rem", "11rem"]}>
                  <TextOverflowTooltip breakWord={false} label={`${companyName}`} />
                  <TextOverflowTooltip className="ch-project-stock-details-data" breakWord={false} label={`${exchange} : ${ticker}`} />
                </Stack>
              )}
              {!isMobile && (
                <Box width="13rem">
                  <StockEquityChart width={208} height="2.5rem" hideAxis usingMockData stockEquityData={placeholderStockEquityData} />
                </Box>
              )}
            </>
          </Stack>
          <Stack ref={rightSectionRef} direction="row" justifyContent="flex-end" align="center" spacing={"1.2rem"}>
            {showWorkflowStepper && (
              <>
                <Popover isLazy trigger="hover" placement="top">
                  <PopoverTrigger>
                    <Box
                      onClick={() => onCancelSchedule()}
                      p="3px"
                      borderRadius={"full"}
                      backgroundColor={`${getStatusColor("scheduled")}.${iconColor}`}
                      border="none"
                      height={"unset"}
                      className="ch-workflow-step"
                      textAlign="left"
                      cursor="pointer">
                      <Stack direction="row" justifyContent="left" alignItems="left">
                        <WorkflowStatusIcon status={"scheduled"} />
                      </Stack>
                    </Box>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent style={{ zIndex: 100 }}>
                      <PopoverBody>
                        <Stack>
                          <Box onClick={() => onCancelSchedule()} pl="1rem" borderLeft="1px solid #718096">
                            <Stack direction="row" align="center">
                              <Text fontSize="xs" as="em">
                                Scheduled : Click to cancel
                              </Text>
                            </Stack>
                            <Stack direction="row" align="center">
                              <Text fontSize="xs" as="em">{`This project is scheduled to run at
                            ~${
                              workflowSchedule?.desiredExecutionDate && new Date(workflowSchedule.desiredExecutionDate).toLocaleTimeString()
                            }`}</Text>
                            </Stack>
                          </Box>
                        </Stack>
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
                <MockMilestonesStepper />
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
