import type { FunctionComponent } from "react";
import React from "react";
import { Box, IconButton, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { MessageContent } from "./MessageContent";

interface Props {
  message: string;
  onClick?: () => void;
  onClose?: () => void;
  backgroundColor?: string;
  showCloseButton?: boolean;
  icon?: React.ReactNode;
  moreDetails?: string;
  maxWidth?: string;
  fontSize?: string;
}

export const ToastMessageContent: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  message,
  onClick,
  onClose,
  backgroundColor,
  showCloseButton,
  icon,
  moreDetails,
  maxWidth,
  fontSize = "sm",
}) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const defaultBgColor = useColorModeValue("charli.lightBlue", "gray.700");
  const bgColor = backgroundColor || defaultBgColor;
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  return (
    <Box
      maxWidth={maxWidth ? maxWidth : isMobile ? "100vw" : "20rem"}
      id="conversation-toast"
      fontSize={fontSize}
      color={textColor}
      borderColor={borderColor}
      borderWidth={"1px"}
      cursor={onClick ? "pointer" : "default"}
      boxShadow={"md"}
      p="1rem"
      bg={bgColor}
      borderRadius={isMobile ? "none" : "lg"}
      position="fixed"
      top="3rem"
      right="1rem"
      onClick={() => {
        if (onClick) onClick();
        if (onClose) onClose();
      }}>
      <MessageContent message={message} moreDetails={moreDetails} icon={icon} fontSize={fontSize} />
      {showCloseButton !== false && onClose && (
        <Box
          position="absolute"
          right="-0.5rem"
          top="-0.5rem"
          bg={bgColor}
          borderRadius={"full"}
          borderColor={borderColor}
          borderWidth={"1px"}>
          <IconButton
            p=".5rem"
            aria-label="Close"
            borderRadius={"full"}
            icon={<CloseIcon color={textColor} boxSize="10px" borderRadius={"full"} />}
            size="xs"
            onClick={(event) => {
              onClose();
              event.stopPropagation();
            }}
          />
        </Box>
      )}
    </Box>
  );
};
