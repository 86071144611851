import React, { useEffect, useRef, useState, useMemo, useCallback, useContext } from "react";
import { useColorModeValue, InputGroup, IconButton, InputRightElement, Box, Stack, Center, Icon, usePrevious } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useConversationContext } from "screens/thread/ConversationContext";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { CharliLogoCircle } from "screens/thread/CharliLogoCircle";
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from "@choc-ui/chakra-autocomplete";
import type { AutoCompleteRefMethods } from "@choc-ui/chakra-autocomplete";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { v4 as uuid } from "uuid";
import { InputBarAttachmentButton } from "screens/conversation/components/InputBar/InputBarAttachmentButton";
import { InputBarDropzoneContext } from "screens/conversation/components/InputBar/InputBarDropzoneContext";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";
import { useEntitlementKey } from "hooks/useEntitlements";
import { useCopyValue } from "hooks/useCopies";
import { SuggestionItem } from "./SuggestionItem";
import { FilterButtons } from "./FilterButtons";
import { NoResultsMessage } from "./NoResultsMessage";
import { IoCloudOffline } from "react-icons/io5";
import type { AutocompleteInputProps } from "./types";
import { MessageContent } from "screens/common/components/MessageContent";

export const AutocompleteInput = React.memo(
  ({
    initialText = "",
    suggestedQuestions = [],
    suggestionListType,
    isInputDisabled = false,
    isSubmitDisabled = false,
    onSelectOption,
    value,
    onChange,
    onReset,
    isLoading = false,
    onClickSubmit,
    conversationId,
    minWidthList = "32rem",
    marginLeftList,
    disableFilter = false,
    defaultIsOpen = false,
    className,
    conversationState,
    externalInputRef,
    inputId,
    canBypassDisabled,
    setCanBypassDisabled,
    onFocus,
    onBlur,
    disableComponent,
  }: AutocompleteInputProps) => {
    const isWebsocketConnected = useSelector((state: RootState) => state.websocket.isConnected);
    const didWebsocketPreviouslyConnect = usePrevious(isWebsocketConnected);
    const inputBgColor = useColorModeValue("white", "gray.700");
    const inputBorderColor = useColorModeValue("gray.200", "gray.700");
    const inputSubmitColor = useColorModeValue("gray.50", "gray.800");
    const inputSubmitColorHover = useColorModeValue("gray.200", "primary.default");
    const submitColor = useColorModeValue("primary.default", "gray.700");
    const textColor = useColorModeValue("primary.darkGray", "gray.400");
    const betaBgColor = useColorModeValue("blue.100", "blue.700");
    const disconnectedBgColor = useColorModeValue("#f9e4dc", "gray.800");

    const {
      onFeedbackModalOpen,
      setFeedbackValue,
      isConversationOpen,
      onConversationClose,
      onConversationOpen,
      isDialogOpen,
      onDialogClose,
      focusedInputId,
      setFocusedInputId,
      setIsUsingDefaultConversationDialog,
      setIsAnotherInputFocused,
    } = useConversationContext();
    const { setIsNewMenuAction } = useAddToCharliContext();
    const autocompleteRef = useRef<AutoCompleteRefMethods>();
    const internalInputRef = useRef<HTMLInputElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const filterContainerRef = useRef<HTMLDivElement>(null);
    const inputRef = externalInputRef || internalInputRef;
    const { files } = useContext(InputBarDropzoneContext);
    const { pathname } = useLocation();
    const { scrollbarStyle } = useCustomScrollbar(listRef, { width: "3px", barTransparency: 0.1 });
    const hasPrivateTickers = useEntitlementKey("ui_show_private_tickers");

    const [showSuggestionList, setShowSuggestionList] = useState(defaultIsOpen);
    const [activeFilter, setActiveFilter] = useState<string | null>(null);

    const isListVisible = useCallback(() => {
      return !!(listRef.current && listRef.current.offsetParent !== null);
    }, []);

    const suggestedQuestionsWithId = useMemo(() => {
      return suggestedQuestions.map((question) => ({ ...question, id: uuid() }));
    }, [suggestedQuestions]);

    const tickerTypes = useCopyValue("copy_ticker_type_options");
    const uniqueTypes = useMemo(() => tickerTypes, [tickerTypes]);

    const filteredSuggestedQuestions = useMemo(() => {
      if (!activeFilter) return suggestedQuestionsWithId;
      return suggestedQuestionsWithId.filter(
        (q) => q.type?.toUpperCase() === activeFilter.toUpperCase() || q.focus?.toUpperCase() === activeFilter.toUpperCase()
      );
    }, [suggestedQuestionsWithId, activeFilter]);

    const onClickFeedback = useCallback(() => {
      if (!value || !activeFilter) {
        return;
      }
      setFeedbackValue(value ? value : activeFilter);
      onFeedbackModalOpen();
    }, [value, activeFilter, setFeedbackValue, onFeedbackModalOpen]);

    const onHandleConversation = useCallback(() => {
      if (isConversationOpen) {
        onConversationClose();
      } else {
        setIsNewMenuAction(conversationId ? undefined : "command");
        onConversationOpen(conversationId);
      }
    }, [isConversationOpen, onConversationClose, setIsNewMenuAction, onConversationOpen, conversationId]);

    useEffect(() => {
      if (inputRef.current) {
        setIsUsingDefaultConversationDialog(false);
        isConversationOpen && focusedInputId === "conversation-input" && inputRef.current.focus();
      }
    }, [focusedInputId, inputRef, isConversationOpen, setIsUsingDefaultConversationDialog]);

    const isFocusedInputDisabled = useMemo(() => {
      return focusedInputId !== inputId;
    }, [focusedInputId, inputId]);

    useEffect(() => {
      if (isConversationOpen) {
        setFocusedInputId("conversation-input");
        if (isDialogOpen) {
          onDialogClose();
        }
      } else {
        setFocusedInputId("view-input");
      }
      setTimeout(() => {
        !disableComponent && inputRef.current && inputRef.current.focus();
      }, 300);
    }, [isConversationOpen, setFocusedInputId, pathname, isDialogOpen, onDialogClose, inputRef, disableComponent]);

    const isSubmitAvailable = useCallback((): boolean => {
      if (value?.startsWith("/")) {
        return false;
      }
      return !!(
        value &&
        value.length > 0 &&
        !suggestedQuestions.some((suggestion) => suggestion.question.toLowerCase().trim() === value.toLowerCase().trim())
      );
    }, [suggestedQuestions, value]);

    const handleInputOnClick = useCallback(
      (onOpen: () => void) => {
        if ((isInputDisabled || isFocusedInputDisabled) && !canBypassDisabled) {
          return;
        }
        setIsAnotherInputFocused(true);
        if (inputId === "view-input" && isConversationOpen) {
          onConversationClose();
        }
        setShowSuggestionList(true);
        setActiveFilter(null);
        onOpen();
        onFocus?.();
        inputRef.current && inputRef.current.focus();
      },
      [
        inputRef,
        isInputDisabled,
        isFocusedInputDisabled,
        canBypassDisabled,
        setIsAnotherInputFocused,
        inputId,
        isConversationOpen,
        onFocus,
        onConversationClose,
      ]
    );

    useEffect(() => {
      const handleKeyDown = (ev: KeyboardEvent) => {
        const { key } = ev;
        const suggestedQuestion =
          suggestedQuestions.length > 0 &&
          suggestedQuestions.filter(
            (suggestion) => value?.toLocaleLowerCase() && suggestion.question.toLocaleLowerCase().includes(value)
          )[0];

        const isListCurrentlyVisible = isListVisible();

        switch (key) {
          case "Enter":
            (!isListCurrentlyVisible || isSubmitAvailable()) && onClickSubmit?.();
            setShowSuggestionList(false);
            setCanBypassDisabled?.(false);
            break;

          case "Tab": {
            if (!isListCurrentlyVisible) {
              return;
            }
            suggestedQuestion && onSelectOption(suggestedQuestion);
            setShowSuggestionList(false);
            setCanBypassDisabled?.(false);
            ev.preventDefault();
            break;
          }

          case " ":
            {
              const doesValueContainEntity = value?.includes(">") || false;
              setShowSuggestionList(doesValueContainEntity);
            }
            break;

          case "Escape":
            setShowSuggestionList(false);
            setCanBypassDisabled?.(false);
            onReset ? onReset() : onChange("");
            inputRef.current && inputRef.current.focus();
            break;

          default:
            break;
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => document.removeEventListener("keydown", handleKeyDown);
    }, [
      isSubmitAvailable,
      onClickSubmit,
      onReset,
      onChange,
      value,
      suggestedQuestions,
      onSelectOption,
      setCanBypassDisabled,
      showSuggestionList,
      setIsAnotherInputFocused,
      onBlur,
      inputRef,
      isListVisible,
    ]);

    const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      setShowSuggestionList(true);
      onChange(ev.target.value);
    };

    const handleSubmit = useCallback(() => {
      if (isLoading) {
        return;
      }
      const suggestedQuestion =
        suggestedQuestions.length > 0 &&
        suggestedQuestions.filter((suggestion) => value?.toLocaleLowerCase() && suggestion.question.toLocaleLowerCase().includes(value))[0];

      onClickSubmit && onClickSubmit(suggestedQuestion ? suggestedQuestion : undefined);
      setShowSuggestionList(false);
    }, [isLoading, onClickSubmit, suggestedQuestions, value]);

    const renderSuggestedQuestions = useCallback(
      (questions: typeof suggestedQuestionsWithId) => {
        return questions.map((suggestion) => (
          <AutoCompleteItem
            _focus={{ bgColor: suggestion.focus?.toUpperCase() === "PRIVATE" && !hasPrivateTickers ? "transparent" : inputSubmitColor }}
            key={`option-${suggestion.id}`}
            value={JSON.stringify({
              question: suggestion.question,
              focus: suggestion.focus,
              matchFilter: suggestion.matchFilter,
              type: suggestion.type,
            })}
            className="ch-autocomplete-suggestion"
            marginInline={0}
            p="1rem"
            borderRadius="none">
            <SuggestionItem
              suggestion={suggestion}
              value={value}
              hasPrivateTickers={hasPrivateTickers}
              textColor={textColor}
              betaBgColor={betaBgColor}
            />
          </AutoCompleteItem>
        ));
      },
      [hasPrivateTickers, inputSubmitColor, textColor, betaBgColor, value]
    );

    return (
      <Stack spacing="9px" width="100%" height="100%">
        <AutoComplete
          ref={autocompleteRef}
          onSelectOption={({ item: { originalValue } }) => {
            if (!isWebsocketConnected) return;
            const suggestedQuestion = JSON.parse(originalValue);
            if (!(suggestedQuestion.focus === "PRIVATE" && !hasPrivateTickers)) {
              onSelectOption(suggestedQuestion);
            }
          }}
          defaultIsOpen={defaultIsOpen}
          disableFilter={disableFilter}
          freeSolo
          emptyState={false}>
          {({ onOpen }) => (
            <>
              <InputGroup position="relative">
                <Stack bottom={-0.5} zIndex={10} display="flex" position="absolute" direction="row" justifyContent="space-between">
                  <CharliLogoCircle className="project-conversation-button" onClick={onHandleConversation} />
                  {(value?.startsWith("/") || files.length > 0 || (conversationState && conversationState === "clarification_needed")) &&
                    isConversationOpen && (
                      <Center width="2rem" borderRadius="30px 0px 0px 30px!important">
                        <InputBarAttachmentButton size="md" />
                      </Center>
                    )}
                </Stack>
                <AutoCompleteInput
                  id={inputId}
                  ref={inputRef}
                  onClick={() => handleInputOnClick(onOpen)}
                  onBlur={() => {
                    setIsAnotherInputFocused(false);
                    setActiveFilter(null);
                    onBlur?.();
                  }}
                  autoComplete="off"
                  paddingInlineStart="0"
                  paddingInlineEnd="2.5rem"
                  paddingLeft={value?.startsWith("/") && isConversationOpen ? "5.5rem" : "4rem"}
                  borderRadius="30px 30px 30px 30px!important"
                  aria-autocomplete="both"
                  className={className ? className : "ch-question-input"}
                  disabled={canBypassDisabled && !isFocusedInputDisabled ? false : isInputDisabled || isFocusedInputDisabled}
                  backgroundColor={inputBgColor}
                  placeholder={initialText}
                  onChange={handleInputChange}
                  _disabled={{
                    cursor: "not-allowed",
                    opacity: 1,
                    color: "gray.200",
                  }}
                  _hover={{}}
                  loadingIcon={<TypingIndicator size="small" />}
                  size="md"
                  borderColor={inputBorderColor}
                  borderWidth="1px"
                  borderLeftWidth="0"
                  value={value}
                  background={`linear-gradient(to right, transparent 2rem, ${inputBgColor} 2rem) !important`}
                />
                <InputRightElement
                  width="2.5rem"
                  height="2.5rem"
                  zIndex={value && value.length > 0 ? 2 : 0}
                  children={
                    <IconButton
                      className="ch-autocomplete-submit"
                      aria-label="submit"
                      borderRadius="full"
                      color={submitColor}
                      size="sm"
                      backgroundColor={inputSubmitColor}
                      _disabled={{ cursor: "not-allowed", opacity: 0.6 }}
                      _hover={{ fontWeight: "bold", backgroundColor: inputSubmitColorHover }}
                      icon={<ArrowForwardIcon width="1.5rem" height="1.5rem" />}
                      isDisabled={
                        canBypassDisabled && !isFocusedInputDisabled
                          ? false
                          : isLoading || !isWebsocketConnected || isSubmitDisabled || isInputDisabled || isFocusedInputDisabled
                      }
                      onClick={handleSubmit}
                    />
                  }
                />
              </InputGroup>
              {showSuggestionList && (
                <AutoCompleteList
                  position="relative"
                  ref={listRef}
                  css={scrollbarStyle}
                  boxShadow="xl"
                  p="0"
                  marginLeft={marginLeftList ? marginLeftList : ["unset", "unset", "3rem!important"]}
                  marginTop="-3px!important"
                  minWidth={["calc(100vw - 2rem)", "unset", minWidthList]}
                  width={["100%", "100%", "calc(100% - 3rem)"]}
                  maxHeight={["250px", "350px", "350px"]}>
                  {isWebsocketConnected && suggestionListType === "tickers" && uniqueTypes.length > 0 ? (
                    <Box
                      ref={filterContainerRef}
                      className="ch-autocomplete-filter"
                      position="fixed"
                      zIndex={2}
                      overflowX="auto"
                      css={{
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                        scrollbarWidth: "none",
                      }}
                      backgroundColor={inputBgColor}
                      minHeight="2.4rem"
                      minWidth={["calc(100vw - 2rem)", "unset", minWidthList]}
                      width={["100%", "100%", "calc(100% - 3rem)"]}>
                      <Box px={"1rem"} p="8px">
                        <FilterButtons uniqueTypes={uniqueTypes} activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
                      </Box>
                    </Box>
                  ) : (
                    !isWebsocketConnected &&
                    didWebsocketPreviouslyConnect === false && (
                      <Box
                        ref={filterContainerRef}
                        className="ch-autocomplete-filter"
                        position="fixed"
                        zIndex={2}
                        backgroundColor={disconnectedBgColor}
                        width={["100%", "100%", `${pathname.includes("/register") ? "25.5rem" : "30rem"}`]}>
                        <Box p={"1rem"}>
                          <Box color={textColor}>
                            <MessageContent
                              fontSize="sm"
                              message="Charli is trying to connect, requests can not be sent yet."
                              icon={<Icon as={IoCloudOffline} color="gray.600" boxSize="1.8rem" />}
                              moreDetails="Charli relies on standard and secure WebSockets for communication with the cloud services and the AI system. <br>Charli cannot currently connect with the cloud services, and this might be blocked due to your network firewall policies or ad blockers in your browser. Please check with your IT organization or network administrator to have WebSockets enabled for charliai.com.<br>You can also contact our <a href='https://support.charli.ai/hc/en-us/articles/33739429556877--Charli-is-trying-to-connect-information-message' target='_blank'>Customer Service</a> team for troubleshooting."
                            />
                          </Box>
                        </Box>
                      </Box>
                    )
                  )}
                  <Box mt={suggestionListType === "tickers" && uniqueTypes.length > 0 ? "2.4rem" : "-3px!important"}>
                    {filteredSuggestedQuestions.length > 0
                      ? renderSuggestedQuestions(filteredSuggestedQuestions)
                      : suggestionListType === "tickers" &&
                        isWebsocketConnected && (
                          <NoResultsMessage value={value} activeFilter={activeFilter} onClickFeedback={onClickFeedback} />
                        )}
                  </Box>
                </AutoCompleteList>
              )}
            </>
          )}
        </AutoComplete>
      </Stack>
    );
  }
);

export default AutocompleteInput;
