import React from "react";
import type { FunctionComponent } from "react";
import { Tooltip, Icon, IconButton, useColorModeValue } from "@chakra-ui/react";
import type { IconType } from "react-icons";

interface Props {
  tooltip?: string;
  iconTypeName: IconType;
  iconSize?: string;
  classNames?: string;
  isDisabled?: boolean;
  onClick?: () => void;
  bgColor?: string;
  id?: string;
}

export const SmallActionButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  iconSize = "1.1rem",
  tooltip,
  iconTypeName,
  classNames,
  onClick,
  isDisabled,
  bgColor,
  id,
}) => {
  const iconColor = useColorModeValue("gray.500", "gray.400");

  return (
    <Tooltip aria-label="action tooltip" label={isDisabled ? "" : tooltip} placement="left">
      <IconButton
        isDisabled={isDisabled}
        color={iconColor}
        backgroundColor={bgColor || "transparent"}
        id={id}
        as="div"
        p="0!important"
        mt="3px!important"
        minWidth={"unset!important"}
        paddingInline={"0!important"}
        aria-label="action button"
        marginInline={"0!important"}
        className={classNames}
        icon={<Icon as={iconTypeName} fontSize={iconSize} />}
        _hover={{
          color: "primary.default",
        }}
        _active={{}}
        cursor={isDisabled ? "not-allowed" : "pointer"}
        boxSize={iconSize}
        onClick={(event) => {
          !isDisabled && onClick && onClick();
          event.stopPropagation();
        }}
      />
    </Tooltip>
  );
};
