import type { WorkflowSchedule } from "api/workflowSchedules/models/WorkflowSchedule";
import { useMemo, useCallback } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import { sendMessage } from "state/websocket/operations";
import type { RequestEntities } from "types/charliui";

export const useWorkflowSchedule = (scheduleId: string): WorkflowSchedule | undefined => {
  return useSelector((state: RootState) => state.workflowsSchedules.workflowsSchedulesById[scheduleId], shallowEqual);
};

export const useWorkflowSchedules = () => {
  return useSelector(
    (state: RootState) => state.workflowsSchedules.workflowsSchedulesIds.map((id) => state.workflowsSchedules.workflowsSchedulesById[id]),
    shallowEqual
  );
};

export const useWorkflowSchedulesByPortfolio = (portfolioId: string) => {
  const workflowsSchedules = useWorkflowSchedules();

  return useMemo(
    () =>
      workflowsSchedules.filter((workflowSchedule) =>
        workflowSchedule.entities.find((entity) => entity.name === "portfolio_id" && entity.value === portfolioId)
      ),
    [workflowsSchedules, portfolioId]
  );
};

export const useWorkflowScheduleByProject = (collectionId: string) => {
  const workflowsSchedules = useWorkflowSchedules();

  return useMemo(
    () =>
      workflowsSchedules.find((workflowSchedule) =>
        workflowSchedule.entities.some((entity) => entity.name === "as_rerun_of_project_id" && entity.value === collectionId)
      ),
    [workflowsSchedules, collectionId]
  );
};

export const useCancelWorkflowSchedule = () => {
  const dispatch = useDispatch();

  return useCallback(
    (workflowSchedule: WorkflowSchedule | undefined, collectionId: string, collectionConversationId: string | undefined) => {
      if (!workflowSchedule || !collectionId || !collectionConversationId) return;

      const entities: RequestEntities = [
        { entity: "workflow_schedule_id", value: workflowSchedule.id },
        { entity: "portfolio_id", value: collectionId },
      ];

      dispatch(
        sendMessage({
          conversationId: collectionConversationId,
          intent: "/delete_workflow_schedule",
          entities: entities,
        })
      );
    },
    [dispatch]
  );
};

interface NewScheduledProject {
  scheduleId: string;
  portfolioId: string;
  companyTicker: string;
  companyStockExchange: string;
  companyName: string;
}

export const useScheduledPlaceholderProjectsByPortfolio = (portfolioId: string): NewScheduledProject[] => {
  const workflowsSchedules = useWorkflowSchedules();

  return useMemo(() => {
    return workflowsSchedules.flatMap((schedule) => {
      const entitiesMap = schedule.entities.reduce((acc: Map<string, string>, entity) => {
        acc.set(entity.name, entity.value);
        return acc;
      }, new Map<string, string>());

      if (
        schedule.frequency === "one-time" &&
        entitiesMap.get("portfolio_id") === portfolioId &&
        entitiesMap.has("company_ticker") &&
        entitiesMap.has("company_stock_exchange") &&
        entitiesMap.has("company_name")
      ) {
        return [
          {
            scheduleId: schedule.id,
            portfolioId,
            companyTicker: entitiesMap.get("company_ticker")!,
            companyStockExchange: entitiesMap.get("company_stock_exchange")!,
            companyName: entitiesMap.get("company_name")!,
          },
        ];
      } else {
        return [];
      }
    });
  }, [workflowsSchedules, portfolioId]);
};

export const useProjectsHaveWorkflowSchedules = (projectsIds: string[]) => {
  const workflowsSchedules = useWorkflowSchedules();

  return useMemo(
    () =>
      projectsIds.some((projectId) =>
        workflowsSchedules.some(
          (workflowSchedule) =>
            workflowSchedule.entities.some((entity) => entity.name === "as_rerun_of_project_id" && entity.value === projectId) &&
            workflowSchedule.frequency === "one-time"
        )
      ),
    [workflowsSchedules, projectsIds]
  );
};
