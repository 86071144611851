import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer, createMigrate } from "redux-persist";
import type { AnyAction, CombinedState } from "@reduxjs/toolkit";
import sessionReducer from "./session/reducer";
import billingReducer from "./billing/reducer";
import conversationReducer from "./conversation/reducer";
import websocketReducer from "./websocket/reducer";
import selectionReducer from "./selection/reducer";
import commandReducer from "./command/reducer";
import flagReducer from "./flags/reducer";
import entitlementReducer from "./entitlements/reducer";
import navigationReducer from "./navigation/reducer";
import notificationReducer from "./notification/reducer";
import type { PersistedCollectionState } from "./collection/reducer";
import collectionReducer from "./collection/reducer";
import userPreferenceReducer from "./userPreference/reducer";
import homeReducer from "./home/reducer";
import shareHistoryReducer from "./shareHistory/reducer";
import integrationsReducer from "./integrations/reducer";
import contentReducer from "./content/reducer";
import configuredWorkflowReducer from "./configuredWorkflow/reducer";
import workflowReducer from "./workflow/reducer";
import checkpointReducer from "./checkpoints/reducer";
import usersReducer from "./users/reducer";
import meetingSyncReducer from "./meetings/reducer";
import taskReducer from "./task/reducer";
import billingPlansReducer from "./billingPlans/reducer";
import systemPreferenceReducer from "./systemPreference/reducer";
import organizationReducer from "./organization/reducer";
import memberOrganizationsReducer from "./memberOrganizations/reducer";
import reportTemplatesReducer from "./reportTemplates/reducer";
import entityConfigurationsReducer from "./entityConfigurations/reducer";
import memorizedClarificationConfigurationsReducer from "./memorizedClarificationConfig/reducer";
import configReducer from "./config/reducer";
import workflowStatsReducer from "./workflowStats/reducer";
import informationExtractionConfigReducer from "./informationExtractionConfig/reducer";
import organizationsPreferenceReducer from "./organizationPreference/reducer";
import charliActivitiesReducer from "./charliActivities/reducer";
import localforage from "localforage";
import suggestedQuestionsReducer from "./suggestedQuestions/reducer";
import usageReducer from "./usage/reducer";
import newsInformationReducer from "./newsInformation/reducer";
import contextCoordinatesReducer from "./contextCoordinates/reducer";
import subscriptionsReducer from "./subscription/reducer";
import financialMarketReducer from "./financialMarket/reducer";
import type { PersistedCollectionMetadataStateInitial, PersistedCollectionMetadataStateV0 } from "./collectionMetadata/reducer";
import collectionMetadataReducer from "./collectionMetadata/reducer";
import copiesReducer from "./copies/reducer";
import milestonesReducer from "./milestones/reducer";
import workflowsSchedulesReducers from "./workflowSchedules/reducer";
import { captureMessage } from "@sentry/react";
import type { ContentDetails } from "types/content/ContentDetails";
import { getCollectionCheckKey } from "./collectionMetadata/operations";

const checkpointPersistConfig = {
  key: "checkpoint",
  storage: localforage,
};

const conversationPersistConfig = {
  key: "conversation",
  storage: localforage,
  whitelist: ["hasInitialSyncCompleted"],
};

const contentPersistConfig = {
  key: "content",
  storage: localforage,
  whitelist: ["recentContent"],
};

const charliActivitiesPersistConfig = {
  key: "charliActivities",
  storage: localforage,
};

const workflowPersistConfig = {
  key: "workflow",
  storage: localforage,
};

const contextCoordinatesPersistConfig = {
  key: "contextCoordinates",
  version: 1,
  storage: localforage,
  migrate: createMigrate(
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      1: (state) => {
        if (state?._persist) {
          captureMessage("Migration V1: Clearing context coordinates data");
          console.log("Migration V1: Clearing context coordinates data");

          return {
            ...state,
            coordinatesByAnswerId: {},
          };
        }
      },
    },
    { debug: false }
  ),
};

const milestonesPersistConfig = {
  key: "milestones",
  storage: localforage,
};

// NOTE: any new reducer needs to have a flush() method to reset the state
// and needs to be added in src/state/middleware/sessionMiddleware.ts
const appReducer = combineReducers({
  session: sessionReducer,
  billing: billingReducer,
  conversation: persistReducer(conversationPersistConfig, conversationReducer),
  websocket: websocketReducer,
  selection: selectionReducer,
  command: commandReducer,
  flag: flagReducer,
  entitlement: entitlementReducer,
  navigation: navigationReducer,
  notification: notificationReducer,
  collection: persistReducer(
    {
      key: "collection",
      storage: localforage,
      version: 0,
      migrate: createMigrate({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        0: (state): PersistedCollectionState => {
          const persistedState = state as PersistedCollectionState;

          return {
            ...persistedState,
            collections: persistedState.order.reduce((acc, id) => {
              const collection = persistedState.collections[id];

              return {
                ...acc,
                [id]: {
                  ...collection,
                  questions: undefined,
                },
              };
            }, {}),
          };
        },
      }),
      blacklist: ["collections", "order", "arePortfolioProjectsLoaded"],
    },
    collectionReducer
  ),
  userPreference: userPreferenceReducer,
  systemPreference: systemPreferenceReducer,
  home: homeReducer,
  shareHistory: shareHistoryReducer,
  integrations: integrationsReducer,
  content: persistReducer(contentPersistConfig, contentReducer),
  configuredWorkflow: configuredWorkflowReducer,
  workflow: persistReducer(workflowPersistConfig, workflowReducer),
  checkpoint: persistReducer(checkpointPersistConfig, checkpointReducer),
  users: usersReducer,
  meetings: meetingSyncReducer,
  tasks: taskReducer,
  billingPlans: billingPlansReducer,
  organizations: organizationReducer,
  memberOrganizations: memberOrganizationsReducer,
  reportTemplate: reportTemplatesReducer,
  entityConfigurations: entityConfigurationsReducer,
  memorizedClarificationConfigurations: memorizedClarificationConfigurationsReducer,
  configMap: configReducer,
  workflowsStats: workflowStatsReducer,
  informationExtractionConfig: informationExtractionConfigReducer,
  organizationsPreference: organizationsPreferenceReducer,
  charliActivities: persistReducer(charliActivitiesPersistConfig, charliActivitiesReducer),
  suggestedQuestions: suggestedQuestionsReducer,
  newsInformation: newsInformationReducer,
  usage: usageReducer,
  contextCoordinates: persistReducer(contextCoordinatesPersistConfig, contextCoordinatesReducer),
  subscriptions: subscriptionsReducer,
  financialMarket: financialMarketReducer,
  collectionMetadata: persistReducer(
    {
      key: "collectionMetadata",
      storage: localforage,
      version: 0,
      migrate: createMigrate({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        0: (state): PersistedCollectionMetadataStateV0 => {
          console.log("Migration V0: Migrating collection metadata");
          const persistedState = state as PersistedCollectionMetadataStateInitial;

          const newContentsIds = Object.entries(persistedState.contentsByIds).reduce(
            (acc: Record<string, Partial<ContentDetails>>, [key, content]) => {
              const type = getCollectionCheckKey(content);

              if (type === "companyLogoId" || type === "landingThumbnailId") {
                return {
                  ...acc,
                  [key]: {
                    id: content.id,
                    mediaId: content.mediaId,
                    urls: content.urls,
                    manualTags: content.manualTags,
                  },
                };
              } else if (type === "companyDetailsId" || type === "stockEquityDataId") {
                return {
                  ...acc,
                  [key]: {
                    id: content.id,
                    name: content.name,
                    dynamicData: content.dynamicData,
                  },
                };
              }

              return acc;
            },
            {}
          );

          return {
            ...persistedState,
            contentsByIds: newContentsIds,
          };
        },
      }),
      whitelist: ["dismissedContentsIds"],
    },
    collectionMetadataReducer
  ),
  copies: copiesReducer,
  milestones: persistReducer(milestonesPersistConfig, milestonesReducer),
  workflowsSchedules: workflowsSchedulesReducers,
});

const rootReducer = (state: CombinedState<RootState> | undefined, action: AnyAction) => {
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;
export default rootReducer;
